import React,{useState} from 'react';
import {Box} from '@mui/material';
import ActiveBar from "../../../../../../../../component/PolicyNavbarUpdated";
import BtnSecondary from '../../../../../../../../component/SecondaryButton';
import SortBy from './SortBy';
import CreateGroup from '../../../../../../../../component/CreateGroup';
import TopBar from '../../../../../../../../component/TopBar';
import MainBox from '../../../../../../container/MainBox';

const AttendancePolicyAssignPolicy = () => {

  const [page, setPage] = useState("sortBy");

  const loadNextPage = (p) => {
    setPage(p);
  };

  const commonProps = {
    loadNextPage,
  };

  const sortByProps = {
    ...commonProps,
  };

  const createGroupProps = {
    ...commonProps,
    redirectLink: "/app/attendance-policy/new/assign-policy", 
  };


  const mapPages = {
    sortBy: (props) => <SortBy {...sortByProps} />,
    createGroup: (props) => <CreateGroup {...createGroupProps} />,
  };

  const ShowPage = mapPages[page];
 
  return (
    <Box sx={{width:"100%", paddingBlock:"1rem"}}>
      <TopBar 
        MainHeading={"Attendance Policy"}
        breadcrumbs={[{label:"Attendance", href:""},{label:"Policy Details", href:""},{label:"Add Category", href:""},{label:"Assign Policy", href:""}]}
      />
      <MainBox sx={{ padding: "1.8125rem 1.25rem", height:"100%" }}>
          <Box sx={{display:"flex", justifyContent:"space-between", marginBottom:"1rem"}}>
              <ActiveBar active={["Policy Details","Add Category","Assign Policy"]} value="52"/>
              <BtnSecondary 
                sx={{width:"10rem", height:"2.25rem", borderRadius:"10px"}} 
                onClick={()=>{page==="sortBy"?loadNextPage("createGroup"):loadNextPage("sortBy")}}
              >
                  {page==="sortBy"?"+ Create Group":"Back"}
              </BtnSecondary>
          </Box>
          <Box>
            <ShowPage/>
          </Box> 
      </MainBox>
    </Box>
    )
}

export default AttendancePolicyAssignPolicy;