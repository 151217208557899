import React from 'react';
import {Avatar, Box,TableCell} from '@mui/material';
import styled from '@emotion/styled';
import PaginationTable from '../PaginationTable';
import Eye from '../../assets/icons/eye.svg';
import { formatDate } from '../../utils/formatDate';
import CheckboxField from '../CheckboxField';
import { PolicyTableHeadings } from '../../styles/PolicyTableHeadings';

const CustomTableCell = styled(TableCell)({
    fontWeight: "300",
    fontSize: "1rem",
    lineHeight: "1.5rem",
    textAlign:"center",
  });

const SortByTable = ({sortBy,data,selectedList,setSelectedList}) => {

const departmentsHeadings = [
    { label: "", style: {...PolicyTableHeadings, width:"3rem", borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px" } },
    { label: "Department Name", style: {...PolicyTableHeadings,  width:"12.56rem"} },
    { label: "Department Head", style: {...PolicyTableHeadings,  width:"37.68rem"} },
    { label: "Created On", style: {...PolicyTableHeadings,  width:"12.56rem"} },
    { label: "", style: { ...PolicyTableHeadings,width:"3rem", borderTopRightRadius: "12px", borderBottomRightRadius: "12px" } }
    ];

    const groupsHeadings = [
    { label: "", style: {...PolicyTableHeadings, width:"3rem", borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px" } },
    { label: "Group Name", style: {...PolicyTableHeadings,  width:"12.56rem"} },
    { label: "Created By", style: {...PolicyTableHeadings,  width:"37.68rem"} },
    { label: "Created On", style: {...PolicyTableHeadings,  width:"12.56rem"} },
    { label: "", style: { ...PolicyTableHeadings,width:"3rem", borderTopRightRadius: "12px", borderBottomRightRadius: "12px" } }
    ];

    const handleCheckboxChange = (id, isChecked) => {
      setSelectedList(prevList => {
          let newDepartmentList = prevList.department;
          let newEmployeeGroupList = prevList.employeeGroup;
  
          if (sortBy === "Departments") {
              if (isChecked) {
                  newDepartmentList = [...prevList.department, id];
              } else {
                  newDepartmentList = prevList.department.filter((item) => item !== id);
              }
          } else {
              if (isChecked) {
                  newEmployeeGroupList = [...prevList.employeeGroup, id];
              } else {
                  newEmployeeGroupList = prevList.employeeGroup.filter((item) => item !== id);
              }
          }
  
          return {
              ...prevList,
              department: newDepartmentList,
              employeeGroup: newEmployeeGroupList
          };
      });
  };
  

  return (
    <PaginationTable
                rows={data||[]}
                headings={sortBy==="Departments"?departmentsHeadings:groupsHeadings}
                noRecordMessage={"No Record Found"}
                renderCell={(row) => (
                  <>
                    <CustomTableCell>
                        <CheckboxField
                            checked={sortBy==="Departments"?
                                      (selectedList?.department?.includes(row._id)):
                                      (selectedList?.employeeGroup?.includes(row._id))
                                    }
                            onChange={(isChecked) => handleCheckboxChange(row._id, isChecked)}
                        />
                    </CustomTableCell>
                    <CustomTableCell>{row?.name}</CustomTableCell>
                    <CustomTableCell>
                      {row.head}
                    </CustomTableCell>
                    <CustomTableCell>{formatDate(row.createdAt)}</CustomTableCell>
                    <CustomTableCell>
                      <Box 
                        sx={{width:"100%",display:"flex",justifyContent:"center"}} 
                        // onClick={()=> {
                        //           setIsInfoDialogOpen(true)
                        //           setInfoId(row._id)
                        // }}
                      >
                        <Avatar src={Eye} sx={{ width: '1.25rem', height: '1.25rem' }}/>
                      </Box>
                    </CustomTableCell>
                  </>
                )}
              />
  )
}

export default SortByTable