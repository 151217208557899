import styled from 'styled-components';
import {Box} from '@mui/material'; 

const Card = styled(Box)({
    display: "block",
    wordBreak: "break-word",
    border: "0.5px solid #00000033",
    borderRadius: "12px",
    display: "flex",
    flexDirection: "column",
});

export default Card;
