import React, { useState} from 'react';
import { Dialog,Box,} from '@mui/material';
import BtnPrimary from "../../../../../../component/PrimaryButton";
import FormField from '../../../../../../component/Formfield';
import ErrorMsg from '../../../../../../component/ErrorMsg';

function AttributeForm({open, onClose}) {

  const [attribute,setAttribute] = useState({name:"",fieldType:"",values:"",isRequired:""})
  const [errorMsg,setErrorMsg] = useState("");

  const handleSave = () => {
     onClose(attribute);
  };

  const handleChange = (field, value) => {
    setAttribute(prevState => ({...prevState,[field]: value}));
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width:"30rem",
          borderRadius: "10px",
          boxShadow: "0px 4px 10px 0px #0000001A",
          padding: "1.625rem 1.25rem",
        }
      }}
    >
        <Box sx={{display: "block",
                  fontWeight:"500",
                  fontSize:"1.25rem",
                  lineHeight:"1.875rem",
                  color:"#0081FF",
                  textDecoration:"underline",
                  marginBottom:"0.75rem"
                }}>
                    Attribute
        </Box>
        <Box>
        <FormField
            label="Attribute Name"
            type="text"
            value={attribute.name}
            fullwidth={true}
            onChange={(e) => {handleChange('name', e.target.value)}}
        />
        <FormField
                label="Field Type"
                type="select"
                options={["Textbox","Dropbox","Checkbox", "Selectbox"]}
                value={attribute.fieldType}
                fullwidth={true}
                onChange={(e) => {handleChange('fieldType', e.target.value)}}
        />
        <FormField
                label="Values"
                type="text"
                value={attribute.values}
                fullwidth={true}
                onChange={(e) => {handleChange('values', e.target.value)}}
        />
        <FormField
                label="Mandatory"
                type="select"
                options={["Yes","No"]}
                value={attribute.isRequired}
                fullwidth={true}
                onChange={(e) => {handleChange('isRequired', e.target.value)}}
        />
        </Box>
        <ErrorMsg>
          {errorMsg}
        </ErrorMsg>
        <Box style={{display:"flex",justifyContent:"center", width:"100%"}}>
           <BtnPrimary onClick={handleSave} sx={{width:"11.875rem", height: '3.125rem', padding: '1.25rem', borderRadius: '10px' }}>
                Save Attribute
           </BtnPrimary>
        </Box>
    </Dialog>
  );
}

export default AttributeForm;

