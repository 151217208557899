import React, { Suspense, useEffect, useState } from "react";
import Spinner from "../component/Spinner";
import { organizationLogin } from "../services/auth";
import { setOnLoginEmployeeInfo } from "../services/globalData";
const MainPage = React.lazy(() => import("../app/main"));

function Main() {
  const [orgLoginStatus, setOrgLoginStatus] = useState(false);

  useEffect(() => {
    const checkOrganizationInfo = async () => {
      if (orgLoginStatus === false) {
        const res = await organizationLogin();
        const { success, data } = res || {};
        if (success) {
          setOnLoginEmployeeInfo(data);
          setOrgLoginStatus(true);
        } else {
          window.location.pathname = "/login";
        }
      }
    };
    checkOrganizationInfo();
  }, []);

  return (
    <>
      <Suspense fallback={<Spinner />}>
        {orgLoginStatus ? <MainPage /> : <Spinner />}
      </Suspense>
    </>
  );
}

export default Main;
