import { postCall, getCall, setOrgId, org_id } from "./api";
import { getOrgDetails, setOrgDetails } from "./globalData";

export const loginUser = async (token) => {
  const request = {
    firebaseToken: token,
    path: "/auth/login-firebase",
  };
  return await postCall(request);
};

export const registerUser = async (token, input = {}) => {
  const request = {
    firebaseToken: token,
    path: "/auth/register-user",
    bodyParams: input,
  };
  return await postCall(request);
};

export const verifyOTPForUser = async (token, otp) => {
  const request = {
    firebaseToken: token,
    path: "/auth/update-account",
    bodyParams: { otp },
  };
  return await postCall(request);
};

export const generateOTPForUser = async (token) => {
  const request = {
    firebaseToken: token,
    path: "/auth/recover-account",
  };
  return await postCall(request);
};

export const addLegalUnit = async (input = {}) => {
  const request = {
    path: "/legal-unit/",
    bodyParams: input,
  };
  return await postCall(request);
};

export const getLegalUnit = async () => {
  const request = {
    path: "/legal-unit/",
  };
  return await getCall(request);
};

export const registerOrg = async (input = {}) => {
  const request = {
    path: "/organization",
    bodyParams: input,
  };
  return await postCall(request);
};

export const updateOrgDetails = async (input = {}) => {
  const orgDetails = getOrgDetails();
  const request = {
    path: "/organization",
    bodyParams: input,
  };
  return await postCall(request);
};

// This will change to login employee.
export const organizationLogin = async () => {
  try {
    const orgDetails = getOrgDetails();
    const { _id: org_id } = orgDetails || {};
    if (org_id) {
      setOrgId(org_id);
      const request = {
        path: `/auth/access-token/${org_id}`,
      };
      return await getCall(request, "OrgLogin");
    } else {
      console.log("organsisation not found, switch to login page");
      window.location.path = "/login";
    }
  } catch (e) {
    console.log(e);
    window.location.path = "/login";
  }
};
// const res = await axios.request(requestOptions);

export const addCompanyDetails = async (input = {}) => {
  const request = {
    path: "/organization",
    bodyParams: input,
  };
  const res = await postCall(request);
  const { success, data } = res;
  if (success && data) {
    const { organizations } = data;
    setOrgDetails(organizations);
  }
  return res;
};

export const selectPlans = async (input = {}) => {
  const request = {
    path: "/organization",
    bodyParams: input,
  };
  return await postCall(request);
};

export const createEmployee = async (input = {}) => {
  const request = {
    path: "/employee/self-onboarding",
    bodyParams: input,
  };
  return await postCall(request);
};

export const getEmployee = async (input = {}) => {
  const request = {
    path: "/employee",
  };
  return await getCall(request);
};
