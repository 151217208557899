import React, { useState } from "react";
import { Box } from "@mui/material";
import { Outlet } from "react-router";
import Topbar from "../../../../../../../component/TopBar";
import MainBox from "../../../../../container/MainBox";

function TripsPolicy() {

  return (
     <Box sx={{width:"100%"}}>
        <Topbar MainHeading={"Trips Policy"}/>
        <MainBox sx={{ padding: "1.875rem 1.25rem" }}>
              <Outlet/>
        </MainBox>
     </Box>
      
  );
}

export default TripsPolicy;
