import React,{useState, useEffect} from 'react';
import {Box, CircularProgress} from '@mui/material';
import ActiveBar from "../../../../../../../../component/PolicyNavbarUpdated";
import BtnPrimary from '../../../../../../../../component/PrimaryButton';
import FormField from '../../../../../../../../component/Formfield';
import TypeBtn from '../../../../../../../../component/AddRemoveButton';
import { updatePolicy } from '../../../../../../../../services/policy';
import { getLegalUnitList } from '../../../../../../../../services/globalData';
import {useLocation, useNavigate} from "react-router-dom";
import ErrorMsg from '../../../../../../../../component/ErrorMsg';
import {getAllVehicleType} from "../../../../../../../../services/policyCategory";


const AddVehicleType = () => {

  const [apiCall,setApiCall]=useState(false);
  const navigate = useNavigate();
  const {state} = useLocation();
  const [policyId, setPolicyId] = useState(null);
  const [vehicleTypes,setVehicleTypes] = useState([{_id:"",code:"",ratePerKm:""}]);
  const [vehicleOptions, setVehicleOptions] = useState([]);
  const [error,setError]=useState("");

  console.log(state);

  useEffect(()=>{
    getVehicleTypeList();
    setPolicyId(state.policyId);
  },[]);

  const getVehicleTypeList = async () => {
    try {
      const legalUnit = getLegalUnitList();
      const res = await getAllVehicleType(legalUnit[0]._id);
      const vehicles = res.data.map(vehicle => ({ value: vehicle._id, name: vehicle.name, code: vehicle.code}));
      console.log(vehicles);
      setVehicleOptions(vehicles);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


    const handleAddVehicleType = () =>{
      setVehicleTypes([...vehicleTypes,{ _id: "", code: "", ratePerKm:"" }])
    }

    const handleRemoveVehicleType = (index) => {
      let selectedVehicleType = [...vehicleTypes];
      selectedVehicleType.splice(index, 1);
      setVehicleTypes(selectedVehicleType);
    };

    const handleVehicleSelection = (index, fieldName, value) => {
      let selectedVehicleType = [...vehicleTypes];
      selectedVehicleType[index][fieldName] = value;
      setVehicleTypes(selectedVehicleType);
    };

    //returns corresponding code when a vehicle is selected
    const getCode = (id) =>{
        return vehicleOptions.find((option)=>option.value===id)?.code;  
    }

    const handleNextClick = async() => {
     setApiCall(true);
     try {
          const res = await updatePolicy(policyId,{policyCateogary:vehicleTypes});
          console.log(res);
          if(res.success){
            navigate("/app/trips/new/assign-policy",{state:{policyId}});
          }else{
            console.error('Policy updation failed:', res.error);
            setError(res.message);
          }
      } catch (error) {
          console.error('Error saving Policy:', error);
      }
      setApiCall(false);
  };

  console.log(vehicleTypes);

  return (
      <Box sx={{width:"100%"}}>
          <Box sx={{display:"flex", justifyContent:"space-between", marginBottom:"1rem"}}>
              <ActiveBar active={["Policy Details","Add Category"]} value="36"/>
          </Box>
          <Box> 
            {
              vehicleTypes?.map((item,index)=>(
                  <Box key={index} sx={{ width: "100%", display: "flex",flexWrap: "wrap", justifyContent: "space-between", alignItems:"center" }}>
                        <FormField
                            label="Choose Vehicle Type"
                            type="select"
                            options={vehicleOptions}
                            value={item._id}
                            onChange={(e) => {
                                      handleVehicleSelection(index, '_id', e.target.value)
                                      handleVehicleSelection(index, 'code', getCode(e.target.value) )
                                    }}
                        />
                        <FormField
                            label="Vehicle Type Code"
                            type="text"
                            value={item.code}
                            disabled={true}
                        />
                        <FormField
                         label="Rate per km"
                         type="text"
                         value={item.ratePerKm}
                         onChange={(e) => handleVehicleSelection(index, 'ratePerKm', e.target.value)}
                       />
                        <TypeBtn type="remove" onClick={()=>handleRemoveVehicleType(index)} sx={{width: '1.125rem', height: '1.125rem'}}>
                        </TypeBtn>
                  </Box>           
              ))
            }
            <ErrorMsg>{error}</ErrorMsg>
            {(vehicleTypes.length===0 || vehicleTypes[vehicleTypes?.length - 1]?._id !=="")?(
                <TypeBtn type="add" onClick={handleAddVehicleType}>
                    Add Vehicle Type
                </TypeBtn>
            ):null}
          </Box>
          
          <Box sx={{display:"flex", justifyContent:"end", gap:"1rem", marginTop:"1.875rem"}}>
              <BtnPrimary 
                onClick={handleNextClick} 
                sx={{width:"10rem", height:"3.125rem", borderRadius:"10px"}}
                disabled={apiCall}
              >
                  {apiCall?<CircularProgress/>:"Next"}
              </BtnPrimary>
          </Box>
      </Box>
    )
}

export default AddVehicleType;