import React, {useState} from 'react';
import { Box, CircularProgress } from '@mui/material';
import TopBar from '../../../../../../../../component/TopBar';
import MainBox from '../../../../../../container/MainBox';
import ActiveBar from '../../../../../../../../component/PolicyNavbarUpdated';
import BtnSecondary from "../../../../../../../../component/SecondaryButton";
import BtnPrimary from "../../../../../../../../component/PrimaryButton";
import ErrorMsg from '../../../../../../../../component/ErrorMsg';
import { useNavigate } from 'react-router';
import FormField from '../../../../../../../../component/Formfield';
import DateField from '../../../../../../../../component/DateField';

const RotationFrequency = () => {
  const [apiCall,setApiCall]=useState(false);
    const navigate = useNavigate();
    const [shiftData,setShiftData] = useState({
          rotationType:"Weekly",
          rotationFrequency:"",
          freqType:"weeks",
          startDate:null
    });
    const [startDateDialogOpen, setStartDateDialogOpen] = useState(false);
    const [validationErrors, setValidationErrors] = useState({rotationType:"",
                                                              rotationFrequency:"",
                                                            });
   const [error,setError]=useState("");

   const getFrequencyType = (frequency) =>{
    const freqType =  frequency==="Weekly"?"Weeks":"Months";
    return freqType;
  }
  
   const handleNextClick = async() => {
        const errors ={};
  
        if (!shiftData.rotationType) errors.rotationType="Rotation Type is required"
        if (!shiftData.rotationFrequency) errors.rotationFrequency="Rotation Frequency is required"
        
        if (Object.keys(errors).length > 0) {
          setValidationErrors(errors);
          return;
        }
  
        console.log(shiftData);
        setApiCall(true);
        try {
           
        } catch (error) {
            console.error('Error creating Shift:', error);
        }
        setApiCall(false);
    };
  
    const handleChange = (field, value) => {
      setShiftData(prevState => ({...prevState, [field]: value}));
      setValidationErrors(prevState => ({...prevState, [field]: ""}));
      setError("");
    };
  
  return ( 
    <Box sx={{width:"100%", paddingBlock:"1rem"}}>
      <TopBar MainHeading={"Shift Rotation"}/>
      <MainBox sx={{ padding: "1.8125rem 1.25rem", height:"100%" }}>
            <Box sx={{display:"flex", justifyContent:"space-between", marginBottom:"1rem"}}>
                <ActiveBar page="shift-rotation" active={["Shift", "Frequency"]} value="25"/>
            </Box>
            <Box sx={{ width: "100%"}}>
                        <FormField
                            label="Rotation Type"
                            type="select"
                            value={shiftData.rotationType}
                            validation={validationErrors.rotationType}
                            options={["Weekly","Monthly"]}
                            onChange={(e) => {
                                        handleChange('rotationType', e.target.value)
                                        handleChange('freqType', getFrequencyType(e.target.value))  
                                    }}
                        />
                        <FormField
                            label="Rotation Frequency"
                            type="text"
                            value={shiftData?.rotationFrequency}
                            validation={validationErrors?.rotationFrequency}
                            placeholder={`select no. of ${shiftData?.freqType?.toLowerCase()} before rotation will occur`}
                            onChange={(e) => handleChange('rotationFrequency', e.target.value)}
                            endAdornment={shiftData?.freqType}
                        />
                        <DateField
                            label="Shift rotation start date"
                            value={shiftData.startDate}
                            isDialogOpen={startDateDialogOpen}
                            validation={validationErrors.startDate}
                            openDialog={() => setStartDateDialogOpen(true)}
                            closeDialog={() => setStartDateDialogOpen(false)}
                            onSelect={(date) => {
                                    handleChange('startDate', date)
                                    setStartDateDialogOpen(false)
                                    }}
                        />
            </Box>
            <ErrorMsg>{error}</ErrorMsg>
            <Box sx={{display:"flex", justifyContent:"end", gap:"1rem", marginTop:"1.875rem"}}>
                <BtnPrimary 
                    onClick={handleNextClick} 
                    sx={{width:"11.875rem", height:"3.125rem", borderRadius:"10px"}}
                    disabled={apiCall}
                >
                    {apiCall?<CircularProgress/>:"Next"}
                </BtnPrimary> 
            </Box>
      </MainBox> 
    </Box>    
  )
}

export default RotationFrequency;