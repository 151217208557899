import React, {useState } from "react";
import {Box, TableCell} from "@mui/material";
import PaginationTable from "../../../../../../../component/PaginationTable";
import styled from '@emotion/styled';

export const StyledTableCell = styled(TableCell)({
    fontWeight: "300",
    fontSize: "0.8rem",
    lineHeight: "1.125rem",
    padding: "1rem 0rem",
    textAlign:"center",
    color:"#16151C",
});

export default function RequestTable(){
  const [data, setData] = useState([]);

  return (
             <PaginationTable
                customWidth="446px"
                paginationHide
                rows={data}
                noRecordMessage={"No Record Found"}
                renderCell={(row) => (
                  <>
                    <StyledTableCell sx={{width:"5.56rem"}}>{row?.name}</StyledTableCell>
                    <StyledTableCell sx={{width:"8.62rem"}}>{row?.date}</StyledTableCell>
                    <StyledTableCell sx={{width:"4.87rem"}}>{row?.time}</StyledTableCell>
                    <StyledTableCell sx={{width:"8.62rem", display:"flex",justifyContent:"center"}}>
                      <Box sx={{color:"#F99500",border:"1px solid #F99500", borderRadius:"6.6px", width:"6rem"}}>
                         {row?.type}
                      </Box>
                    </StyledTableCell>
                  </>
                )}
              />
  );
};
