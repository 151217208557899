import React from "react";
import { Box, Stack} from "@mui/material";
import Topbar from "../../../../../component/TopBar";
import styled from "@emotion/styled";
import Header from "../../../../../component/DashboardHeader";
import PayrollTable from "./payrolltable";
import Card from "../../../container/Card";
import { AvatarWrapper, AvatarImage } from "../../../container/Avatar";
import Payroll from "../../../../../assets/icons/money.svg";
import PayrollSuccess from "../../../../../assets/icons/money-checkmark.svg";
import PayrollPending from "../../../../../assets/icons/money-process.svg";
import BtnFilter from "../../../../../component/FilterButton";
import BtnPrimary from "../../../../../component/PrimaryButton";

const OuterContainer = styled(Box)({width: "100%"});

const TextBox = styled(Box)({
  display: "block",
  whiteSpace: 'nowrap',
});

const cardItems = [
  { icon: Payroll, noOfEmployees: "150", label: "Total Payroll", value: "5000000", payroll: "Total Payroll", width: "calc(60% - 0.96rem)"},
  { icon: PayrollPending, noOfEmployees: "10", label: "Pending Payroll", value: "150", payroll: "Pending Payroll" , width: "calc(40% - 0.48rem)"},
  { icon: PayrollSuccess, noOfEmployees: "140", label: "Paid Payroll", value: "485" , width: "calc(40% - 0.48rem)"} // Assuming no buttons for Paid Payroll
]

export default (props) => {

  return (
    <Box>
      <Topbar />
      <OuterContainer>
        <Header heading="Payroll" tab1="Dashboard" tab2="Payroll" tab3="Payroll Information" />
        <Stack width="100%">
          <Box sx={{width:"100%", display:"flex", justifyContent:"space-between", marginBottom:"1.5rem"}}>
              {cardItems.map((item, index) => (
                  <Card sx={{padding:"1.5rem"}}>
                      <Box sx={{width:"100%", display:"flex", justifyContent:"space-between", gap:"1rem"}}>
                         <AvatarWrapper>
                             <AvatarImage src={item.icon} />
                          </AvatarWrapper>
                          <TextBox fontSize={"0.75rem"} fontWeight={300} lineHeight={"1.25rem"} color={"#00000099"} textAlign="right">
                                 No. of Employees: <span sx={{ color: "#069855" }}>{item.noOfEmployees}</span>
                          </TextBox>
                       </Box>
                       <Box sx={{width:"100%", display:"flex", justifyContent:"space-between", gap:"1rem", marginTop:"1rem"}}>
                         <Box>
                            <TextBox fontSize={"0.75rem"} fontWeight={300} lineHeight={"1.25rem"}>
                                {item.label}
                            </TextBox>
                            <TextBox fontSize={"1.5rem"} lineHeight={"2.5rem"} >
                                 ₹{item.value}
                            </TextBox>
                         </Box>
                         {item.payroll === "Total Payroll" && (
                              <Box sx={{display:"flex", gap:"1rem"}}>
                                  <BtnFilter sx={{ padding: '1rem 1.5rem', borderRadius: '12px', fontWeight: "300"}}>View schedule</BtnFilter>
                                  <BtnPrimary sx={{ padding: '1rem 1.5rem', borderRadius: '12px', fontWeight: "300" }}>Pay payroll</BtnPrimary>
                              </Box>
                          )}
                          {item.payroll === "Pending Payroll" && (
                                 <BtnFilter sx={{ padding: '1rem 1.5rem', borderRadius: '12px', fontWeight: "300" }}>View</BtnFilter>
                          )}
                       </Box>
                  </Card>
                ))
              }
          </Box>

          <PayrollTable />
        </Stack>
      </OuterContainer>
    </Box>
  );
};
