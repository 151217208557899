import React from 'react';
import { Box, Checkbox } from '@mui/material';

const CheckboxField = ({ label, checked, onChange,labelStyle,checkboxStyle, disabled, helpText}) => {
  const handleCheckboxChange = (e) => {
    onChange(e.target.checked); 
  }; 

  return (
    <Box sx={{marginBlock:"0.75rem", display:"flex", alignItems:"center", width:"100%"}}>
      <Checkbox
        checked={checked}
        onChange={handleCheckboxChange}
        disabled={disabled}
        sx={{
          "&.MuiButtonBase-root":{
            padding:0, marginRight:"0.5rem"
          },
          "& .MuiSvgIcon-root":checkboxStyle||null
        }}
      />
      <Box>
        <Box sx={labelStyle}>{label}</Box>
        <Box sx={{fontWeight:"300", fontSize: "0.875rem", lineHeight: "1.25rem", color:"#818181"}}>
            {helpText}         
        </Box>
      </Box>
    </Box>
  );
};

export default CheckboxField;
