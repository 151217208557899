import React, { useState, useEffect } from "react";
import { Box, Stack, Avatar, TableCell } from "@mui/material";
import styled from "@emotion/styled";
import BtnFilter from "../../../../../component/FilterButton";
import PaginationTable from "../../../../../component/PaginationTable";

const CustomTableCell = styled(TableCell)({
  fontWeight: "300",
  fontSize: "1rem",
  color: "#16151C",
  lineHeight: "1.5rem",
  padding: "0.25rem 0.75rem 0.25rem 0.75rem",
});

const Heading = {
  fontWeight: "300",
  fontSize: "1rem",
  lineHeight: "1.5rem",
  color: "#A2A1A8",
  padding: "0.625rem 0.625rem 0.625rem 0.625rem",
  textAlign: "left",
};

const TextBox = styled(Box)({
  display: "block",
  wordBreak: "break-word",
  color: "#16151C",
  fontWeight: "600",
  fontSize: "1.25rem",
  lineHeight: "1.875rem",
});

export default function Overview(props) {
  const [data, setData] = useState([
    {
      fname: "John",
      lname: "Doe",
      designation: "manager",
      type: "Office",
      checkintime: "9:00 A.M.",
      status: "Late",
    },
    {
      fname: "John",
      lname: "Doe",
      designation: "manager",
      type: "Office",
      checkintime: "8:00 A.M.",
      status: "On Time",
    },
    {
      fname: "John",
      lname: "Doe",
      designation: "manager",
      type: "Office",
      checkintime: "8:00 A.M.",
      status: "On Time",
    },
  ]);

  const headings = [
    { label: "Employee Name", style: { ...Heading } },
    { label: "Designation", style: { ...Heading } },
    { label: "Type", style: { ...Heading } },
    { label: "Check In Time", style: { ...Heading } },
    { label: "Status", style: { ...Heading } },
  ];

  return (
    <Box>
      <Stack
        width="100%"
        direction="row"
        justifyContent="space-between"
        marginBottom={"1rem"}
      >
        <TextBox>Attendance Overview</TextBox>
        <BtnFilter
          sx={{
            padding: "0.625rem",
            borderRadius: "10px",
            fontWeight: "300",
            fontSize: "0.875rem",
          }}
        >
          View All
        </BtnFilter>
      </Stack>

      <PaginationTable
        rows={data}
        headings={headings}
        renderCell={(row, index) => (
          <>
            <CustomTableCell align="left">
              <Box display="flex" alignItems="center">
                <Avatar
                  alt={`${row.fname} ${row.lname}`}
                  sx={{
                    width: "2.5rem",
                    height: "2.5rem",
                    marginRight: "0.78rem",
                  }}
                />
                {row.fname} {row.lname}
              </Box>
            </CustomTableCell>
            <CustomTableCell align="left">{row.designation}</CustomTableCell>
            <CustomTableCell align="left">{row.type}</CustomTableCell>
            <CustomTableCell align="left">{row.checkintime}</CustomTableCell>
            <CustomTableCell align="left">
              <Box
                sx={{
                  display: "inline-block",
                  border: "0.5px solid",
                  borderRadius: "4px",
                  color: row.status === "Late" ? "#D62525" : "#069855",
                  backgroundColor:
                    row.status === "Late" ? "#FDF4F4" : "#E6F5EE",
                  padding: "0.25rem 0.75rem",
                }}
              >
                {row.status}
              </Box>
            </CustomTableCell>
          </>
        )}
      />
    </Box>
  );
}
