import React from "react";
import styled from "@emotion/styled";
import { Box } from "@mui/material";

const ItemName = styled(Box)({
  fontSize: "1rem",
  lineHeight: "1.5rem",
  wordWrap: "break-word",
  "&:hover": {
    cursor: "pointer",
  },
});


const TabbarMenuItem = (props) => {
  const { item, onItemClick, activeTab } = props;
  const { icon: Icon } = item;
  return (
      <Box
        sx={{ 
            display: "flex", 
            alignItems: "center",
            justifyContent: "space-between",
            gap: "0.625rem", 
            borderBottom: activeTab.key === item.key ? "2px solid #0081FF" : "none",
            paddingBottom: activeTab.key === item.key ? "1px" : "0",
        }}
        onClick={() => onItemClick(item)}
      >
            <Icon color={activeTab.key === item.key ? "#0081FF" : "#16151C"} />
            <ItemName
              color={activeTab.key === item.key ? "#0081FF" : "#16151C"}
              sx={{
                fontWeight: activeTab.key === item.key ? 600 : 300, 
              }}
            >
              {item.name}
            </ItemName>
      </Box>
  );
};

export default TabbarMenuItem;
