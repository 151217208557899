import React, {useState} from "react";
import {Avatar, Box, TableCell} from "@mui/material";
import MainBox from "../../../../container/MainBox";
import TopBar from "../../../../../../component/TopBar";
import { getOrgGroups } from "../../../../../../services/globalData";
import FormField from "../../../../../../component/Formfield";
import BtnPrimary from '../../../../../../component/PrimaryButton';
import PaginationTable from "../../../../../../component/PaginationTable";
import { PolicyTableHeadings } from "../../../../../../styles/PolicyTableHeadings";
import styled from '@emotion/styled';
import CheckboxField from '../../../../../../component/CheckboxField';
import EditIcon from "../../../../../../assets/icons/pencil-black.svg";
import DeleteIcon from "../../../../../../assets/icons/delete-black.svg";
import ActiveBar from "../../../../../../component/PolicyNavbarUpdated";
import { useNavigate } from "react-router";
import ErrorMsg from "../../../../../../component/ErrorMsg";

const CustomTableCell = styled(TableCell)({
    fontWeight: "300",
    fontSize: "1rem",
    lineHeight: "1.5rem",
    textAlign:"center",
});


const PayGroup = () => {

  const allGroups = getOrgGroups(); 
  const [sortBy, setSortBy] = useState();
  const [selectedGroup, setSelectedGroup] = useState(null);
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const groupsHeadings = [
    { label: "", style: {...PolicyTableHeadings, width:"3rem", borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px" } },
    { label: "Group Name", style: {...PolicyTableHeadings,  width:"12.56rem"} },
    { label: "Employee", style: {...PolicyTableHeadings,  width:"12.56rem"} },
    { label: "Pay Cycle", style: {...PolicyTableHeadings,  width:"12.56rem"} },
    { label: "Payroll Status", style: {...PolicyTableHeadings,  width:"12.56rem"} },
    { label: "Action", style: { ...PolicyTableHeadings,width:"4.625rem", borderTopRightRadius: "12px", borderBottomRightRadius: "12px" } }
    ];

    const handleCheckboxChange = (id, isChecked) => {
        setError("");
        if (isChecked) {
            setSelectedGroup(id); 
        } else {
            setSelectedGroup(null);
        }
    };

    const handleProceedClick = () =>{
        if(selectedGroup===null){
            setError("Select a group to proceed");
            return;
        }
        navigate("/app/settings/payroll/paygroupdetails",{state:{group:selectedGroup}})
    }
  

  return (
     <Box sx={{width:"100%", paddingBlock:"1rem"}}>
        <TopBar 
          MainHeading={"Payroll"} 
          breadcrumbs={[{label:"Settings", href:""},{label:"Payroll", href:""},{label:"Pay Group", href:""}]}
        />
        <MainBox sx={{ padding: "1rem 1.25rem"}}>
            <ActiveBar active={["Pay Group"]} value="16" page="pay-group"/>
            <Box sx={{marginTop:"1rem"}}>
            <Box sx={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between",marginBottom:"0.75rem" }}>
                        <FormField
                            label="Sort By"
                            type="select"
                            options={[]}
                            value={sortBy}
                            onChange={(e)=>{setSortBy(e.target.value)}}
                        />
                        <FormField
                            label="Search"
                            type="text"
                            
                        />
            </Box>
            <Box>
                <Box sx={{padding:"10px", fontWeight:"500",fontSize:"1.25rem",lineHeight:"1.875rem", color:"#0081FF", marginBottom:"1.875rem"}}>
                        Groups / 
                </Box>
                <PaginationTable
                    rows={allGroups}
                    headings={groupsHeadings}
                    noRecordMessage={"No Record Found"}
                    renderCell={(row) => (
                    <>
                        <CustomTableCell>
                            <CheckboxField
                                checked={(selectedGroup===row._id)}
                                onChange={(isChecked) => handleCheckboxChange(row._id, isChecked)}
                            />
                        </CustomTableCell>
                        <CustomTableCell>{row?.name}</CustomTableCell>
                        <CustomTableCell>{row?.employee?.length}</CustomTableCell>
                        <CustomTableCell></CustomTableCell>
                        <CustomTableCell></CustomTableCell>
                        <CustomTableCell>
                            <Box sx={{width:"100%", display:"flex", gap:"1rem"}}>
                                 <Avatar src={EditIcon} sx={{height:"1.125rem", width:"1rem"}}/>
                                 <Avatar src={DeleteIcon} sx={{height:"1.125rem", width:"1rem"}}/>
                            </Box>
                        </CustomTableCell>
                    </>
                    )}
                />
                <ErrorMsg>{error}</ErrorMsg>
                <Box sx={{display:"flex", justifyContent:"end", marginTop:"1.875rem"}}>
                        <BtnPrimary 
                        onClick={handleProceedClick} 
                        sx={{width:"11.875rem", height:"3.125rem", borderRadius:"10px"}}
                        >
                         Proceed
                        </BtnPrimary>
                </Box>
            </Box>
            </Box>
        </MainBox>
     </Box>
  );
};

export default PayGroup;
