import { postCall, getCall, putCall } from "./api";

// Leave Type

export const createLeaveType = async (input = {}) => {
  const request = {
    path: `/leave-type`,
    bodyParams: input,
  };
  return await postCall(request);
};

export const getAllLeaveType = async () => {
  const request = {
    path: `/leave-type`,
  };
  return await getCall(request);
};

export const getLeaveTypeById = async (id) => {
  const request = {
    path: `/leave-type/${id}`,
  };
  return await getCall(request);
};

export const updateLeaveType = async (id, input = {}) => {
  const request = {
    path: `/leave-type/${id}`,
    bodyParams: input,
  };
  return await putCall(request);
};

// Leave Request

export const leaveRequest = async (input = {}) => {
    const request = {
        path: `/leave-request`,
        bodyParams: input,
      };
      return await postCall(request);
};

export const getleaveDetails = async () => {
  const request = {
    path: `/leave-request`,
  };
  return await getCall(request);
};

export const getAllLeaveRequests = async () => {
  const request = {
    path: `/leave-request`,
  };
  return await getCall(request);
};

//  get leave request by id

export const getLeaveRequestById = async (leaveId) => {
  const request = {
    path: `/leave-request/${leaveId}`,
  };
  return await getCall(request);
};

//review leave request
export const reviewLeaveRequest = async (input = {}, id) => {
  const request = {
      path: `/leave-request/${id}/review`,
      bodyParams: input,
    };
    return await postCall(request);
};

// Leave Policy

export const createLeavePolicy = async (input = {}) => {
    const request = {
      path: `/leave-policy`,
      bodyParams: input,
    };
    return await postCall(request);
  };
  
  export const getAllLeavePolicy = async () => {
    const request = {
      path: `/leave-policy`,
    };
    return await getCall(request);
  };

  export const getLeavePolicyById = async (id) => {
    const request = {
      path: `/leave-policy/${id}`,
    };
    return await getCall(request);
  };

  export const updateLeavePolicy = async (id, input = {}) => {
    const request = {
      path: `/leave-policy/${id}`,
      bodyParams: input,
    };
    return await putCall(request);
  };

  //add departments to leave policy
  export const addDepartmentsToLeavePolicy = async (policyId, input = {}) => {
    const request = {
      path: `/leave-policy/${policyId}/departments-add`,
      bodyParams: input,
    };
    return await postCall(request);
  };

  //remove departments from leave policy
  export const removeDepartmentsFromLeavePolicy = async (policyId, input = {}) => {
    const request = {
      path: `/leave-policy/${policyId}/departments-remove`,
      bodyParams: input,
    };
    return await postCall(request);
  };

  export const addGroupsToLeavePolicy = async (policyId, input = {}) => {
    const request = {
      path: `/leave-policy/${policyId}/groups-add`,
      bodyParams: input,
    };
    return await postCall(request);
  };

  //remove departments from leave policy
  export const removeGroupsFromLeavePolicy = async (policyId, input = {}) => {
    const request = {
      path: `/leave-policy/${policyId}/groups-remove`,
      bodyParams: input,
    };
    return await postCall(request);
  };
  