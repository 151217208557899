import HolidaySettingController from "./holidaysettings/index";
import CalenderSettingController from "./calendersettings/index";
import GeneralSettingController from "./holidaysettings/index";
import AccountAccessController from "./holidaysettings/index";
import AccountAccess from "../../../../../assets/icons/AccountAccess";
import HolidaySetting from "../../../../../assets/icons/HolidaySetting";
import GeneralSettings from "../../../../../assets/icons/GeneralSettings";
import CalenderSetting from "../../../../../assets/icons/CalenderSetting";

export const TabList = [
  {
    key: "0",
    name: "General Setting",
    controller: GeneralSettingController,
    icon: GeneralSettings,
  },
  {
    key: "1",
    name: "Calender Setting",
    controller: CalenderSettingController,
    icon: CalenderSetting,
  },
  {
    key: "2",
    name: "Holiday Setting",
    controller: HolidaySettingController,
    icon: HolidaySetting,
  },
  {
    key: "3",
    name: "Account Access",
    controller: AccountAccessController,
    icon: AccountAccess,
  },
];


