import React, { Suspense } from "react";
import Spinner from "../component/Spinner";
const ForgetPasswordPage = React.lazy(() => import("../app/forgetPassword"));

function ForgetPassword() {
  return (
    <>
      <Suspense fallback={<Spinner />}>
        <ForgetPasswordPage />
      </Suspense>
    </>
  );
}

export default ForgetPassword;
