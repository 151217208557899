import React,{useState, useEffect} from 'react';
import {Box} from "@mui/material";
import PaginationTable from '../../../../../../component/PaginationTable';
import {getAllLeaveType} from "../../../../../../services/leave";
import { getEmployeeNames } from '../../../../../../utils/getEmployeeNames';
import { useNavigate } from 'react-router';
import PolicyListingHeader from "../../../../../../component/PolicyListingHeader";
import { PolicyTableCell } from "../../../../../../styles/PolicyTableCell";
import { PolicyTableHeadings } from "../../../../../../styles/PolicyTableHeadings";

const LeaveTypeListing = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const res = await getAllLeaveType();
          const filteredData = res.data.filter(data => data.createdBy !== undefined);
          const employeeIds = filteredData.map(data => data.createdBy);
          const combinedData = await getEmployeeNames(res.data, employeeIds, "createdBy");
          console.log(combinedData);
          setData(combinedData.reverse());
          setLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoading(false);
        } 
      };
    
      fetchData();
    }, []);
  
    const headings = [
      { label: "Code", style: {... PolicyTableHeadings , borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px" } },
      { label: "Leave Name", style: {... PolicyTableHeadings } },
      { label: "Created by", style: {... PolicyTableHeadings } },
      { label: "Created on", style: {... PolicyTableHeadings } },
      { label: "Policy attached", style: { ... PolicyTableHeadings , borderTopRightRadius: "12px", borderBottomRightRadius: "12px" } }
    ];

    const handleMenuItemClick = (id) => { 
      navigate("form",{state:{typeId:id}});
    };

  return (
    <Box>
        <PolicyListingHeader 
          Heading = "Leave Types"
          ButtonText = "+ Create Leave Type"
          ButtonLink = "form"
        />
        <PaginationTable
          rows={data}
          headings={headings}
          loading={loading}
          noRecordMessage={"No Record Found"}
          renderCell={(row) => (
            <>
              <PolicyTableCell >{row.leaveCode}</PolicyTableCell>
              <PolicyTableCell onClick={() => handleMenuItemClick(row._id)}>
                <Box sx={{width:"100%", color:"#008CC4", ":hover":{cursor:"pointer"}, textDecoration:"underline"}}>
                  {row.name}
                </Box>
              </PolicyTableCell>
              <PolicyTableCell >{row?.employee?.fname+" "+row?.employee?.lname}</PolicyTableCell>
              <PolicyTableCell >{new Date(row.createdAt).toLocaleDateString('en-GB')}</PolicyTableCell>
              <PolicyTableCell >{row.policyAttached||0}</PolicyTableCell>
            </>
          )}
        />
    </Box>
  )
}

export default LeaveTypeListing