import React, {useState} from 'react';
import MainBox from '../../../../../../container/MainBox';
import styled from "@emotion/styled";
import TopBar from '../../../../../../../../component/TopBar';
import {Box, CircularProgress} from '@mui/material';
import ActiveBar from '../../../../../../../../component/PolicyNavbarUpdated';
import BtnSecondary from "../../../../../../../../component/SecondaryButton";
import BtnPrimary from "../../../../../../../../component/PrimaryButton";
import ErrorMsg from '../../../../../../../../component/ErrorMsg';
import FormField from '../../../../../../../../component/Formfield';
import { useNavigate } from 'react-router';
import CheckboxField from '../../../../../../../../component/CheckboxField';
import { Link } from 'react-router-dom';

const TextBox = styled(Box)({
    fontSize:"1rem",
    lineHeight:"1.5rem"
});

const RotationDetails = () => {
    const [apiCall,setApiCall]=useState(false);
    const navigate = useNavigate();
    const [shiftRotationData,setShiftData] = useState({
          name:"",
          description:"",
          numberOfShifts:""
    });
    const [validationErrors, setValidationErrors] = useState({name:"",
                                                              description:"",
                                                            });
   const [error,setError]=useState("");
  
   const handleNextClick = async() => {
        const errors ={};
  
        if (!shiftRotationData.name) errors.name="name is required"
        if (!shiftRotationData.numberOfShifts) errors.numberOfShifts="Number of shifts is required"
        
        if (Object.keys(errors).length > 0) {
          setValidationErrors(errors);
          return;
        }
  
        console.log(shiftRotationData);
        setApiCall(true);
        try {
           
        } catch (error) {
            console.error('Error creating Shift:', error);
        }
        setApiCall(false);
    };
  
    const handleChange = (field, value) => {
      setShiftData(prevState => ({...prevState, [field]: value}));
      setValidationErrors(prevState => ({...prevState, [field]: ""}));
      setError("");
    };
  
  return (
    <Box sx={{width:"100%", paddingBlock:"1rem"}}>
        <TopBar 
            MainHeading={"Shift Rotation"}
            breadcrumbs={[{label:"Shift Rotation", href:""},{label:"Shift Rotation Details", href:""}]}
        />
        <MainBox sx={{ padding: "1.8125rem 1.25rem", height:"100%" }}>
            <Box sx={{display:"flex", justifyContent:"space-between", marginBottom:"1rem"}}>
                <ActiveBar page="shift-rotation" active={["Shift"]} value="10"/>
                <BtnSecondary to={"/app/shift/listing"} sx={{width:"5.56rem", height:"2.25rem", borderRadius:"10px"}}>
                    Back
                </BtnSecondary>
            </Box>
            <Box sx={{ width: "100%"}}>
                        <FormField
                            label="Rotation Name"
                            type="text"
                            value={shiftRotationData.name}
                            validation={validationErrors.name}
                            onChange={(e) => handleChange('name', e.target.value)}
                        />
                        <FormField
                            label="Description"
                            type="text"
                            value={shiftRotationData.description}
                            fullwidth
                            row={3}
                            onChange={(e) => handleChange('description', e.target.value)}
                        />
                        <FormField
                            label="How many shifts for rotation?"
                            type="text"
                            value={shiftRotationData.numberOfShifts}
                            validation={validationErrors.numberOfShifts}
                            onChange={(e) => handleChange('numberOfShifts', e.target.value)}
                            endAdornment="Shifts"
                        />
                    
            </Box>
            <ErrorMsg>{error}</ErrorMsg>
            <Box sx={{display:"flex", justifyContent:"end", gap:"1rem", marginTop:"1.875rem"}}>
                <BtnPrimary 
                    onClick={handleNextClick} 
                    sx={{width:"11.875rem", height:"3.125rem", borderRadius:"10px"}}
                    disabled={apiCall}
                >
                    {apiCall?<CircularProgress/>:"Next"}
                </BtnPrimary> 
            </Box>
        </MainBox>
    </Box>
    )
}

export default RotationDetails;