import React from 'react'
import styled from "@emotion/styled";
import {Avatar, Box, Tooltip} from "@mui/material";
import PencilAlt from "../../../../../../../assets/icons/pencil-alt.png";
import { useNavigate } from 'react-router';

const Heading = styled(Box)({
    fontWeight:"500",
    fontSize:"1.25rem",
    lineHeight:"1.75rem",
    color:"#141414"
 })

 const Container = styled(Box)({
    borderRadius:"12px",
    padding:"1.5rem",
    border:"1px solid var(--Colors-Prime-Gray-Prime-Gray-300, #D1D5DB)",
 });
 
 const TextBox = styled(Box)({
    lineHeight:"1.5rem",
 })

const InfoCard = ({headingText, data, employeeId, name}) => {

  const navigate = useNavigate();
  const handleEdit = () => {
     navigate(`/app/edit-employee-details/${employeeId}`,{state:{data, headingText, name}});
  }

  return (
         headingText==="Basic Information"?(
            <Container>
                  <Box sx={{display:"flex", justifyContent:"space-between"}}>
                        <Avatar src={data[0]?.value} alt="Image" sx={{height:"10rem", width:"10rem", borderRadius:"12px", marginBottom:"2.25rem"}}/>
                        <Tooltip title="Edit Details">
                           <Avatar src={PencilAlt} alt="Edit" 
                                    sx={{height:"1.5rem", width:"1.5rem", '&:hover': {cursor: "pointer"}}} 
                                    onClick={handleEdit}
                           />
                        </Tooltip>
                  </Box>
                  <TextBox sx={{fontWeight:"500",fontSize:"1.25rem", color:"#141414", marginBottom:"1rem"}}>
                     {data[1]?.value +" " +data[2]?.value}
                  </TextBox>
                  <TextBox sx={{fontSize:"1rem", color:"#141414", marginBottom:"0.75rem"}}>
                     Emp ID: {data[3]?.value}
                  </TextBox>
                  <Box sx={{display:"flex", flexDirection:"column", gap:"1rem",}}>
                  {
                     data.slice(4).map((item)=>{
                        return(
                           <Box sx={{display:"flex", justifyContent:"space-between"}}>
                           <TextBox sx={{fontSize:"1rem", color:"#00000099"}}>{item.label}</TextBox>
                           <TextBox sx={{fontSize:"1rem"}}>{item.value}</TextBox>
                           </Box> 
                        )
                     })
                  }
                  </Box>
            </Container>
         ):(
            <Container>
               <Box sx={{display:"flex", justifyContent:"space-between", alignItems:"center", marginBottom:"2.25rem"}}>
                  <Heading>{headingText}</Heading>
                  <Tooltip title="Edit Details">
                     <Avatar src={PencilAlt} alt="Edit" 
                              sx={{height:"1.5rem", width:"1.5rem", '&:hover': {cursor: "pointer"}}} 
                              onClick={handleEdit}
                     />
                  </Tooltip>
               </Box>
               <Box sx={{display:"flex", flexDirection:"column", gap:"1rem",}}>
               {
                  data.map((item)=>{
                     return(
                        <Box sx={{display:"flex", justifyContent:"space-between"}}>
                        <TextBox sx={{fontSize:"1rem", color:"#00000099"}}>{item.label}</TextBox>
                        <TextBox sx={{fontSize:"1rem"}}>{item.value}</TextBox>
                        </Box> 
                     )
                  })
               }
               </Box>
            </Container>
         )
)}

export default InfoCard