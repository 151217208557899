import React, {useState} from "react";
import {Avatar, Box, TableCell} from "@mui/material";
import MainBox from "../../../../container/MainBox";
import TopBar from "../../../../../../component/TopBar";
import BtnPrimary from '../../../../../../component/PrimaryButton';
import PaginationTable from "../../../../../../component/PaginationTable";
import { PolicyTableHeadings } from "../../../../../../styles/PolicyTableHeadings";
import styled from '@emotion/styled';
import CheckboxField from '../../../../../../component/CheckboxField';
import EditIcon from "../../../../../../assets/icons/pencil-black.svg";
import ActiveBar from "../../../../../../component/PolicyNavbarUpdated";
import { Link, useNavigate } from "react-router-dom";
import UpdateRecordDialog from "./UpdateRecord";

const CustomTableCell = styled(TableCell)({
    fontWeight: "300",
    fontSize: "1rem",
    lineHeight: "1.5rem",
    textAlign:"center",
});

const TextBox = styled(Box)({
    fontWeight: "300",
    fontSize: "1rem",
    lineHeight: "1.5rem",
    color:"#16151C"
});

const Card = styled(Box)({
    height:"10rem",
    backgroundColor:"#FAFAFB",
    padding:"1.25rem",
    borderRadius:"15px",
    boxShadow:"0px 1px 2px 0px #0000004D",
    display:"flex",
    flexDirection:"column",
    alignItems:"center"  
})

const CardMainHeading = styled(Box)({
    fontWeight: "600",
    fontSize: "1.875rem",
    lineHeight: "2.5rem",
    color:"#16151C"
});

const CardText = styled(Box)({
    fontSize: "0.75rem",
    lineHeight: "1.125rem",
    letterSpacing: "0.1%",
});


const PayGroupDetail = () => {

  const [groupEmployees, setGroupEmployees] = useState([{}]); 
  const [selectedEmployeesList, setSelectedEmployeesList] = useState([]);
  const navigate = useNavigate();
  const [isUpdateRecordDialog, setIsUpdateRecordDialog]=useState(false);

  const employeesDataHeadings = [
    { label: "", style: {...PolicyTableHeadings, width:"3rem", borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px" } },
    { label: "Employee Name", style: {...PolicyTableHeadings,  width:"12.56rem"} },
    { label: "Employee Id", style: {...PolicyTableHeadings,  width:"12.56rem"} },
    { label: "Paid Days", style: {...PolicyTableHeadings,  width:"12.56rem"} },
    { label: "Gross Pay", style: {...PolicyTableHeadings,  width:"12.56rem"} },
    { label: "Deduction", style: {...PolicyTableHeadings,  width:"12.56rem"} },
    { label: "Taxes", style: {...PolicyTableHeadings,  width:"12.56rem"} },
    { label: "Reimbursement", style: {...PolicyTableHeadings,  width:"12.56rem"} },
    { label: "Bonus/Overtime", style: {...PolicyTableHeadings,  width:"12.56rem"} },
    { label: "Net Pay", style: {...PolicyTableHeadings,  width:"12.56rem"} },
    { label: "Approval type", style: { ...PolicyTableHeadings,width:"4.625rem", borderTopRightRadius: "12px", borderBottomRightRadius: "12px" } }
    ];

    const handleCheckboxChange = (id, isChecked) => {
        setSelectedEmployeesList((prevList) => {
            if (isChecked) {
                return [...prevList, id];
            } else {
                return prevList.filter(employeeId => employeeId !== id);
            }
        });
    };

    const handleApprovalClick = (type) =>{
          navigate("approvals",{state:{type:type}});
    }

    const handleEditClick = (id) =>{
        navigate("/app/settings/payroll/salarytemplate",{state:{empId:id}});
   }
  

  return (
     <Box sx={{width:"100%", paddingBlock:"1rem"}}>
        <TopBar 
          MainHeading={"Payroll"} 
          breadcrumbs={[{label:"Settings", href:""},{label:"Payroll", href:""},{label:"Pay Group Detail", href:""}]}
        />
        <MainBox sx={{ padding: "1.375rem 1.25rem"}}>
            <ActiveBar active={["Pay Group Details"]} value="22" page="pay-group-details"/>
            <Box sx={{display:"flex", flexDirection:"column", gap:"1.5rem"}}>
                <Box sx={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                        <TextBox>
                            Group Name: 
                        </TextBox>
                        <TextBox>
                            Period: | Pay Cycle: | Status:  
                        </TextBox>
                        <BtnPrimary 
                         onClick={()=>setIsUpdateRecordDialog(true)} 
                         sx={{width:"11.875rem", height:"3.125rem", borderRadius:"10px"}}
                        >
                          Approve & Update
                        </BtnPrimary>
                </Box>
                <Box sx={{ width: "100%", display: "flex", flexDirection: "row", gap:"2.15rem", justifyContent: "space-between", color:"#6B7280"}}>
                        <Card sx={{width:"16.5rem"}}>
                          <CardMainHeading>28</CardMainHeading>
                          <CardText>Employee in Group</CardText>
                          <Box sx={{width:"100%",borderTop:"1px solid #A2A1A833", marginTop:"1.75rem", paddingTop:"1rem", textAlign:"center"}}>
                            <CardText>Monthly Payment Due in 3 days</CardText>
                          </Box>      
                        </Card>
                        <Card sx={{width:"21.25rem"}}>
                          <CardMainHeading>28</CardMainHeading>
                          <CardText>Total Group Payroll Cost </CardText>
                          <Box sx={{width:"100%",borderTop:"1px solid #A2A1A833", marginTop:"1.75rem", paddingTop:"1rem", textAlign:"center"}}>
                            <CardText>Total Tax Deduction</CardText>
                          </Box> 
                        </Card>
                        <Card sx={{width:"23.375rem"}}>
                          <CardMainHeading>28</CardMainHeading>
                          <CardText>Deviation in salary calculation</CardText>
                          <Box sx={{width:"100%",borderTop:"1px solid #A2A1A833", marginTop:"0.5rem", paddingTop:"1rem"}}>
                            <Box sx={{display:"flex", justifyContent:"space-between"}}>
                                 <CardText 
                                    sx={{color:"#008CC4", textDecoration:"underline", cursor: "pointer"}}
                                    onClick={()=>handleApprovalClick("no-attendance")}
                                 >
                                    1. No Attendance
                                  </CardText>
                                 <CardText></CardText>
                            </Box>
                            <Box sx={{display:"flex", justifyContent:"space-between"}}>
                                 <CardText 
                                    sx={{color:"#008CC4", textDecoration:"underline", cursor: "pointer"}}
                                    onClick={()=>handleApprovalClick("LOP")}
                                 >
                                    2. Loss Of Pay (LOP)
                                 </CardText>
                                 <CardText></CardText>
                            </Box>
                          </Box> 
                        </Card>  
                </Box>
                <Box>
                    
                    <PaginationTable
                        rows={groupEmployees}
                        headings={employeesDataHeadings}
                        noRecordMessage={"No Record Found"}
                        renderCell={(row) => (
                        <>
                            <CustomTableCell sx={{display:"flex", alignItems:"center"}}>
                                <CheckboxField
                                    checked={(selectedEmployeesList.includes(row._id))}
                                    onChange={(isChecked) => handleCheckboxChange(row._id, isChecked)}
                                />
                                <Avatar 
                                    src={EditIcon} 
                                    sx={{height:"1.3rem", width:"1.35rem", marginTop:"2.5px", cursor:"pointer"}}
                                    onClick={()=>handleEditClick(row._id)}
                                />
                            </CustomTableCell>
                            <CustomTableCell>{}</CustomTableCell>
                            <CustomTableCell>{}</CustomTableCell>
                            <CustomTableCell></CustomTableCell>
                            <CustomTableCell>{}</CustomTableCell>
                            <CustomTableCell>{}</CustomTableCell>
                            <CustomTableCell></CustomTableCell>
                            <CustomTableCell></CustomTableCell>
                            <CustomTableCell></CustomTableCell>
                            <CustomTableCell></CustomTableCell>
                            <CustomTableCell>
                                
                            </CustomTableCell>
                        </>
                        )}
                    />
                </Box>
            </Box>
        </MainBox>
        <UpdateRecordDialog
           open={isUpdateRecordDialog}
           onClose={()=>setIsUpdateRecordDialog(false)}
        />
     </Box>
  );
};

export default PayGroupDetail;
