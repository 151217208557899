import React,{useState, useEffect} from "react";
import {Box} from "@mui/material";
import styled from "@emotion/styled";
import TopBar from "../../../../../component/TopBar";
import BtnPrimary from "../../../../../component/PrimaryButton";
import LeaveData from "./leaveData";
import Calendar from "../../../../../component/CalendarMark";
import ApplyLeave from "./applyLeave";
import {getleaveDetails} from "../../../../../services/leave";

const MainBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    minHeight: "100vh",
    backgroundColor: "#FAFAFA",
    padding:"1.5rem",
});

const TextBox = styled(Box)({
    display: "block",
  });

export default (props) => {

 const [open, setOpen] = useState(false);
 const [leaveData, setLeaveData] = useState([]);

 const handleClickOpen = () => {
   setOpen(true);
 };
  
 const handleClose = () => {
   setOpen(false);
   fetchData();
 };
  
 useEffect(() => {
  fetchData();
}, []);

const fetchData = async () => {
  try {
    const res = await getleaveDetails();
    setLeaveData(res.data);
    console.log(res.data);
  } catch (error) {
    console.error('Error fetching leave details:', error);
  }
};
// Calculate leave approved and leave request pending
const leaveApprovedCount = leaveData.filter(item => item.status === 'Approved').length;
const leavePendingCount = leaveData.filter(item => item.status === 'Pending').length;

// Update CardItems with the calculated counts
const CardItems = [
  { label: "Leave request pending", value: leavePendingCount, textColor: '#E52E2E', bgColor: '#E493931A' },
  { label: "Leave Approved", value: leaveApprovedCount, textColor: '#009244', bgColor: '#0092441A' },
  { label: "Total leave available", value: 0, textColor: '#FB662B', bgColor: '#FFA07A1A' }
];

return (
    <Box sx={{width:"100%"}}>
       <TopBar/>
       <MainBox>
           <TextBox sx={{fontWeight:"500", fontSize:"1.5rem", lineHeight:"2.25", color:"#141414", width: "100%"}}>
             Leave Balance
           </TextBox>
           <BtnPrimary sx={{ padding: '1rem 0.75rem', borderRadius: '4px', alignSelf: 'flex-end', width: '10rem'}} onClick={handleClickOpen}>
             Apply Leave
           </BtnPrimary>
           <Box sx={{display:'flex', justifyContent:'space-between', marginTop: '1rem', marginBottom: '1.5rem', gap:"0.5rem"}}>
                 {
                    CardItems.map((item)=>(
                        <Box key={item.label} sx={{display:'flex', alignItems:'center', gap:'0.75rem', padding: '1.5rem 0.75rem', borderRadius: '8px', backgroundColor: '#FFFFFF', flexBasis:'100%'}}>
                            <TextBox sx={{backgroundColor:item.bgColor, 
                                          color:item.textColor, 
                                          borderRadius:'4px', 
                                          padding:'2px 6px 2px 6px', 
                                          height:'3.37rem', 
                                          width:'3.37rem',
                                          fontWeight:500, 
                                          fontSize:'1.625rem', 
                                          lineHeight:'2.25', 
                                          letterSpacing:'-0.0013125rem',
                                          textAlign:'center'}}>
                                {item.value}
                            </TextBox>
                            <TextBox sx={{fontWeight:500, fontSize:'1.125rem', lineHeight:'1.25', letterSpacing:'-0.011rem'}}>{item.label}</TextBox>
                        </Box> 
                    ))
                 }
           </Box>
           <Box sx={{display:'flex', justifyContent:'space-between', gap:"1.5rem"}}>
                <LeaveData leaveData={leaveData}/>
                <Box sx={{flex:1}}>
                     <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center', marginBottom:'0.75rem'}}>
                            <TextBox sx={{color:"#141414", fontWeight:500,fontSize:'1.125rem', lineHeight:'1.68rem' }}>Holidays</TextBox>
                            <TextBox sx={{fontSize:'0.875rem', lineHeight:'1.31rem', color:"#141414"}}>Months</TextBox>
                     </Box>
                     <Box sx={{borderRadius:"12px", backgroundColor:"#FFFFFF",boxShadow:"0px 2.767256498336792px 20px 0px #0000000D"}}>
                            <Calendar/>
                     </Box>
                </Box>
           </Box>
       </MainBox>
       <ApplyLeave open={open} onClose={handleClose} />
    </Box>
  );
};
