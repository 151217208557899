export const getDuration = (time) =>{
    const hrs = Math.floor(time/60);
    const min = Math.floor(time%60);
    const formatWithTwoDigits = new Intl.NumberFormat('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      });
    return hrs > 0 
    ? `${formatWithTwoDigits.format(hrs)} hrs ${formatWithTwoDigits.format(min)} min` 
    : `${formatWithTwoDigits.format(min)} min`;
}