import React from 'react'
import { Avatar, Box, Tooltip } from '@mui/material'
import styled from '@emotion/styled'
import PencilAlt from "../../../../../../assets/icons/pencil-alt.png";
import { useNavigate } from 'react-router';

const LabelBox = styled(Box)(() => ({
    color:"#4B5563", 
    fontSize:"1rem",
    lineHeight:"1.5rem",
    flex:1
}));

const ValueBox = styled(Box)(() => ({
    fontSize:"1rem",
    lineHeight:"1.5rem",
    flex:1
}));

const DisplayCard = ({mainHeading,data}) => {

  const navigate = useNavigate();
  const handleClick = () =>{
    navigate(`form`,{state:{data, mainHeading}});
  }

  return (
    <Box>
        <Box sx={{display:"flex", gap:"0.875rem", alignItems:"center", marginBottom:"2.25rem"}}>
                  <Box sx={{fontWeight:"500",fontSize:"1.375rem",lineHeight:"1.5rem", color:"#141414"}}>
                      {mainHeading}
                  </Box>
                  <Tooltip title={`${mainHeading} Form`}>
                     <Avatar src={PencilAlt} alt="Edit" 
                              sx={{height:"1.5rem", width:"1.5rem", '&:hover': {cursor: "pointer"}}} 
                              onClick={handleClick}
                     />
                  </Tooltip>
        </Box>
        <Box sx={{marginTop:"1.5rem", display:"flex", flexDirection:"column", gap:"1rem"}}>
            {
                data.map((item)=>(
                    <Box sx={{display:"flex", justifyContent:"space-between"}}>
                        <LabelBox>{item.label}</LabelBox>
                        <ValueBox>{item.value}</ValueBox>
                    </Box>
                ))
            }

        </Box>
    </Box>
  )
}

export default DisplayCard