import React from "react";
import {Box} from "@mui/material";
import Topbar from "../../../../../component/TopBar";
import styled from "@emotion/styled";
import BriefcaseIcon from "../../../../../assets/icons/briefcase.svg";
import AttendanceIcon from "../../../../../assets/icons/calendar-check.svg";
import EmployeeIcon from "../../../../../assets/icons/people.svg";
import ProjectIcon from "../../../../../assets/icons/paper.svg";
import CalenderIcon from "../../../../../assets/icons/calendar-dot.svg";
import OverviewTable from "./Overview";
import Card from "../../../container/Card";
import {AvatarWrapper, AvatarImage} from "../../../container/Avatar";
import Up from "../../../../../assets/icons/up.png";
import Down from "../../../../../assets/icons/down.png";

const OuterContainer = styled(Box)({width: "100%"});

const TextBox = styled(Box)({
  display: "block",
  whitespace:"nowrap",
});

const cardItems = [
  { icon: EmployeeIcon, stats: "10", label: "Total Employee", value: "10",change:"12%",update:"July 16, 2023" },
  { icon: BriefcaseIcon, stats: "10", label: "Total Applicant", value: "10",change:"12%",update:"July 16, 2023" },
  { icon: AttendanceIcon, stats: "10", label: "Today Attendance", value: "10",change:"12%",update:"July 16, 2023" },
  { icon: ProjectIcon, stats: "10", label: "Total Projects", value: "10",change:"12%",update:"July 16, 2023" },
]

export default (props) => {

  const handleDateRangeChange = (range) => {
        // selected range
        console.log('Selected Range:', range);
  };

  return (
    <Box sx={{width:"100%"}}>
      <Topbar/>
      <OuterContainer>
      <Box sx={{width:"100%", display:"flex", justifyContent:"space-between", marginBottom:"1.5rem"}}>
        <Box sx={{width:"calc(60% - 0.48rem)"}}>
           <Box sx={{width:"100%", display:"flex", justifyContent:"space-between", flexWrap:"wrap"}}>
              {cardItems.map((item, index) => (
                  <Card key={index} sx={{ width: "calc(50% - 0.48rem)",  padding: "1rem 1rem 0.625rem 1rem", marginBottom: "1.5rem",position: 'relative'}}>
                       <Box sx={{width:"100%", display:"flex", gap:"1rem", alignContent:"center"}}>
                           <AvatarWrapper sx={{backgroundColor:"#7152F31A", border:"none"}}>
                             <AvatarImage src={item.icon} />
                           </AvatarWrapper>
                           <TextBox fontSize={"0.875rem"} fontWeight={300} lineHeight={"1.375rem"} color={"#16151C"}>
                             {item.label}
                           </TextBox>
                        </Box>  
                        <Box sx={{width:"100%", display:"flex", justifyContent:"space-between", gap:"1rem", marginTop:"1rem", alignItems:"center"}}>
                              <TextBox fontSize={"1.875rem"} fontWeight={600} lineHeight={"2.5rem"} color={"#16151C"}>
                                {item.value}
                              </TextBox>
                              <TextBox 
                                fontSize={"0.68rem"} 
                                fontWeight={300} 
                                lineHeight={"1rem"} 
                                color={item.change?"#30BE82":"#F45B69"} 
                                backgroundColor={item.change?"#30BE821A":"#F45B691A"}
                                padding="0.31rem" 
                                borderRadius="5px"
                               >
                                <img src={item.change?Up:Down}/>
                                {item.change}
                              </TextBox>
                        </Box> 
                        <Box sx={{ width: '100%', borderTop: '1px solid #A2A1A833', position: 'absolute', bottom: '3rem', left:"0.5rem" }} /> 
                        <TextBox fontSize={"0.75rem"} fontWeight={300} lineHeight={"1.12rem"} color={"#A2A1A8"}  marginTop="1.25rem">
                          Update: {item.update}
                        </TextBox>
                  </Card>

              ))}
           </Box>
           <Card sx={{width:"100%"}}>

           </Card>
        </Box> 
        <Card sx={{width:"calc(40% - 0.75rem)", padding: "1.25rem"}}>
           <Box sx={{display:"flex", justifyContent:"space-between"}}>
                    <TextBox fontSize="1.25rem" fontWeight={600} lineHeight="1.875rem" color="#16151C">My Schedule</TextBox>
                    <AvatarWrapper sx={{backgroundColor:"#7152F31A", border:"none"}}>
                       <AvatarImage src={CalenderIcon}/>
                    </AvatarWrapper>
           </Box>
           {/* <DateRangeCalender/> */}
        </Card>       
      </Box>

      </OuterContainer>
      <OuterContainer sx={{ border:"1px solid #A2A1A833", borderRadius:"0.4rem", padding:"1.25rem"}}>
          <OverviewTable/>
      </OuterContainer>
    </Box>
  );
};
