import React from 'react';
import { Box, TextField, MenuItem,Checkbox, InputAdornment } from '@mui/material';
import styled from '@emotion/styled';
import ErrorMsg from './ErrorMsg';

const TextBox = styled(Box)({
  fontSize: '1rem',
  lineHeight: '1.5rem',
  color: '#818181',
  marginBottom: '4px'
});

const InputStyle = {
  borderRadius: '10px',
  fontSize: '1rem',
  lineHeight: '1.5rem',
  fontWeight:"300",
  color: '#16151CCC',
}

const FormField = ({ label,placeholder, type, value, width, height, disabled, options,disabledOptions, onChange, fullwidth, validation, row, endAdornment}) => {

  return (
    <Box sx={{width: width?width:(fullwidth?'100%':'48%'), marginBottom: '1.125rem' }}>
      <TextBox>{label}</TextBox>
      {type === 'select' ? (
        <TextField
          id="outlined-basic"
          select
          variant="outlined"
          onChange={onChange}
          fullWidth
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          sx={{
            '& .MuiInputBase-root': {...InputStyle, height: height? height : (row? `${3 * row}rem`:"3rem"), paddingInline: row? `1rem`:"none"},
            "& .MuiOutlinedInput-notchedOutline":{
                border: '1px solid #A2A1A833'
            }
          }}
        >
          {options &&
            options.map((option) => (
              typeof option === 'object' ? (
                <MenuItem key={option._id} value={option.value} disabled={disabledOptions?.includes(option.value)}>
                  {option.name}
                </MenuItem>
              ) : (
                <MenuItem key={option} value={option} disabled={disabledOptions?.includes(option)}>
                  {option}
                </MenuItem>
              )
            ))}
        </TextField>
      )  :  type === 'file' ? (
      <input
        type="file"
        onChange={onChange}
        style={{
          ...InputStyle,
          display: 'block',
          border: '1px solid #A2A1A833',
          padding: '8px',
          width: '100%',
          boxSizing: 'border-box',
        }}
      />
    ) :  (
        <TextField
          id="outlined-basic"
          variant="outlined"
          onChange={onChange}
          fullWidth
          value={value}
          multiline={fullwidth}
          placeholder={placeholder}
          disabled={disabled}
          rows={row}
          sx={{
            '& .MuiInputBase-root': {...InputStyle, height: height? height : (row? `${3 * row}rem`:"3rem")},
              "& .MuiOutlinedInput-notchedOutline":{
                  border: '1px solid #A2A1A833'
              }
          }}
          InputProps={{
            endAdornment: endAdornment?(
              <InputAdornment position="end">
                 {endAdornment}
              </InputAdornment>
            ) : null     
          }}
        />
      )}
      <ErrorMsg>{validation}</ErrorMsg>
    </Box>
  );
};

export default FormField;
