import React, { useState } from 'react'
import styled from "@emotion/styled";
import { Box, FormControlLabel,Radio, RadioGroup } from '@mui/material';
import FormField from '../../../../../../../../component/Formfield';
import CheckboxField from '../../../../../../../../component/CheckboxField';
import BtnPrimary from "../../../../../../../../component/PrimaryButton";
import SwitchToggle from '../../../../../../../../component/SwitchToggle';
import MultiSectionClock from '../../../../../../../../component/MultiSectionClock';


const TextBox = styled(Box)({
   fontSize:"1rem",
   lineHeight:"1.5rem"
});

const BreakTimeForm = ({loadNextPage, breakTimeForm, setBreakTimeForm}) => {

  const [isActive, setIsActive] = useState(true);
  const handleChange = (field, value) => {
    setBreakTimeForm(prevState => ({...prevState, [field]: value}));
  };

  return (
    <Box>
       <Box sx={{width:"100%", display:"flex", flexWrap:"wrap", justifyContent: "space-between", alignItems:"center"}}>
            <Box sx={{width:"100%", display:"flex", justifyContent: "space-between", marginBlock:"1.375rem"}}>
              <Box sx={{display:"flex", gap:"0.2rem"}}>
               <TextBox>Break time</TextBox>
               <TextBox sx={{color:"#818181"}}>Penalize employee if they exceed allowed number / hours of break time.</TextBox>
              </Box>
              <SwitchToggle 
                  checked={isActive} 
                  onChange={(e)=>setIsActive(e.target.checked)} 
              />
            </Box>
            <FormField
                label="Type of Break"
                type="select"
                options={["Lunch Break", "Rest Break"]}
                value={breakTimeForm.type}
                onChange={(e) => {handleChange('type', e.target.value)}}
            />
            <Box sx={{width:"100%", display:"flex",justifyContent:"space-between"}}>
                  <FormField
                      width="11.625rem"
                      label="Break Time Window"
                      type="select"
                      options={[]}
                      value={breakTimeForm.breakTimeWindow}
                      onChange={(e) => {handleChange('breakTimeWindow', e.target.value)}}
                  />
                  <Box>
                      <Box sx={{fontSize: '1rem',lineHeight: '1.5rem',color: '#818181',marginBottom: '4px'}}>
                          Maximum duration of break
                      </Box>
                      <MultiSectionClock/>
                  </Box>
                  <Box>
                      <Box sx={{fontSize: '1rem',lineHeight: '1.5rem',color: '#818181',marginBottom: '4px'}}>
                        Frequency of Break
                      </Box>
                      <Box sx={{display:"flex",gap:"1rem", color:"#16151C80", alignItems:"center"}}>
                          <FormField
                              width="2.25rem"
                              type="text"
                              value={breakTimeForm.breakFrequency}
                              onChange={(e) => {handleChange('breakFrequency', e.target.value)}}
                          />
                          times in a
                          <FormField
                              width="11.625rem"
                              type="select"
                              options={["Day","Week"]}
                              value={breakTimeForm.breakFrequencyType}
                              onChange={(e) => {handleChange('breakFrequencyType', e.target.value)}}
                          />
                      </Box>
                  </Box>
            </Box>
            <Box sx={{width:"100%", display:"flex", gap:"0.2rem", marginBlock:"1.375rem"}}>
               <TextBox>Grace Period-</TextBox>
               <TextBox sx={{color:"#818181"}}>
                  Period beyond which break time will be considered as extended break time.
               </TextBox>
            </Box>
            <Box sx={{width:"100%", borderRadius:"10px", padding:"1.25rem", backgroundColor:"#FAFAFB"}}>
                   <Box>
                      <TextBox>Set Limit for Grace Period</TextBox>
                      <Box sx={{width:"100%",display:"flex", alignItems:"center", gap:"0.75rem", color:"#16151C80"}}>
                            <FormField
                                  width="5rem"
                                  height="3.5rem"
                                  type="text"
                                  value={breakTimeForm.graceLimit}
                                  onChange={(e) => {handleChange('graceLimit', e.target.value)}}
                            />
                            /
                            <FormField
                                  width="11.625rem"
                                  height="3.5rem"
                                  type="select"
                                  options={["Minutes", "Hours"]}
                                  value={breakTimeForm.graceLimitFrequency}
                                  onChange={(e) => {handleChange('graceLimitFrequency', e.target.value)}}
                            /> 
                    </Box> 
                   </Box>
                   <Box>
                      <TextBox>During Buffer period, (2 times in a week)</TextBox>
                      <Box sx={{width:"100%",display:"flex", alignItems:"center", gap:"0.75rem", color:"#16151C80"}}>
                            <CheckboxField
                                label="Notify employee on their extended break time."
                                checked={breakTimeForm.notifyEmployee}
                                onChange={(isChecked) => handleChange('notifyEmployee',isChecked)}
                                labelStyle={{fontSize: "1rem", lineHeight: "1.5rem", color:"#818181"}}
                            />
                             <CheckboxField
                                label="Notify reporting manager about their extended break time."
                                checked={breakTimeForm.notifyReportingManager}
                                onChange={(isChecked) => handleChange('notifyReportingManager',isChecked)}
                                labelStyle={{fontSize: "1rem", lineHeight: "1.5rem", color:"#818181"}}
                            />
                       </Box>
                   </Box>
            </Box>
            <Box sx={{marginBlock:"1.375rem"}}>
               <TextBox>Penalty in case of breach</TextBox>
               <CheckboxField
                    label="Employees are penalized if the exceed allowed number of extended break in a  week/ month"
                    checked={breakTimeForm.isPenalised}
                    onChange={(isChecked) => handleChange('isPenalised',isChecked)}
                    labelStyle={{fontSize: "1rem", lineHeight: "1.5rem", color:"#818181"}}
                />
            </Box>
            {
               breakTimeForm?.isPenalised?(
                <Box sx={{width:"100%", borderRadius:"10px", padding:"1.25rem", backgroundColor:"#FAFAFB"}}>
                  <Box sx={{marginBlock:"1.5rem"}}>
                    <TextBox sx={{marginBottom:"0.75rem"}}>How are Penalties charged?</TextBox>
                    <Box sx={{display:"flex", justifyContent:"space-between"}}>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={breakTimeForm?.penaltyType}
                          onChange={(e)=> handleChange('penaltyType',e.target.value)}
                          style={{ width:"100%", flexDirection: 'row', justifyContent:"space-between"}}
                        >
                          <FormControlLabel 
                            value="Deduct Effective Hours" 
                            control={<Radio />} 
                            label={
                              <Box>
                                Deducted from total effective hours of the day
                                <Box sx={{fontSize:"0.875rem", lineHeight:"1.31rem", color:"#818181"}}>
                                Consider all penalties as deduction from effective hour
                                </Box>
                              </Box>
                            } 
                          />
                          <FormControlLabel 
                            value="Paid Leave" 
                            control={<Radio />} 
                            label={
                              <Box>
                                Paid Leave
                                <Box sx={{fontSize:"0.875rem", lineHeight:"1.31rem", color:"#818181"}}>  
                                  Penalties are deducted from paid leave followed by unpaid leave
                                </Box>
                              </Box>
                            } 
                          />
                        </RadioGroup>
                    </Box>
                  </Box>
                  {
               breakTimeForm?.isPenalised?(
                <Box sx={{width:"100%", padding:"1.25rem", backgroundColor:"#FAFAFB"}}>
                  <Box sx={{marginBlock:"1.5rem"}}>
                    <TextBox sx={{marginBottom:"0.75rem"}}>Post 2 extended break in a week , how are employee penalized?</TextBox>
                      {breakTimeForm?.penaltyType==="Deduct Effective Hours"?
                         <Box sx={{width:"100%",display:"flex", alignItems:"center", gap:"0.2rem", color:"#16151C80"}}>
                                <FormField
                                      width="5rem"
                                      height="2rem"
                                      type="text"
                                      value={breakTimeForm.effectiveHoursDeduction}
                                      onChange={(e) => {handleChange('effectiveHoursDeduction', e.target.value)}}
                                /> 
                                <FormField
                                      width="5rem"
                                      height="2rem"
                                      type="select"
                                      options={["Minutes","Hours"]}
                                      value={breakTimeForm.effectiveHoursDeductionFrequency}
                                      onChange={(e) => {handleChange('effectiveHoursDeductionFrequency', e.target.value)}}
                                /> 
                                  Of daily effective hours will be deducted for 
                                  <FormField
                                      width="5rem"
                                      height="2rem"
                                      type="text"
                                      value={breakTimeForm.numberOfEXtendedBreakForDeduction}
                                      onChange={(e) => {handleChange('numberOfEXtendedBreakForDeduction', e.target.value)}}
                                /> 
                                 extended break.
                              </Box> :
                              <Box sx={{width:"100%",display:"flex", alignItems:"center", gap:"0.2rem", color:"#16151C80"}}>
                                  <FormField
                                        width="5rem"
                                        height="2rem"
                                        type="text"
                                        value={breakTimeForm.numberOfDaysPaidLeaveDeduction}
                                        onChange={(e) => {handleChange('numberOfDaysPaidLeaveDeduction', e.target.value)}}
                                  /> 
                                  day(s) Paid Leave.
                              </Box> 
                            }
                        </Box>
                  </Box>
                    ):null
                  }
            </Box>
               ):null
            }
       </Box>
       <Box sx={{display:"flex", justifyContent:"end", gap:"1rem", marginTop:"1.875rem"}}>
                <BtnPrimary 
                    onClick={() => loadNextPage("chooseCategory")}
                    sx={{width:"11.875rem", height:"3.125rem", borderRadius:"10px"}}
                >
                    Next
                </BtnPrimary> 
        </Box>
    </Box>
  )
}

export default BreakTimeForm