import React from 'react';
import {Box} from "@mui/material";
import BtnSecondary from "../SecondaryButton";
import FormField from '../Formfield';
import TypeBtn from '../AddRemoveButton';

const EditPolicyCategory = ({type,categoryEdit, policyData, setPolicyData, categoryOptions, setSelectedCategoryIndex, setIsLimitDialogOpen}) => {

  //to get corresponding code of a given category
  const getCode = (id) => (categoryOptions.find((option)=>option.value===id)?.code)

  //to select a category
  const handleCategorySelection = (index, fieldName, value) => {
          let selectedExpenseCategory = [...policyData.policyCateogary];
          selectedExpenseCategory[index][fieldName] = value;
          setPolicyData((prev)=>({...prev,policyCateogary:selectedExpenseCategory}));
  };

  //to remove a category
  const handleRemoveExpenseCategory = (index) => {
    let selectedExpenseCategory = [...policyData.policyCateogary];
    selectedExpenseCategory.splice(index, 1);
    setPolicyData((prev)=>({...prev,policyCateogary:selectedExpenseCategory}));
  };

  //to add new expense fields
  const handleAddExpenseCategory = () =>{
    setPolicyData((prev)=>({...prev,policyCateogary:[...policyData.policyCateogary,{ _id: "", code: "", limits:[]}]}));
  }

  //opening set limit dialog box
  const handleSetLimit = (index) => {
    setSelectedCategoryIndex(index); 
    setIsLimitDialogOpen(true);
  };
    

  return (
    <Box> 
     {
        policyData?.policyCateogary?.map((item,index)=>(
          <Box key={index} sx={{ width: "100%", display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
            <FormField
                label="Choose Category"
                type="select"
                options={categoryOptions}
                value={item?._id}
                onChange={(e) => {
                          handleCategorySelection(index, '_id', e.target.value)
                          handleCategorySelection(index, 'code', getCode(e.target.value) )
                        }}
                disabled={!categoryEdit}
            />
            <FormField
                label="Category Code"
                type="text"
                value={item?.code || getCode(item?._id)}
                disabled={true}
            />
            {type==="TRIP"?
              <FormField
              label="Rate per km"
              type="text"
              value={item.ratePerKm}
              onChange={(e) => handleCategorySelection(index, 'ratePerKm', e.target.value)}
              disabled={!categoryEdit}
            />:null
            }
            {type==="ANY"? (item?.limits?.length > 0?(
              <Box sx={{backgroundColor:"#FAFAFB",borderRadius:"1rem",padding:"1rem", display:"flex", justifyContent:"space-between", marginBottom:"1rem", width:"100%"}}>
                      <Box sx={{fontSize:"1rem",lineHeight:"1.5rem", color: '#818181'}}>
                        {"Expense Limit set to: "}  
                        {item?.limits?.map((limit)=>`${limit?.limit}/${limit?.frequency}`).join(', ')}
                      </Box>
                      <Box sx={{display:categoryEdit?"flex":"none"}}>
                            <BtnSecondary
                                onClick={() => handleSetLimit(index)}
                                sx={{width:"8.93rem", height:"1.93rem", borderRadius:"10px"}}
                              >
                                  Edit Limit
                            </BtnSecondary>
                      </Box>
              </Box>
            ):(
              <Box sx={{width:"100%", display:categoryEdit?"flex":"none",justifyContent:"end", marginBottom:"1rem"}}>
                    <BtnSecondary
                      onClick={() => handleSetLimit(index)}
                      sx={{width:"8.93rem", height:"1.93rem", borderRadius:"10px"}}
                    >
                        Set Limit
                    </BtnSecondary>
              </Box>
            )):null} 
            <Box sx={{width:"100%", display:categoryEdit?"flex":"none", justifyContent:"end"}}>
              <TypeBtn type="remove" onClick={()=>handleRemoveExpenseCategory(index)} sx={{width: '1.125rem', height: '1.125rem'}} >
                 Remove Category
              </TypeBtn>
            </Box>
          </Box>
        ))
      }
      <Box sx={{display:categoryEdit?"flex":"none"}}>
          <TypeBtn type="add" onClick={handleAddExpenseCategory}>
              Add New Category
          </TypeBtn>  
      </Box>   
    </Box>
  )
}

export default EditPolicyCategory