import React,{useState} from 'react'
import { Box } from '@mui/material';
import DropdownButton from "../../../../../../../../component/DropdownButton";
import BtnSecondary from "../../../../../../../../component/SecondaryButton";
import { PolicyTableHeadings} from '../../../../../../../../styles/PolicyTableHeadings';
import { PolicyTableCell} from '../../../../../../../../styles/PolicyTableCell';
import PaginationTable from "../../../../../../../../component/PaginationTable";

const RequestsListing = () => {
    const [loading, setLoading] = useState(false);
    const [shift, setShift] = useState("Morning Shift");
    const shiftOptions = ["Morning Shift","Evening Shift"];
    const [data, setData] = useState([]);

    const headings = [
        { label: "Date", style: {...PolicyTableHeadings, width:"8.25rem", borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px" } },
        { label: "Employee Name", style: {...PolicyTableHeadings,  width:"13.75rem"} },
        { label: "Employee ID", style: {...PolicyTableHeadings, width:"10rem"} },
        { label: "Request Type", style: { ...PolicyTableHeadings,width:"6.06rem", borderTopRightRadius: "12px", borderBottomRightRadius: "12px" } }
    ];

  return (
    <Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom:"1.5rem", alignItems:"end" }}>
                <Box sx={{fontWeight:"500", fontSize:"1.5rem", lineHeight:"2.25rem"}}>
                   Requests
                </Box>
                <Box sx={{display:"flex", gap:"1rem"}}>
                  <DropdownButton value={shift} options={shiftOptions} onChange={(e)=>setShift(e.target.value)}/>
                  <BtnSecondary sx={{ width: '5.56rem', height: '2rem', borderRadius: '8px' }} to="/app/attendance-dashboard">
                      Back
                  </BtnSecondary>
                </Box>
            </Box>
             <PaginationTable
                rows={data}
                headings={headings}
                loading={loading}
                link={true}
                path="/app/attendance-details"
                noRecordMessage={"No Record Found"}
                renderCell={(row) => (
                  <>
                    <PolicyTableCell>{row?.date || "------"}</PolicyTableCell>
                    <PolicyTableCell >{row?.name || "------"}</PolicyTableCell>
                    <PolicyTableCell>{row?.id || "------"}</PolicyTableCell>
                    <PolicyTableCell>{row?.date || "------"}</PolicyTableCell>
                    <PolicyTableCell>{row?.date || "------"}</PolicyTableCell>
                    <PolicyTableCell>{}</PolicyTableCell>
                    <PolicyTableCell>{row?.updatedAt}</PolicyTableCell>
                  </>
                )}
              />
    </Box>
  )
}

export default RequestsListing