import React, { useState } from "react";
import {Box} from "@mui/material";
import { AvatarImage, AvatarWrapper } from "../app/main/container/Avatar";
import Card from "../app/main/container/Card";

function CardItem({icon, Heading, subHeading, children}) {
    return(
        <Card sx={{padding:"1.5rem", flex:1}}>
            <Box sx={{ width: "100%", display: "flex", alignItems:"center", gap: "0.5rem" }}>
                <AvatarWrapper>
                    <AvatarImage src={icon} style={{ width: '1.5rem', height: '1.5rem' }}/>
                </AvatarWrapper>
                <Box>
                    <Box fontSize={"0.875rem"} lineHeight={"1.375rem"} color={"#16151C"}>
                        {Heading}
                    </Box>
                    <Box fontSize={"0.75rem"} lineHeight={"1.125rem"} color={"#6B7280"}>
                        {subHeading}
                    </Box>
                </Box>
            </Box>
            <Box sx={{marginTop:"1.81rem"}}>
               {children}
            </Box>
        </Card>
    )
}

export default CardItem;