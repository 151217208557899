import React, {useState} from 'react'
import { Box, CircularProgress } from '@mui/material';
import { useLocation, useNavigate } from 'react-router';
import FormField from '../../../../../../../../component/Formfield';
import BtnSecondary from '../../../../../../../../component/SecondaryButton';
import BtnPrimary from '../../../../../../../../component/PrimaryButton';
import TypeBtn from '../../../../../../../../component/AddRemoveButton';
import ErrorMsg from '../../../../../../../../component/ErrorMsg';
import { updateAttendancePolicy } from '../../../../../../../../services/attendancePolicy';

const ChooseCategory = ({
  loadNextPage, 
  attendanceCategories, 
  setAttendanceCategories, 
  absenteeismForm,
  tardinessForm,
  setAbsenteeismForm,
  setTardinessForm,
  absenteeismFormDefaultState,
  tardinessFormDefaultState,
}) => {

    const [apiCall,setApiCall]=useState(false);
    const navigate = useNavigate();
    const {state} = useLocation();
    const [policyId, setPolicyId] = useState(state?.policyId);
    const [error,setError]=useState("");

    console.log(policyId, absenteeismForm, tardinessForm);
  
  
    const handleSetConfiguration = (index) => {
      if(attendanceCategories[index]==="Tardiness")
         loadNextPage("tardinessForm");
      else if(attendanceCategories[index]==="Absenteeism")
         loadNextPage("absenteeismForm");
      else
         loadNextPage("breakTimeForm");
    };
  
      const handleAddAttendanceCategory = () =>{
        setAttendanceCategories([...attendanceCategories,""])
      }
  
      const handleRemoveAttendanceCategory = (index) => {
        let selectedAttendanceCategory = [...attendanceCategories];
        if(selectedAttendanceCategory[index]==="Tardiness")
           setTardinessForm(tardinessFormDefaultState);
        else if(selectedAttendanceCategory[index]==="Absenteeism")
           setAbsenteeismForm(absenteeismFormDefaultState);
        selectedAttendanceCategory.splice(index, 1);
        setAttendanceCategories(selectedAttendanceCategory);
      };
  
      const handleCategorySelection = (index,value) => {
        let selectedAttendanceCategory = [...attendanceCategories];
        selectedAttendanceCategory[index] = value;
        setAttendanceCategories(selectedAttendanceCategory);
      };
  
      const handleNextClick = async() => {
       setApiCall(true);
       try {
            const res = await updateAttendancePolicy(policyId,{
              ...(attendanceCategories.includes("Absenteeism") && absenteeismForm),
              ...(attendanceCategories.includes("Tardiness") && tardinessForm),
              enableAbsenteeism: attendanceCategories.includes("Absenteeism"),
              enableTardiness: attendanceCategories.includes("Tardiness")
            })
            console.log(res);
            if(res.success){
              navigate("/app/attendance-policy/new/assign-policy",{state:{policyId}});
            }else{
              console.error('Policy updation failed:', res.error);
              setError(res.message);
            }
        } catch (error) {
            console.error('Error saving Policy:', error);
        }
        setApiCall(false);
      };
  
    console.log(attendanceCategories);

  return (
    <Box>
          <Box> 
            {
              attendanceCategories?.map((item,index)=>(
                <Box key={index}>
                  <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems:"center" }}>
                    <Box sx={{ width: "90%", display: "flex", justifyContent: "space-between", alignItems:"center" }}>
                        <FormField
                            label="Choose Category"
                            type="select"
                            options={["Absenteeism", "Tardiness"]}
                            disabledOptions={attendanceCategories}
                            value={item}
                            onChange={(e) => {handleCategorySelection(index, e.target.value)}}
                        />
                    </Box>
                    <TypeBtn type="remove" onClick={()=>handleRemoveAttendanceCategory(index)} sx={{width: '1.125rem', height: '1.125rem'}}>
                    </TypeBtn>
                  </Box>
                  {
                    <Box sx={{display:"flex",justifyContent:"end"}}>
                          <BtnSecondary
                            onClick={() => handleSetConfiguration(index)}
                            sx={{width:"11.25rem", height:"1.93rem", borderRadius:"10px"}}
                            disabled={item.code===""}
                          >
                              Edit Configuration
                          </BtnSecondary>
                    </Box>
                  }             
                </Box>
              ))
            }
            <ErrorMsg>{error}</ErrorMsg>
            {attendanceCategories.length<2?(
                <TypeBtn type="add" onClick={handleAddAttendanceCategory}>
                    Add Category
                </TypeBtn>
            ):null}
          </Box>
          
          <Box sx={{display:"flex", justifyContent:"end", gap:"1rem", marginTop:"1.875rem"}}>
              <BtnPrimary 
                onClick={handleNextClick} 
                sx={{width:"10rem", height:"3.125rem", borderRadius:"10px"}}
                disabled={apiCall}
              >
                  {apiCall?<CircularProgress/>:"Next"}
              </BtnPrimary>
          </Box>
    </Box>
  )
}

export default ChooseCategory