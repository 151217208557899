import React from 'react';
import { Dialog,Box, Avatar} from '@mui/material';
import { styled } from '@mui/material/styles'; 
import BtnPrimary from "./PrimaryButton";
import GreenTick from "../assets/images/green-checkmark-filled-bordered.png";
import { useNavigate } from 'react-router';
import BtnSecondary from './SecondaryButton';

const TextBox = styled(Box)({
    width:"100%"
});

function SuccessDialog({open, onClose, message, addNewLink, addNewText, viewAllLink, viewAllText}) {
  
  const navigate = useNavigate();

  const handleAddNew = () => {
       onClose();
       navigate(addNewLink);
  }

  const handleViewAll = () => {
       onClose();
       if (typeof viewAllLink === 'function') {
            viewAllLink();
        } else {
            navigate(viewAllLink);
        }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width:"36.625rem",
          borderRadius: "8px",
          boxShadow: "0px 4px 10px 0px #0000001A",
          padding: "2.25rem 1.5rem",
          display:"flex",
          flexDirection:"column",
          alignItems:"center",
        }
      }}
    >
        
            <Avatar src={GreenTick} alt="icon" sx={{height:"7rem", width:"7rem"}}/>
            <TextBox sx={{fontSize: "1.25rem", lineHeight: "1.8rem", color: "#6B6C7E", marginTop:"1.5rem", textAlign:"center"}}>
              {message}
            </TextBox>
            <Box sx={{
                width:"100%", 
                display:"flex",
                justifyContent:viewAllText && addNewText?"space-between":"center", 
                marginTop:"2.25rem"
            }}>
                {viewAllText && (
                  <BtnSecondary 
                    onClick={handleViewAll}
                    sx={{ width: "15.56rem", height: '3.5rem', padding: '1rem', borderRadius: '4px', backgroundColor: "#E5F2FF" }}
                  >
                    {viewAllText}
                  </BtnSecondary>
                )}
                {addNewText && (
                  <BtnPrimary 
                    onClick={handleAddNew}
                    sx={{ width: "15.56rem", height: '3.5rem', padding: '1rem', borderRadius: '4px' }}
                  >
                    {addNewText}
                  </BtnPrimary>
                )}
            </Box>
    </Dialog>
  );
}

export default SuccessDialog;

