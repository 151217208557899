import React, { useState} from 'react';
import { Dialog,Box, Avatar,} from '@mui/material';
import BtnPrimary from "../../../../../../component/PrimaryButton";
import BtnSecondary from "../../../../../../component/SecondaryButton";
import FormField from '../../../../../../component/Formfield';
import ErrorMsg from '../../../../../../component/ErrorMsg';
import Cross from '../../../../../../assets/icons/cross.png';
import CheckboxField from '../../../../../../component/CheckboxField';

function ApprovalDialog({open, onClose, type}) {

  const [errorMsg,setErrorMsg] = useState("");
  const [isComment, setIsComment] = useState(false);

  const handleSave = () => {
      onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose(null)}
      PaperProps={{
        sx: {
          width:"80%",
          borderRadius: "12px",
          boxShadow: "0px 4px 10px 0px #0000001A",
          padding: "3.43rem 1.25rem",
        }
      }}
    >
        <Box sx={{display:"flex", justifyContent:"space-between"}}>
            <Box sx={{display: "block",
                      fontWeight:"500",
                      fontSize:"1.25rem",
                      lineHeight:"1.875rem",
                      color:"#0081FF",
                      marginBottom:"1.5rem"
                    }}>
              {type==="LOP"?"Loss Of Pay":"Deduct Leave"}
            </Box>
            <Avatar src={Cross} sx={{height:"1.2rem", width:"1.2rem", '&:hover': {cursor: "pointer"}}} onClick={() => onClose(null)}/>
        </Box>
        <Box sx={{display:"flex", justifyContent:"space-between", flexWrap:"wrap"}}>
          <FormField
                label={type==="LOP"?"Total Penalty":"Leave Type"}
                type="text"
                value={""}
                disabled={true}
          />
          <FormField
                label={type==="LOP"?"LOP per Penalty":"Deduct Leave"}
                type="text"
                value={""}
                disabled={true}
          />
         <FormField
                label={type==="LOP"?"LOP for Total Days":"Deduct Leave"}
                type="text"
                value={""}
                disabled={true}
          />
        </Box>
        <CheckboxField
            label="Update in the pay slip comment"
            checked={isComment}
            onChange={(isChecked) => setIsComment(isChecked)}
            labelStyle={{fontSize: "1rem", lineHeight: "1.5rem", fontWeight:"300", letterSpacing:"-2.5%"}}
        />
        <ErrorMsg>
          {errorMsg}
        </ErrorMsg>
        <Box style={{display:"flex",justifyContent:"center", gap:"2.25rem", width:"100%", marginTop:"1.5rem"}}>
           <BtnSecondary onClick={handleSave} sx={{width:"15.5625rem", height: '3.5rem', padding: '1rem', borderRadius: '4px', backgroundColor:"#E5F2FF" }}>
                Cancel
           </BtnSecondary>
           <BtnPrimary onClick={handleSave} sx={{width:"15.5625rem", height: '3.5rem', padding: '1rem', borderRadius: '4px' }}>
                Confirm
           </BtnPrimary>
        </Box>
    </Dialog>
  );
}

export default ApprovalDialog;

