import React from 'react';
import { Box, TextField, Autocomplete} from '@mui/material';
import styled from '@emotion/styled';
import ErrorMsg from '../component/ErrorMsg';

const TextBox = styled(Box)({
    display: 'block',
    fontSize: '1rem',
    lineHeight: '1.5rem',
    color: '#818181',
  });
  
  const InputStyle = {
    height: '3rem',
    borderRadius: '10px',
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontWeight:"300",
    color: '#1E1E1E',
  }

const AutocompleteField = ({label, value, disabled, options,disabledOptions, onChange, fullwidth, validation, required}) => {
  return (
    <Box sx={{width: fullwidth?'100%':'48%', marginBottom: '1.125rem' }}>
      <TextBox>{label}</TextBox>
      <Autocomplete
            options={options}
            value={value}
            onChange={onChange}
            disabled={disabled}
            sx={{
            '& .MuiInputBase-root': InputStyle,
                "& .MuiOutlinedInput-notchedOutline":{
                border: "1px solid #E7E7ED",
                }
            }}
            renderInput={(params)=><TextField {...params} required={required}/>}
        />
       <ErrorMsg>{validation}</ErrorMsg>
    </Box>
  )
}

export default AutocompleteField