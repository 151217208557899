import React from 'react'
import styled from "@emotion/styled";
import { Box } from '@mui/material';
import BtnPrimary from '../component/PrimaryButton';

const Page = styled(Box)({
    height:"100vh",
    padding: "1.875rem 1.25rem", 
    display:"flex",
    flexDirection:"column",  
    alignItems:"center",
    justifyContent:"center"
});

const TextBox = styled(Box)({
    fontSize: "1.5rem",
    color: "#16151C",
});

const ErrorPage = () => {
  return (
    <Page>
        <TextBox>The page you are looking for does not exists!</TextBox>
        <BtnPrimary sx={{marginTop:"1rem"}} to="/app">Back to dashboard</BtnPrimary>
    </Page>
  )
}

export default ErrorPage