import React,{useState, useEffect} from 'react';
import {Box} from "@mui/material";
import PaginationTable from '../../../../../../component/PaginationTable';
import { getLegalUnitList } from "../../../../../../services/globalData";
import { getEmployeeNames } from '../../../../../../utils/getEmployeeNames';
import { useNavigate } from "react-router-dom";
import { getAllExpensePolicy } from '../../../../../../services/expense';
import { formatDate, formatDateByDay } from '../../../../../../utils/formatDate';
import { PolicyTableHeadings} from '../../../../../../styles/PolicyTableHeadings';
import { PolicyTableCell} from '../../../../../../styles/PolicyTableCell';
import PolicyListingHeader from '../../../../../../component/PolicyListingHeader';

const ExpensePolicyListing = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        const fetchData = async () => {
          try {
            const legalUnit = getLegalUnitList();
            const res = await getAllExpensePolicy(legalUnit[0]._id); 
            const employeeIds = res.data.map(data => data.createdBy);
            const combinedData = await getEmployeeNames(res.data, employeeIds, "createdBy");
            setData(combinedData.reverse());
            setLoading(false);
            console.log(combinedData);
          } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
          }
        };
      
        fetchData();
      }, []);
  
    const headings = [
      { label: "Policy Code", style: {...PolicyTableHeadings, width:"7.68rem", borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px" } },
      { label: "Policy Name", style: {...PolicyTableHeadings,  width:"12.56rem"} },
      { label: "Policy Validity", style: {...PolicyTableHeadings, width:"12.56rem"} },
      { label: "Limit", style: {...PolicyTableHeadings, width:"12.56rem"} },
      { label: "Created By", style: {...PolicyTableHeadings, width:"12.56rem"} },
      { label: "Last Modified", style: { ...PolicyTableHeadings,width:"12.56rem", borderTopRightRadius: "12px", borderBottomRightRadius: "12px" } }
    ];

    const handleMenuItemClick = (id) => { 
      navigate("/app/expense-policy/edit",{state:{policyId:id}});
    };

    const formatLimits = (categories) =>{
      const limitsString = categories?.map(category => category?.limits?.map(limit => `Max ${limit?.limit} ${limit?.frequency}`).join(', ')).join(', ');
      return limitsString || 'No Limit Set';
    }

  return (
    <Box>
        <PolicyListingHeader 
          Heading = "Expense Policies"
          ButtonText = "+ Create Expense Policy"
          ButtonLink = "/app/expense-policy/new/setting"
        />
        <PaginationTable
          rows={data}
          headings={headings}
          loading={loading}
          noRecordMessage={"No Record Found"}
          renderCell={(row) => (
            <>
              <PolicyTableCell>{row?.code}</PolicyTableCell>
              <PolicyTableCell onClick={() => handleMenuItemClick(row._id)}>
                <Box sx={{width:"100%", ":hover":{cursor:"pointer"}, color:"#008cc4",textDecoration:"underline"}}>
                      {row.name}
                </Box>
              </PolicyTableCell>
              <PolicyTableCell>{formatDate(row.startDate)}-{formatDate(row.endDate)}</PolicyTableCell>
              <PolicyTableCell>
                {formatLimits(row?.policyCateogary)}
              </PolicyTableCell>
              <PolicyTableCell>
                {row?.employee?.fname + " " +row?.employee?.lname}
              </PolicyTableCell>
              <PolicyTableCell>{formatDateByDay(row.updatedAt)}</PolicyTableCell>
            </>
          )}
        />
    </Box>
  )
}

export default ExpensePolicyListing