import React from 'react';
import styled from "@emotion/styled";
import { Box } from '@mui/material';

const ErrorText = styled(Box)({
    display: 'block',
    fontSize: '1rem',
    lineHeight: '1.5rem',
    color: '#f2545b',
});

const ErrorMsg = ({ children }) => {
  return (
    <ErrorText>
      {children}
    </ErrorText>
  );
};

export default ErrorMsg;
