import React from 'react';
import {Box} from '@mui/material';
import Topbar from "../../../../../../component/TopBar";
import MainBox from "../../../../container/MainBox";
import { Outlet } from 'react-router';

const TimeSheetGeneralSettings = () => {
  return (
    <Box sx={{ width: "100%" }}>
            <Topbar MainHeading={"General Settings"}/>
            <MainBox sx={{ padding: "0.875rem 1rem" }}>
                <Outlet/>
            </MainBox>
    </Box>
  )
}

export default TimeSheetGeneralSettings