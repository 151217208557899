import React from 'react'
import { Box } from '@mui/material'

const RequestDetails = () => {

  return (
    <Box>
         <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom:"1.5rem", alignItems:"end" }}>
                <Box sx={{fontWeight:"500", fontSize:"1.5rem", lineHeight:"2.25rem"}}>
                   Request details
                </Box>
                
          </Box>
    </Box>
  )
}

export default RequestDetails