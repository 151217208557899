import React from 'react'
import {Box} from "@mui/material";
import styled from "@emotion/styled";
import { Link,} from "react-router-dom";

const NavHeading = styled(Box)({
    lineHeight:"1.5rem",
 })

const StyledLink = styled(Link)({
     color:"inherit",
     textDecoration:"none"
})

const TopNavbar = ({navItems,active}) => {
  return (
    <Box sx={{width:"100%", display:"flex", gap:"1.25rem", borderBottom:"1px solid #A2A1A833"}}>
         {
            navItems.map((item)=>{
                return(
                    <NavHeading 
                        sx={{
                              color:active===item.check?"#0081FF":"#16151C",
                              borderBottom:active===item.check?"2px solid #0081FF":"none",
                              fontSize:active===item.check?"1.125rem":"1rem",
                              fontWeight:active===item.check?"400":"300"
                           }}
                    >   
                       <StyledLink to={item.link}>{item.value}</StyledLink>
                    </NavHeading>
                )
            })
         }
    </Box>
  )
}

export default TopNavbar