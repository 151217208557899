import React, { useState, useEffect } from 'react';
import {Box, IconButton} from '@mui/material';
import UpArrow from '../assets/icons/up-arrow-blue.svg';
import DownArrow from '../assets/icons/down-arrow-blue.svg';
import styled from "@emotion/styled";
import FormField from './Formfield';

const TimeLabel = styled(Box)({
  width:"2rem", 
  fontWeight:"600",
  display:"flex", 
  alignItems:"center", 
  justifyContent:"center"
});

export default function MultiSectionClock({ 
  showHours = true, showMinutes = true, initialHours=0, initialMinutes=0, onChangeHours, onChangeMinutes, onChangeTime, disabled
}) {
  const [hours, setHours] = useState(initialHours || 0);
  const [minutes, setMinutes] = useState(initialMinutes || 0);

  useEffect(() => {
    setHours(initialHours || 0);
  }, [initialHours]);

  useEffect(() => {
    setMinutes(initialMinutes || 0);
  }, [initialMinutes]);

  const increaseHours = () => {
    setHours((prevHours) => {
      const newHours = (prevHours + 1) % 24;
      const totalMinutes = newHours * 60 + minutes;
      onChangeHours?.(newHours);
      onChangeTime?.(totalMinutes);
      return newHours;
    });
  };

  const decreaseHours = () => {
    setHours((prevHours) => {
      const newHours = (prevHours - 1 + 24) % 24;
      const totalMinutes = newHours * 60 + minutes;
      onChangeHours?.(newHours);
      onChangeTime?.(totalMinutes);
      return newHours;
    });
  };

  const increaseMinutes = () => {
    setMinutes((prevMinutes) => {
      const newMinutes = (prevMinutes + 1) % 60;
      const totalMinutes = hours * 60 + newMinutes;
      onChangeMinutes?.(newMinutes);
      onChangeTime?.(totalMinutes);
      return newMinutes;
    });
  };

  const decreaseMinutes = () => {
    setMinutes((prevMinutes) => {
      const newMinutes = (prevMinutes - 1 + 60) % 60;
      const totalMinutes = hours * 60 + newMinutes;
      onChangeMinutes?.(newMinutes);
      onChangeTime?.(totalMinutes);
      return newMinutes;
    });
  };

  const handleHoursChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      setHours("");
    } else {
      const intValue = parseInt(value, 10);
      if (!isNaN(intValue)) {
        const newHours = intValue >= 0 && intValue <= 23 ? intValue : 23;
        const totalMinutes = newHours * 60 + minutes;
        setHours(newHours);
        onChangeHours?.(newHours); 
        onChangeTime?.(totalMinutes);
      }
    }
  };

  const handleMinutesChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      setMinutes("");
    } else {
      const intValue = parseInt(value, 10);
      if (!isNaN(intValue)) {
        const newMinutes = intValue >= 0 && intValue <= 59 ? intValue : 59;
        const totalMinutes = hours * 60 + newMinutes;
        setMinutes(newMinutes);
        onChangeMinutes?.(newMinutes);
        onChangeTime?.(totalMinutes);
      }
    }
  };

  return (
      <Box sx={{display:"flex", gap:"0.5rem", alignItems:"center"}}>
        {showHours && (
          <>
             <Box>
                <IconButton onClick={increaseHours} sx={{display:"block", padding:0}} disabled={disabled}>
                  <img src={UpArrow} sx={{height:"0.5rem", width:"0.5rem"}}/>
                </IconButton>
                <IconButton onClick={decreaseHours} sx={{display:"block", padding:0}} disabled={disabled}>
                  <img src={DownArrow} sx={{height:"0.5rem", width:"0.5rem"}}/>
                </IconButton>
            </Box>
            <FormField
                width="3rem"
                height="3.5rem"
                type="text"
                value={hours}
                onChange={(e) => handleHoursChange(e)}
                disabled={disabled}
            /> 
            <TimeLabel>Hrs</TimeLabel>
          </>
        )}
        {showMinutes && (
          <>
            <Box>
                <IconButton onClick={increaseMinutes}  sx={{display:"block", padding:0}} disabled={disabled}>
                  <img src={UpArrow} sx={{height:"0.5rem", width:"0.5rem"}}/>
                </IconButton>
                <IconButton onClick={decreaseMinutes}  sx={{display:"block", padding:0}} disabled={disabled}>
                  <img src={DownArrow} sx={{height:"0.5rem", width:"0.5rem"}}/>
                </IconButton>
            </Box>  
            <FormField
                width="3rem"
                height="3.5rem"
                type="text"
                value={minutes}
                onChange={(e) => handleMinutesChange(e)}
                disabled={disabled}
            />   
            <TimeLabel>Min</TimeLabel>         
          </>
        )} 
      </Box>
  );
}
