import React, { useState } from "react";
import { Box } from "@mui/material";
import { Outlet } from "react-router";
import Topbar from "../../../../../../component/TopBar";
import MainBox from "../../../../container/MainBox";

function ExpensePolicy() {

  return (
     <Box sx={{width:"100%"}}>
        <Topbar MainHeading={"Expense Policy"}/>
        <MainBox sx={{ padding: "1.8125rem 1.25rem", height:"100%" }}>
              <Outlet/>
        </MainBox>
     </Box>
      
  );
}

export default ExpensePolicy;
