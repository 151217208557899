import React from 'react';
import { Button, Box } from '@mui/material';
import AddIcon from '../assets/icons/plus.svg';
import RemoveIcon from '../assets/icons/minus.png';

const AddRemoveButton = ({ type, onClick, children, disabled }) => {
  return (
    <Button 
            onClick={onClick}
             sx={{
                fontSize: "0.875rem",
                lineHeight: "1.31rem",
                color:"#000000",
                textTransform: "none",
                marginBottom: '1rem',
                display:"flex",
                alignItems:'center',
                "&.MuiButtonBase-root":{padding:0},
                '& .start-icon': {
                    width: '1.125rem',
                    height: '1.125rem',
                  },
                }} 
            disabled={disabled}
            startIcon={
                <Box sx={{width: '1.125rem', 
                          height: '1.125rem', 
                          borderRadius: '50%', 
                          backgroundColor:type==="add"?'#00AE88':'#F05C5C80',
                          display: 'flex', 
                          justifyContent: 'center', 
                          alignItems: 'center'
                        }}>
                  <img src={type==="add"?AddIcon:RemoveIcon} className="start-icon" />
                </Box>
              }
          >
                {children}
          </Button>
  );
};

export default AddRemoveButton;
