import React,{useState, useEffect} from 'react';
import {useNavigate } from 'react-router-dom';
import {Box} from "@mui/material";
import PaginationTable from '../../../../../../../component/PaginationTable';
import {getAllVehicleType} from '../../../../../../../services/trips';
import { getLegalUnitList } from "../../../../../../../services/globalData";
import { getEmployeeNames } from '../../../../../../../utils/getEmployeeNames';
import { formatDateByDay } from '../../../../../../../utils/formatDate';
import PolicyListingHeader from "../../../../../../../component/PolicyListingHeader";
import { PolicyTableCell } from "../../../../../../../styles/PolicyTableCell";
import { PolicyTableHeadings } from "../../../../../../../styles/PolicyTableHeadings";

const VehicleListing = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
      const fetchData = async () => {
        try {
          const legalUnit = getLegalUnitList();
          const res = await getAllVehicleType(legalUnit[0]._id);
          const employeeIds = res.data.map(data => data.createdBy);
          const combinedData = await getEmployeeNames(res.data, employeeIds, "createdBy");
          setData(combinedData);
          console.log(combinedData);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoading(false);
        }
      };
    
      fetchData();
    }, []);
  
    const headings = [
      { label: "Code", style: {...PolicyTableHeadings, borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px" } },
      { label: "Vehicle Category", style: {...PolicyTableHeadings} },
      { label: "Used in Policies", style: {...PolicyTableHeadings} },
      { label: "Created By", style: {...PolicyTableHeadings} },
      { label: "Last Modified", style: {...PolicyTableHeadings, borderTopRightRadius: "12px", borderBottomRightRadius: "12px"} }
    ];

    const handleMenuItemClick = (id) => { 
      navigate("form",{state:{typeId:id}});
    };

  return (
    <Box>
        <PolicyListingHeader 
          Heading = "Vehicle Types"
          ButtonText = "+ Create Vehicle Type"
          ButtonLink = "form"
        />
        <PaginationTable
          rows={data}
          headings={headings}
          loading={loading}
          noRecordMessage={"No Record Found"}
          renderCell={(row) => (
            <>
              <PolicyTableCell>{row.code}</PolicyTableCell>
              <PolicyTableCell onClick={() => handleMenuItemClick(row._id)}>
                 <Box sx={{width:"100%", ":hover":{cursor:"pointer"}, color:"#008cc4",textDecoration:"underline"}}>
                      {row.name}
                </Box>
              </PolicyTableCell>
              <PolicyTableCell>{row.policy?.length}</PolicyTableCell>
              <PolicyTableCell>{row.employee.fname+" "+row.employee.lname}</PolicyTableCell>
              <PolicyTableCell>{formatDateByDay(row.updatedAt)}</PolicyTableCell>
            </>
          )}
        />
    </Box>
  )
}

export default VehicleListing