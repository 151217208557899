import React,{useState, useEffect} from 'react';
import { Box, Avatar, CircularProgress, Autocomplete, TextField} from "@mui/material";
import styled from '@emotion/styled';
import BtnSecondary from "../../../../.../../../../component/SecondaryButton";
import { getOrgDepartments, getOrgEmployees, getOrgGroups, getOrgShifts } from "../../../../.../../../../services/globalData";
import { useLocation } from "react-router-dom";
import {getAttendancePolicyById, updateAttendancePolicy } from '../../../../.../../../../services/attendancePolicy';
import ProgressBar from '../../../../.../../../../component/ProgressBar';
import DocsIcon from '../../../../.../../../../assets/icons/docs.svg';
import { findGroup } from '../../../../.../../../../utils/findGroup';
import { findDepartment } from '../../../../.../../../../utils/findDepartment';
import FormField from '../../../../.../../../../component/Formfield';
import { currentUserDetails } from '../../../../.../../../../utils/currentUserDetails';
import DateField from '../../../../.../../../../component/DateField';
import Pencil from '../../../../.../../../../assets/icons/pencil.svg';
import BtnPrimary from '../../../../.../../../../component/PrimaryButton';
import TypeBtn from '../../../../.../../../../component/AddRemoveButton';
import SortByTable from '../../../../.../../../../component/assign-policy/SortByTable';
// import AttendancePolicyEditCategory from './AttendancePolicyEditCategory';
import ErrorMsg from '../../../../.../../../../component/ErrorMsg';
import SaveDialog from '../../../../.../../../../component/SaveDialog';
import TopBar from '../../../../../../component/TopBar';
import MainBox from '../../../../container/MainBox';
import CheckboxField from '../../../../../../component/CheckboxField';
import SwitchToggle from '../../../../../../component/SwitchToggle';
import RadioButtonGroup from '../../../../../../component/RadioButtonGroup';
import MultiSectionClock from '../../../../../../component/MultiSectionClock';


const HeadingBox = styled(Box)({
        fontSize: "1rem",
        lineHeight: "1.5rem",
});

const TextBox = styled(HeadingBox)({
  color: '#818181',
  // marginBottom:"4px"
});

const HeaderSection = () => (
  <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1.9375rem', alignItems: 'end' }}>
      <Box>
          <Box sx={{ display: 'flex' }}>
              <Avatar src={DocsIcon} style={{ width: '1.5rem', height: '1.5rem' }} />
              <Box sx={{
                      fontWeight: '300',
                      fontSize: '1rem',
                      lineHeight: '1.5rem',
                      color: '#0081FF',
                      marginBottom: '0.56rem',
                  }}
              >
                  Attendance Policies
              </Box>
          </Box>
          <ProgressBar />
      </Box>
      <BtnSecondary 
         sx={{ width: '5.56rem', height: '2.25rem', borderRadius: '10px' }} 
         to={"/app/attendance-policy/listing"}
      >
          Back
      </BtnSecondary>
  </Box>
);

const Headings = ({title,onEditClick, onSaveClick, edit, apiCall}) => (
    <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", marginBottom:"1.875rem" }}>
      <HeadingBox>{title}</HeadingBox>
      {
        edit?(
          <BtnPrimary sx={{ width: '10rem', height: '2.25rem', borderRadius: '10px' }} onClick={onSaveClick}>
            {apiCall?<CircularProgress size={"1rem"}/>:"Save Changes"}
          </BtnPrimary>
        ):<Avatar src={Pencil} sx={{width:"1.5rem",height:"1.5rem",'&:hover': {cursor: "pointer"}}} onClick={onEditClick}/>
      }
    </Box>
)


const AttendancePolicyEdit = ({type}) => {
        const {state} = useLocation();
        const createdBy = currentUserDetails().fname+" "+currentUserDetails().lname;
        const [loading, setLoading] = useState(true);
        const [apiCall,setApiCall]=useState(false);
        const [isSaveOpen, setIsSaveOpen]=useState(false);
        const [policyData,setPolicyData] = useState({});
        const [startDateDialogOpen, setStartDateDialogOpen] = useState(false);
        const [endDateDialogOpen, setEndDateDialogOpen] = useState(false);
        const [validationErrors, setValidationErrors] = useState({
                                                                  code:"",
                                                                  name:"",
                                                                  startDate:"",
                                                                  endDate:"",
                                                                });
        const [error,setError]=useState("");
        const approverOptions = getOrgEmployees().map(employee => ({
                    name: employee.fname+" "+employee.lname,
                    value: employee._id
        }));
        const [sortBy,setSortBy] = useState("");
        const [detailsEdit,setDetailsEdit]=useState(false);
        const [categoryEdit,setCategoryEdit]=useState(false);
        const [assignPolicyEdit,setAssignPolicyEdit]=useState(false);
        const [approverEdit,setApproverEdit]=useState(false);
        const [selectedList,setSelectedList] = useState({department: [],employeeGroup:[]});
        const [data, setData] = useState();
        const allDepartments = getOrgDepartments();
        const allGroups = getOrgGroups();
        const shiftOptions = getOrgShifts()?.map((shift) => ({
          value: shift?._id,
          name: shift?.name,
        }));
        const [selectedShifts, setSelectedShifts] = useState([]);
      
        const handleShiftChange = (event, newValue) =>{
          setSelectedShifts(newValue);
          const selectedIds = newValue.map((shift) => shift?.value);
          handleChange('shifts',selectedIds);
        }

        useEffect(()=>{
          setData(sortBy==="Departments"?allDepartments:allGroups);
        },[sortBy])

        useEffect(()=>{
                getPolicyData(state.policyId);
        },[]);

        //to get policy data
        const getPolicyData = async (policyId) => {
                setLoading(true);
                try {
                    const res = await getAttendancePolicyById(policyId); 
                    const data = res.data;
                    console.log(data);
                    if(res.success){
                          setPolicyData(prevState => ({
                            ...prevState,
                            code: data?.code,
                            name: data?.name,
                            startDate: data?.startDate?.split('T')[0],
                            endDate: data?.endDate?.split('T')[0],
                            enableGeofencing: data?.enableGeofencing,
                            allowMobileClockIn: data?.allowMobileClockIn,
                            requireCommentMobileClockIn: data?.requireCommentMobileClockIn,
                            allowWebClockIn: data?.allowWebClockIn,
                            requireCommentWebClockIn: data?.requireCommentWebClockIn,
                            enableAbsenteeism: data?.enableAbsenteeism,
                            autoDetectAbsenteeism: data?.autoDetectAbsenteeism,
                            minDailyHours: data?.minDailyHours,
                            absenteeismThreshold: data?.absenteeismThreshold,
                            absenteeismTimePeriod: data?.absenteeismTimePeriod,
                            penaliseAdjoiningHolidays: data?.penaliseAdjoiningHolidays,
                            penaliseAdjoiningWeekends: data?.penaliseAdjoiningWeekends,
                            minAbsenceDurationForAdjoiningHolidayPenalty: data?.minAbsenceDurationForAdjoiningHolidayPenalty,
                            minAbsenceDurationForAdjoiningWeekendPenalty: data?.minAbsenceDurationForAdjoiningWeekendPenalty,
                            absenteeismPenalty: data?.absenteeismPenalty,
                            noOfPaidLeaveDeductForAbsenteeism: data?.noOfPaidLeaveDeductForAbsenteeism,
                            noOfDaysOfPayLossForAbsenteeism: data?.noOfDaysOfPayLossForAbsenteeism,
                            enableTardiness: data?.enableTardiness,
                            tardinessType:data?.tardinessType,
                            tardinessThreshold:data?.tardinessThreshold,
                            minimumPunctuality:data?.minimumPunctuality,
                            tardinessTimePeriod:data?.tardinessTimePeriod,
                            punctualityTimePeriod:data?.punctualityTimePeriod,
                            noOfPermissibleLateArrivals:data?.noOfPermissibleLateArrivals,
                            tardinessPenalty:data?.tardinessPenalty,
                            noOfDaysOfPayLossForTardiness: data?.noOfDaysOfPayLossForTardiness,
                            noOfPaidLeaveDeductForTardiness: data?.noOfPaidLeaveDeductForTardiness,
                            employeeGroup: data?.employeeGroup,
                            department: data?.department,
                            approverIds: data?.approverIds,
                        })); 
                        const shiftsInPolicy = shiftOptions?.filter((option) =>
                          data?.shifts?.includes(option.value)
                        );
                        setSelectedShifts(shiftsInPolicy);
                    }
                    if(data?.employeeGroup?.length>0){
                       setSortBy("Groups");
                       setSelectedList(prevState => ({...prevState,employeeGroup: data?.employeeGroup}));
                    }else if(data?.department?.length>0){
                      setSortBy("Departments");
                      setSelectedList(prevState => ({...prevState,department: data?.department}));
                    }
                } catch (error) {
                    console.error('Error fetching policy data:', error);
                }
                setLoading(false);
        };
          
        //to manage input
        const handleChange = (field, value) => {
                const updatedValue = field === "code" ? value?.toUpperCase() : value;
                setPolicyData(prevState => ({...prevState, [field]: updatedValue}));
                setValidationErrors(prevState => ({...prevState, [field]: ""}));
                setError("");
        };

        //to display selected policy bearer
        const formatPolicyBearer = (linkTo) =>{
          const employeeGroups = linkTo?.employeeGroup?.map(findGroup)?.map(obj => obj?.name).filter(Boolean).join(', ') || '';
          const departments = linkTo?.department?.map(findDepartment)?.map(obj => obj?.name).filter(Boolean).join(', ') || '';
          if(employeeGroups || departments)
              return `${employeeGroups}${employeeGroups && departments ? ', ' : ''}${departments}`;
          else
              return "No Policy Bearer Assigned Yet"
        }

        const handleAddApproval = () =>{
                setPolicyData(prevState => ({
                        ...prevState,
                        approverIds: [...prevState.approverIds, ""]
                }));
        }
            
        const handleRemoveApprover = (index) => {
                setPolicyData(prevState => {
                    const approverList = [...prevState.approverIds];
                    approverList.splice(index, 1);
                    return {
                        ...prevState,
                        approverIds: approverList
                    };
                });
        };
        
        const handleApproverChange = (index, value) => {
                setPolicyData(prevState => {
                    const updatedApprover = [...prevState.approverIds];
                    updatedApprover[index] = value;
                    return {
                        ...prevState,
                        approverIds: updatedApprover
                    };
                });
        };
            
      const handleSaveChanges = async() =>{
        setError("");
        setApiCall(true);
        const res = await updateAttendancePolicy(state.policyId,policyData);
        handlePolicyLinking();
        setApiCall(false);
        setDetailsEdit(false);
        setAssignPolicyEdit(false);
        setApproverEdit(false);
        setCategoryEdit(false);
      }

      const handlePolicyLinking = async() =>{
        setError("");
        setApiCall(true);
        const res = await updateAttendancePolicy(state.policyId, {department:selectedList?.department, employeeGroup: selectedList?.employeeGroup});
        if(!res?.success){
          setError(res?.message);
          setApiCall(false);
          return;
        }
        setApiCall(false);
        setAssignPolicyEdit(false);
      }

      const handleNextClick = () =>{
        handleSaveChanges();
        setIsSaveOpen(true);
      }

  return (
    loading?(
      <Box sx={{width:"100%",display:"flex", justifyContent:"center", alignItems:"center", height:"100vh"}}>
        <CircularProgress/>
      </Box>
    ):(
    <Box sx={{width:"100%",paddingBlock:"1rem"}}>
      <TopBar MainHeading={"Attendance Policy"}/>
      <MainBox sx={{ padding: "1.8125rem 1.25rem", height:"100%" }}>
        <HeaderSection type={type}/>
        <Box sx={{marginBottom: '1.9375rem'}}>
            <Headings 
             title="Policy Details" 
             edit={detailsEdit} 
             onEditClick={()=>setDetailsEdit(true)} 
             onSaveClick={handleSaveChanges}
             apiCall={apiCall}
            />
            <Box sx={{ width: "100%", display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                      <FormField
                          label="Policy Code"
                          type="text"
                          value={policyData.code}
                          validation={validationErrors.code}
                          disabled={!detailsEdit}
                          onChange={(e) => handleChange('code', e.target.value)}
                      />
                      <FormField
                          label="Policy Name"
                          type="text"
                          value={policyData.name}
                          validation={validationErrors.name}
                          disabled={!detailsEdit}
                          onChange={(e) => handleChange('name', e.target.value)}
                      />
                      <DateField
                        label="Policy Valid From"
                        value={policyData.startDate}
                        isDialogOpen={startDateDialogOpen}
                        validation={validationErrors.startDate}
                        openDialog={() => setStartDateDialogOpen(true)}
                        closeDialog={() => setStartDateDialogOpen(false)}
                        onSelect={(date) => {
                                  handleChange('startDate', date)
                                  setStartDateDialogOpen(false)
                                  }}
                        disabled={!detailsEdit}
                      />
                      <DateField
                        label="Policy Valid Till"
                        value={policyData.endDate}
                        isDialogOpen={endDateDialogOpen}
                        validation={validationErrors.endDate}
                        openDialog={() => setEndDateDialogOpen(true)}
                        closeDialog={() => setEndDateDialogOpen(false)}
                        onSelect={(date) => {
                                  handleChange('endDate', date)
                                  setEndDateDialogOpen(false)
                                }}
                        disabled={!detailsEdit}
                      />
                      <Box sx={{width:"100%", display:"flex", justifyContent:"space-between"}}>
                          <Box sx={{width:"48%"}}>
                            <SwitchToggle 
                                  checked={policyData?.allowMobileClockIn} 
                                  onChange={(e)=>handleChange('allowMobileClockIn',e.target.checked)} 
                                  label="Mobile clock in"
                                  disabled={!detailsEdit}
                              />
                              <CheckboxField
                                  label="Enable Geo-fencing"
                                  checked={policyData.enableGeofencing}
                                  disabled={!policyData?.allowMobileClockIn}
                                  onChange={(isChecked) => handleChange('enableGeofencing',isChecked)}
                                  labelStyle={{fontSize: "1rem", lineHeight: "1.5rem", color: `${!policyData?.allowMobileClockIn?"#81818180":"#818181"}`}}
                              />
                              <CheckboxField
                                  label="Comment is mandatory at clock in"
                                  checked={policyData.requireCommentMobileClockIn}
                                  disabled={!policyData?.allowMobileClockIn}
                                  onChange={(isChecked) => handleChange('requireCommentMobileClockIn',isChecked)}
                                  labelStyle={{fontSize: "1rem", lineHeight: "1.5rem", color: `${!policyData?.allowMobileClockIn?"#81818180":"#818181"}`}}
                              />
                          </Box>
                          <Box sx={{width:"48%"}}>
                            <SwitchToggle 
                                  checked={policyData?.allowWebClockIn} 
                                  onChange={(e)=>handleChange("allowWebClockIn",e.target.checked)} 
                                  label="Web clock in"
                                  disabled={!detailsEdit}
                              />
                              <CheckboxField
                                  label="Comment is mandatory at clock in"
                                  checked={policyData.requireCommentWebClockIn}
                                  disabled={!policyData?.allowWebClockIn}
                                  onChange={(isChecked) => handleChange('requireCommentWebClockIn',isChecked)}
                                  labelStyle={{fontSize: "1rem", lineHeight: "1.5rem", color: `${!policyData?.allowWebClockIn?"#81818180":"#818181"}`}}
                              />
                          </Box>            
                      </Box>
            </Box>
        </Box>
        <Box sx={{marginBottom: '1.9375rem'}}>
            <Headings 
             title="Add Category" 
             edit={categoryEdit} 
             onEditClick={()=>setCategoryEdit(true)} 
             onSaveClick={handleSaveChanges}
             apiCall={apiCall}
            />
            <Box>
               <Box sx={{width:"48%"}}>
                  <SwitchToggle 
                      label="Enable Absenteeism"
                      labelStyle={{fontSize: '1rem',lineHeight: '1.5rem',color: '#000'}}
                      checked={policyData?.enableAbsenteeism} 
                      onChange={(e)=>handleChange("enableAbsenteeism",e.target.checked)} 
                      disabled={!categoryEdit}
                  />
               </Box>
               {policyData?.enableAbsenteeism?(
                     <Box sx={{ width: "100%", display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                     <RadioButtonGroup
                         label="Automatically consider absent when there is no attendance login?"
                         labelStyle={{fontSize: "1rem",lineHeight: "1.5rem",color:"#818181", marginBottom:"4px"}}
                         horizontalLayout={true}
                         value={policyData?.autoDetectAbsenteeism} 
                         disabled={!categoryEdit}
                         onChange={(e) => handleChange('autoDetectAbsenteeism', e.target.value)}
                         options={[
                             { value: true, label: 'Yes' },
                             { value: false, label: 'No' }
                         ]}
                     />                  
                     <Box sx={{display:"flex", gap:"1rem", alignItems:"center"}}>
                         <TextBox>Minimum daily hours required to be considered present for a shift</TextBox>
                         <FormField
                             width="5rem"
                             type="text"
                             height="3rem"
                             disabled={!categoryEdit}
                             value={policyData.minDailyHours}
                             onChange={(e) => {handleChange('minDailyHours', e.target.value)}}
                         />             
                      </Box>
                      <Box sx={{display:"flex", gap:"1rem", alignItems:"center"}}>
                         <TextBox>Days of unreported leave will result in a warning before an employee faces penalties</TextBox>    
                         <Box sx={{display:"flex", alignItems:"center", gap:"0.75rem"}}>
                            <FormField
                                width="5rem"
                                type="text"
                                height="3rem"
                                disabled={!categoryEdit}
                                value={policyData.absenteeismThreshold}
                                onChange={(e) => {handleChange('absenteeismThreshold', e.target.value)}}
                            />
                            <Box sx={{fontSize:"1rem", lineHeight:"1.5rem", color:"#16151C80"}}>
                                Day(s) in a  
                              </Box>
                              <FormField
                                  width="11.625rem"
                                  type="select"
                                  options={[{name:"Week",value:"weekly"},{name:"Month", value:"monthly"},{name:"Year", value:"yearly"}]}
                                  value={policyData?.absenteeismTimePeriod}
                                  onChange={(e) => {handleChange('absenteeismTimePeriod', e.target.value)}}
                              /> 
                          </Box>
                      </Box>
                     <CheckboxField
                             label={
                               <TextBox sx={{display:"flex", alignItems:"center", gap:"0.2rem"}}>
                                 Penalized adjoining holiday, if there are
                                 <FormField
                                       width="5rem"
                                       height="3rem"
                                       type="text"
                                       disabled={!categoryEdit}
                                       value={policyData.minAbsenceDurationForAdjoiningHolidayPenalty}
                                       onChange={(e) => {handleChange('minAbsenceDurationForAdjoiningHolidayPenalty', e.target.value)}}
                                 /> 
                                 or more day(s) with no attendance.
                               </TextBox>
                             }
                             disabled={!categoryEdit}
                             checked={policyData.penaliseAdjoiningHolidays}
                             onChange={(isChecked) => handleChange('penaliseAdjoiningHolidays',isChecked)}
                     />
                     <CheckboxField
                             label={
                               <TextBox sx={{display:"flex", alignItems:"center", gap:"0.2rem"}}>
                                 Penalized adjoining weekend off, if there are 
                                 <FormField
                                       width="5rem"
                                       height="3rem"
                                       type="text"
                                       disabled={!categoryEdit}
                                       value={policyData.minAbsenceDurationForAdjoiningWeekendPenalty}
                                       onChange={(e) => {handleChange('minAbsenceDurationForAdjoiningWeekendPenalty', e.target.value)}}
                                 /> 
                                 or more day(s) with no attendance.
                               </TextBox>
                             }
                             disabled={!categoryEdit}
                             checked={policyData.penaliseAdjoiningWeekends}
                             onChange={(isChecked) => handleChange('penaliseAdjoiningWeekends',isChecked)}
                     />    
                     <CheckboxField
                           label="Employees are penalized if the exceed allowed unreported absences in a week or month."
                           checked={policyData?.absenteeismPenalty !== "none"}
                           onChange={(isChecked) => {
                             handleChange('absenteeismPenalty', isChecked ? "deduct" : "none");
                           }}
                           labelStyle={{fontSize: "1rem", lineHeight: "1.5rem", color:"#818181"}}
                           disabled={!categoryEdit}
                       />
                       {
                         policyData?.absenteeismPenalty !== "none"?(
                          <>
                             <RadioButtonGroup
                                 horizontalLayout={true}
                                 value={policyData?.absenteeismPenalty} 
                                 disabled={!categoryEdit}
                                 onChange={(e) => handleChange('absenteeismPenalty', e.target.value)}
                                 options={[
                                     { value: 'deduct', label: 'Loss of Pay' },
                                     { value: 'leave', label: 'Paid Leave' }
                                 ]}
                             />
                             {policyData?.absenteeismPenalty==="deduct"?
                              <Box sx={{width:"100%",display:"flex", alignItems:"center", gap:"0.2rem", color:"#16151C80"}}>
                                 <FormField
                                       width="5rem"
                                       height="2rem"
                                       type="text"
                                       disabled={!categoryEdit}
                                       value={policyData?.noOfDaysOfPayLossForAbsenteeism}
                                       onChange={(e) => {handleChange('noOfDaysOfPayLossForAbsenteeism', e.target.value)}}
                                 /> 
                                 day(s) Pay loss (LOP) for each incident week.
                              </Box> :
                              (policyData?.absenteeismPenalty==="leave"?
                                <Box sx={{width:"100%",display:"flex", alignItems:"center", gap:"0.2rem", color:"#16151C80"}}>
                                  <FormField
                                        width="5rem"
                                        height="2rem"
                                        type="text"
                                        disabled={!categoryEdit}
                                        value={policyData?.noOfPaidLeaveDeductForAbsenteeism}
                                        onChange={(e) => {handleChange('noOfPaidLeaveDeductForAbsenteeism', e.target.value)}}
                                  /> 
                                  day(s) Paid Leave.
                                </Box> 
                                :null) 
                             }
                          </>
                         ):null
                       }
                  </Box>
               ):null}
            </Box>
            <Box>
               <Box sx={{width:"48%"}}>
                  <SwitchToggle 
                      label="Enable Tardiness"
                      labelStyle={{fontSize: '1rem',lineHeight: '1.5rem',color: '#000'}}
                      checked={policyData?.enableTardiness} 
                      onChange={(e)=>handleChange("enableTardiness",e.target.checked)} 
                      disabled={!categoryEdit}
                  />
               </Box>
               {policyData?.enableTardiness?(
                     <Box sx={{ width: "100%", display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>                 
                        <Box sx={{width:"100%", display:"flex", flexWrap:"wrap", justifyContent: "space-between", alignItems:"center"}}>
                              <FormField
                                  label="Tardiness Type"
                                  type="select"
                                  options={[{name:"Late Login / Clock In", value:"late-login"},{name:"Low Punctuality", value:"low-punctuality"}]}
                                  value={policyData?.tardinessType}
                                  onChange={(e) => {handleChange('tardinessType', e.target.value)}}
                                  disabled={!categoryEdit}
                              />
                              <Box sx={{ width: "48%", marginBottom: '1.125rem'}}>
                                <TextBox sx={{marginBottom: '4px'}}>Scheduled Shift</TextBox>
                                <Autocomplete
                                  multiple
                                  options={shiftOptions}
                                  getOptionLabel={(option) => option?.name} 
                                  size="small"
                                  value={selectedShifts}
                                  onChange={handleShiftChange}
                                  disabled={!categoryEdit}
                                  sx={{
                                    '& .MuiInputBase-root': {
                                      borderRadius: '10px',
                                      fontSize: '1rem',
                                      lineHeight: '1.5rem',
                                      fontWeight:"300",
                                      color: '#16151CCC',
                                      height: "3rem"
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                      border: '1px solid #A2A1A833'
                                    },
                                  }}
                                  renderInput={(params) => <TextField {...params} />}
                                />
                              </Box>
                              <Box sx={{width:"48%"}}>
                              <TextBox>Set Limit for {policyData?.tardinessType==="low-punctuality"?"minimum punctuality rate":"Grace Period"}</TextBox>
                              <Box sx={{width:"100%",display:"flex", alignItems:"center", gap:"0.75rem", color:"#16151C80"}}>
                                  {policyData?.tardinessType==="low-punctuality"?
                                    (<>
                                      <FormField
                                          width="5rem"
                                          height="3.5rem"
                                          type="text"
                                          value={policyData?.minimumPunctuality}
                                          onChange={(e) => {handleChange('minimumPunctuality', e.target.value)}}
                                          disabled={!categoryEdit}
                                      /> /
                                      <FormField
                                            width="11.625rem"
                                            height="3.5rem"
                                            type="select"
                                            options={[{name:"Week",value:"weekly"},{name:"Month", value:"monthly"},{name:"Year", value:"yearly"}]}
                                            value={policyData?.tardinessType==="low-punctuality"?(policyData?.punctualityTimePeriod):(policyData?.tardinessTimePeriod)}
                                            onChange={(e) => {handleChange(
                                              policyData?.tardinessType==="low-punctuality"?"punctualityTimePeriod":"tardinessTimePeriod", e.target.value
                                            )}}
                                            disabled={!categoryEdit}
                                      /> 
                                    </>)
                                    :
                                    <MultiSectionClock 
                                          initialHours={Math.floor(policyData?.tardinessThreshold/60)||0}
                                          initialMinutes={Math.floor(policyData?.tardinessThreshold%60)||0}
                                          onChangeTime={(value) => handleChange('tardinessThreshold', value)}
                                          disabled={!categoryEdit}
                                      />
                                }
                              </Box> 
                              </Box>
                              <Box sx={{width:"48%"}}>
                                      <TextBox>How many times an employee is allowed to come in Late?</TextBox>
                                      <Box sx={{display:"flex", alignItems:"center", gap:"0.75rem", color:"#16151C80"}}>
                                              <FormField
                                                    width="5rem"
                                                    height="3rem"
                                                    type="text"
                                                    value={policyData?.noOfPermissibleLateArrivals}
                                                    onChange={(e) => {handleChange('noOfPermissibleLateArrivals', e.target.value)}}
                                                    disabled={!categoryEdit}
                                              />
                                              {/* times in a
                                              <FormField
                                                    width="11.625rem"
                                                    height="3rem"
                                                    type="select"
                                                    options={["Month", "Week", "Year"]}
                                                    value={policyData?.lateAllowedFrequency}
                                                    // onChange={(e) => {handleChange('lateAllowedFrequency', e.target.value)}}
                                              />  */}
                                      </Box> 
                              </Box>
                              <CheckboxField
                                      label="Employees are penalized if the exceed allowed number of late arrival in a  week/ month. "
                                      checked={policyData?.tardinessPenalty !== "none"}
                                      onChange={(isChecked) => {
                                        handleChange('tardinessPenalty', isChecked ? "deduct" : "none");
                                      }}
                                      labelStyle={{fontSize: "1rem", lineHeight: "1.5rem", color:"#818181"}}
                                      disabled={!categoryEdit}
                              />
                              {
                                policyData?.tardinessPenalty !== "none"?(
                                  <>
                                    <RadioButtonGroup
                                        horizontalLayout={true}
                                        value={policyData?.tardinessPenalty} 
                                        onChange={(e) => handleChange('tardinessPenalty', e.target.value)}
                                        options={[
                                            { value: 'deduct', label: 'Loss of Pay' },
                                            { value: 'leave', label: 'Paid Leave' }
                                        ]}
                                        disabled={!categoryEdit}
                                    />
                                    {policyData?.tardinessPenalty==="deduct"?
                                      <Box sx={{width:"100%",display:"flex", alignItems:"center", gap:"0.2rem", color:"#16151C80"}}>
                                        <FormField
                                              width="5rem"
                                              height="2rem"
                                              type="text"
                                              value={policyData?.noOfDaysOfPayLossForTardiness}
                                              onChange={(e) => {handleChange('noOfDaysOfPayLossForTardiness', e.target.value)}}
                                              disabled={!categoryEdit}
                                        /> 
                                        day(s) Pay loss (LOP) for each incident week.
                                      </Box> : (
                                        policyData?.tardinessPenalty==="leave"?
                                          <Box sx={{width:"100%",display:"flex", alignItems:"center", gap:"0.2rem", color:"#16151C80"}}>
                                            <FormField
                                                  width="5rem"
                                                  height="2rem"
                                                  type="text"
                                                  value={policyData?.noOfPaidLeaveDeductForTardiness}
                                                  onChange={(e) => {handleChange('noOfPaidLeaveDeductForTardiness', e.target.value)}}
                                                  disabled={!categoryEdit}
                                            /> 
                                            day(s) Paid Leave.
                                          </Box> 
                                        :null
                                      )
                                    }
                                  </>
                                ):null
                              }          
                        </Box>
                     </Box>
               ):null}
            </Box>
        </Box>
        <Box sx={{marginBottom: '1.9375rem'}}>
            <Headings 
             title="Assign Policy" 
             edit={assignPolicyEdit} 
             onEditClick={()=>setAssignPolicyEdit(true)} 
             onSaveClick={handlePolicyLinking}
             apiCall={apiCall}
            />
            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems:"center" }}>
                        <FormField
                            label="Sort By"
                            type="select"
                            options={["Departments","Groups"]}
                            value={sortBy}
                            disabled={!assignPolicyEdit}
                            onChange={(e)=>{setSortBy(e.target.value)}}
                        />
                        <FormField
                            label="Policy Bearers"
                            type="text"
                            value={formatPolicyBearer(selectedList)}
                            disabled={true}
                        />
            </Box>
            <Box sx={{marginBottom:"1rem"}}>
                <ErrorMsg>{error}</ErrorMsg>
            </Box>
            {(sortBy && assignPolicyEdit) && (
              <SortByTable
                sortBy={sortBy}
                data={data}
                selectedList={selectedList}
                setSelectedList={setSelectedList}  
              />
            )}   
        </Box>
        <Box>
            <Headings 
             title="Rules" 
             edit={approverEdit} 
             onEditClick={()=>setApproverEdit(true)} 
             onSaveClick={handleSaveChanges}
             apiCall={apiCall}
            />
            <Box>
            <Box>
            {   policyData?.approverIds?.length > 0 ? (
                <Box>
                 <Box sx={{fontSize: "1rem",lineHeight: "1.5rem", color:"#818181",marginBottom:"1rem"}}>
                    Approval Chain
                  </Box>
                 {
                  policyData?.approverIds?.map((item,index)=>(
                    <Box key={index} 
                         sx={{ width: "100%", 
                               display: "flex",  
                               marginBottom: "1rem", 
                               justifyContent:"space-between",
                               padding:"1.125rem 1.875rem",
                               borderRadius:"10px",
                               border:"1px solid #A2A1A833"
                            }}
                    >
                          <FormField
                              placeholder="Employee Name"
                              type="select"
                              options={approverOptions}
                              value={item}
                              disabled={!approverEdit}
                              onChange={(e) => handleApproverChange(index, e.target.value)}
                          />
                          <TypeBtn type="remove" onClick={()=>handleRemoveApprover(index)} disabled={!approverEdit}>
                                  Remove 
                          </TypeBtn>
                    </Box>
                  ))
                  }
                </Box>
              ): (
                <Box sx={{fontSize:"1rem",lineHeight:"1.5rem", color: '#818181', marginBottom:"1rem"}}>
                        No approvers assigned yet
                </Box>
              )
            }
            <Box sx={{display:approverEdit?"flex":"none"}}>
                <TypeBtn onClick={handleAddApproval} type="add">
                    Add Approver
                </TypeBtn>
            </Box>
          </Box>
        </Box>
        </Box>
        <Box sx={{display:"flex", justifyContent:"center", gap:"1rem", marginTop:"1.875rem"}}>
              <BtnPrimary 
                onClick={handleNextClick} 
                sx={{width:"18.75rem", height:"3.125rem", borderRadius:"10px"}}
                disabled={apiCall}
              >
                  {apiCall?<CircularProgress color='info'/>:"Save Changes"}
              </BtnPrimary> 
        </Box>
        <SaveDialog 
            open={isSaveOpen} 
            onClose={()=>setIsSaveOpen(false)} 
            redirectLink="/app/attendance-policy/listing"
        />
        </MainBox>
    </Box>
  ))
}

export default AttendancePolicyEdit
        