import React from 'react';
import {Box} from "@mui/material";
import BtnSecondary from "../../../../../../component/SecondaryButton";
import FormField from '../../../../../../component/Formfield';
import TypeBtn from '../../../../../../component/AddRemoveButton';

const EditPolicyCategory = ({categoryEdit, policyData, setPolicyData, categoryOptions}) => {

  //to get corresponding code of a given category
  const getCode = (id) => (categoryOptions.find((option)=>option.value===id)?.code)

  //to select a category
  const handleCategorySelection = (index, fieldName, value) => {
          let selectedLeaveQuantity = [...policyData.leaveQuantities];
          selectedLeaveQuantity[index][fieldName] = value;
          setPolicyData((prev)=>({...prev,leaveQuantities:selectedLeaveQuantity}));
  };

  //to remove a category
  const handleRemoveQuantity = (index) => {
    let selectedLeaveQuantity = [...policyData.leaveQuantities];
    selectedLeaveQuantity.splice(index, 1);
    setPolicyData((prev)=>({...prev,leaveQuantities:selectedLeaveQuantity}));
  };

  //to add new expense fields
  const handleAddQuantity = () =>{
    setPolicyData((prev)=>({...prev,leaveQuantities:[...policyData.leaveQuantities,{ _id: "", leaveType:"", quantity:""}]}));
  }

    

  return (
    <Box> 
     {
        policyData?.leaveQuantities?.map((item,index)=>(
          <Box key={index} sx={{ width: "100%", display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
            <FormField
                label="Choose Category"
                type="select"
                options={categoryOptions}
                value={item?.leaveType}
                onChange={(e) => {
                          handleCategorySelection(index, 'leaveType', e.target.value)
                        }}
                disabled={!categoryEdit}
            />
            <FormField
                label="Select Quantity"
                type="text"
                value={item?.quantity}
                onChange={(e) => {
                    handleCategorySelection(index, 'quantity', e.target.value)
                }}
                disabled={!categoryEdit}
            />
            <Box sx={{width:"100%", display:categoryEdit?"flex":"none", justifyContent:"end"}}>
              <TypeBtn type="remove" onClick={()=>handleRemoveQuantity(index)} sx={{width: '1.125rem', height: '1.125rem'}} >
                 Remove Category
              </TypeBtn>
            </Box>
          </Box>
        ))
      }
      <Box sx={{display:categoryEdit?"flex":"none"}}>
          <TypeBtn type="add" onClick={handleAddQuantity}>
              Add New Category
          </TypeBtn>  
      </Box>   
    </Box>
  )
}

export default EditPolicyCategory