import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import Topbar from "../../../../../../../component/TopBar";
import MainBox from "../../../../../container/MainBox";
import { useNavigate } from "react-router";
import { PolicyTableHeadings } from "../../../../../../../styles/PolicyTableHeadings";
import { PolicyTableCell } from "../../../../../../../styles/PolicyTableCell";
import { formatDateByDay } from "../../../../../../../utils/formatDate";
import PaginationTable from "../../../../../../../component/PaginationTable";
import PolicyListingHeader from "../../../../../../../component/PolicyListingHeader";
import { getAllShift } from "../../../../../../../services/shift";
import { calculateTime } from "../../../../../../../utils/calculateTime";
import { getLocationNameFromLegalUnit } from "../../../../../../../utils/getLocationNameFromLegalUnit";

function ShiftListing() {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        const fetchData = async () => {
          try {
            const res = await getAllShift();
            console.log(res);
            setData(res?.data?.reverse());
            setLoading(false);
          } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
          }
        };
      
        fetchData();
      }, []);
  
    const headings = [
      { label: "Shift Code", style: {...PolicyTableHeadings, width:"7.68rem", borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px" } },
      { label: "Shift Name", style: {...PolicyTableHeadings,  width:"15.5rem"} },
      { label: "Shift Time", style: {...PolicyTableHeadings, width:"12.56rem"} },
      { label: "Location", style: {...PolicyTableHeadings, width:"12.56rem"} },
      { label: "Created On", style: { ...PolicyTableHeadings,width:"12.56rem", borderTopRightRadius: "12px", borderBottomRightRadius: "12px" } }
    ];

    const handleMenuItemClick = (id) => { 
      navigate("/app/shift/edit",{state:{shiftId:id}});
    };

    const getFirstValidDay = (days) => {

      for (const day of days) {
          if (day.minFullDayHours && day.minFullDayHours!==0) {
              return day.minFullDayHours+" hrs"; 
          }
          if (day.startTime && day.startTime !== 0 && day.endTime !== 0) {
              return calculateTime(day.startTime)+" - "+calculateTime(day.endTime); 
          }
      }
      return null; 
  };

  return (
     <Box sx={{width:"100%",paddingBlock:"1rem"}}>
        <Topbar MainHeading={"Shift Schedular"}/>
        <MainBox sx={{ padding: "1.8125rem 1.25rem", height:"100%" }}>
                <PolicyListingHeader 
                    Heading = "Shifts"
                    ButtonText = "+ Create New Shift"
                    ButtonLink = "/app/shift/new/details"
                />
                <PaginationTable
                    rows={data}
                    headings={headings}
                    loading={loading}
                    noRecordMessage={"No Record Found"}
                    renderCell={(row) => (
                        <>
                        <PolicyTableCell>{row?.code}</PolicyTableCell>
                        <PolicyTableCell 
                            onClick={() => handleMenuItemClick(row?._id)}
                        >
                          <Box sx={{width:"100%", ":hover":{cursor:"pointer"}, color:"#008cc4",textDecoration:"underline"}}>
                            {row?.name}
                          </Box>
                        </PolicyTableCell>
                        <PolicyTableCell>
                          {getFirstValidDay(row?.days)}
                        </PolicyTableCell>
                        <PolicyTableCell>
                          {getLocationNameFromLegalUnit(row?.legalUnit)||"--"}
                        </PolicyTableCell>
                        <PolicyTableCell>{formatDateByDay(row?.createdAt)}</PolicyTableCell>
                        </>
                    )}
                />
        </MainBox>
     </Box>
      
  );
}

export default ShiftListing;
