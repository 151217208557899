import React,{useState, useEffect} from 'react';
import {Avatar, Box, CircularProgress} from '@mui/material';
import BtnPrimary from '../../../../../../../component/PrimaryButton';
import BtnSecondary from '../../../../../../../component/SecondaryButton';
import FormField from '../../../../../../../component/Formfield';
import {createVehicleType, getVehicleTypeById, updateVehicleType} from "../../../../../../../services/trips";
import { getLegalUnitList } from "../../../../../../../services/globalData";
import { useNavigate,useLocation } from "react-router-dom";
import DeleteWarning from '../../../../../../../component/DeleteWarning';
import SaveDialog from '../../../../../../../component/SaveDialog';
import { set } from 'firebase/database';
import ErrorMsg from '../../../../../../../component/ErrorMsg';
import { currentUserDetails } from '../../../../../../../utils/currentUserDetails';
import ProgressBar from '../../../../../../../component/ProgressBar';
import DocsIcon from '../../../../../../../assets/icons/docs.svg';

const VehicleForm = () => {
  const [loading,setLoading]=useState(false);
  const [apiCall,setApiCall]=useState(false);
  const [error, setError]=useState("");
  const navigate = useNavigate();
  const {state} = useLocation();
  const [mode, setMode] = useState('create'); 
  const [typeId, setTypeId] = useState(null);
  const [isDeleteDialogOpen,setIsDeleteDialogOpen] = useState(false);
  const [isSaveDialogOpen,setIsSaveDialogOpen] = useState(false);
  const [vehicleForm,setVehicleForm] = useState({
    code:"",
    name:"",
    description:"",
    iconFile:null
  })
  const user = currentUserDetails();
  const createdBy = user.fname+" "+user.lname;
  const [validationErrors, setValidationErrors] = useState({
    code:"",
    name:"",
  });

  useEffect(() => {
      if (state && state.typeId) {
          setMode('view');
          setTypeId(state.typeId);
          fetchTypeData(state.typeId); 
      } 
  }, [state]);

  const fetchTypeData = async (typeId) => {
    setLoading(true);
      try {
         const res = await getVehicleTypeById(typeId);
         setVehicleForm(prevState=> ({
            ...prevState,
            code:res?.data?.code,
            name:res?.data?.name,
            description:res?.data?.description,
            // iconFile:res?.data?.iconFile,
         }))
         console.log(res);
      } catch (error) {
          console.error('Error fetching data:', error);
      }
      setLoading(false);
  };
  
    const handleIconChange = (event) => {
      setVehicleForm(prevState => ({
        ...prevState,
        iconFile: event.target.files[0]
    }));
    };

    const handleDelete = () =>{
      setIsDeleteDialogOpen(true);
    }

    const handleChange = (field, value) => {
        const updatedValue = field === "code" ? value?.toUpperCase() : value;
        setVehicleForm(prevState => ({...prevState,[field]: updatedValue}));
        setValidationErrors(prevState => ({...prevState,[field]: ""}));
        setError("");
    };

    const modeSwitch = () =>{
      setMode(mode==="edit"?"view":"edit")
    }

  const handleSaveClick = async() => {
    const errors ={};
    if (!vehicleForm.code) {
        errors.code="Code is required"
    }
    if (!vehicleForm.name) {
      errors.name="Name is required"
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    try {
        setApiCall(true);
        if (mode === 'create') {
            vehicleForm.type="TRIP"
            const legalUnit = getLegalUnitList();
            vehicleForm.legalUnit = legalUnit[0]._id;
            const res = await createVehicleType(vehicleForm);
            console.log(res); 
            if (res.success) {
                navigate("/app/vehicle-type");
            } else {
                console.error('Vehicle creation failed:', res.error);
                if(res.message.includes("policy cateogary with code already exists"))
                   setError("Vehicle with same code exists");
            }
        } else if (mode === 'edit') {
            const res = await updateVehicleType(typeId,vehicleForm);
            console.log(res); 
            if (res.success) {
                setIsSaveDialogOpen(true);
            } else {
                console.error('Vehicle creation failed:', res.error);
                setError(res.message);
                if(res.message.includes("policy cateogary with code already exists"))
                  setError("Vehicle with same code exists");
            }
        }
    } catch (error) {
        console.error('Error saving Vehicle:', error);
    }
    setApiCall(false);
};

  return (
    loading?(
        <Box sx={{width:"100%",display:"flex", justifyContent:"center"}}>
          <CircularProgress/>
        </Box>
      ):(
    <Box sx={{width:"100%"}}>
            <Box sx={{display:"flex", justifyContent:"space-between"}}>
                <Box>
                    <Box sx={{display:"flex"}}>
                      <Avatar src={DocsIcon} style={{ width: '1.5rem', height: '1.5rem' }}/>
                      <Box sx={{fontWeight:"300", fontSize:"1rem", lineHeight:"1.5rem", color:"#0081FF", marginBottom:"0.56rem"}}>
                          Vehicle Type
                      </Box>
                    </Box>
                    <ProgressBar/>
                </Box> 
                <BtnSecondary 
                      to="/app/vehicle-type"
                      sx={{width:"5.56rem", height:"2.25rem", borderRadius:"10px"}}
                  >
                        Back
                </BtnSecondary>
            </Box>
        <Box sx={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", marginTop: "1rem", justifyContent: "space-between" }}>
                  <FormField
                      label="Category Code"
                      type="text"
                      value={vehicleForm.code}
                      validation={validationErrors.code}
                      onChange={(e) => handleChange('code', e.target.value)}
                      disabled={mode === 'view'}
                  />
                  <FormField
                      label="Category Name"
                      type="text"
                      value={vehicleForm.name}
                      validation={validationErrors.name}
                      onChange={(e) => handleChange('name', e.target.value)}
                      disabled={mode === 'view'}
                  />
                  <FormField
                      label="Add Icon"
                      type="file"
                      onChange={handleIconChange}
                  />
                  <FormField
                      label="Created By"
                      type="text"
                      value={createdBy}
                      disabled={true}
                  />
                  <FormField
                      label="Description"
                      type="text"
                      value={vehicleForm.description}
                      onChange={(e) => handleChange('description', e.target.value)}
                      disabled={mode === 'view'}
                      fullwidth
                      row={4}
                  />
        </Box>
        <ErrorMsg>{error}</ErrorMsg>
            <Box sx={{ display: "flex", justifyContent: "end", gap: "1rem", marginBlock: "1.875rem" }}>
                {mode==="view"?
                      <BtnSecondary onClick={modeSwitch} sx={{borderRadius:"10px",width:"5.56rem",height:"2.25rem"}}>
                        Edit
                      </BtnSecondary> 
                  :(
                    <Box sx={{display:"flex", gap:"0.5rem"}}>
                        <BtnPrimary 
                            onClick={handleSaveClick} 
                            sx={{ borderRadius: "8px", width: "11.875rem", height: "3.125rem" }}
                            disabled={apiCall}
                        >
                          {apiCall?<CircularProgress/>:"Save Vehicle Type"}
                        </BtnPrimary>
                        {mode==="edit"?(
                          <BtnSecondary 
                          onClick={handleDelete} 
                          sx={{borderRadius: "8px", width: "11.875rem", height: "3.125rem" }}
                            >
                              Delete
                            </BtnSecondary>
                        ):null}
                    </Box>
              )}
            </Box>
            <SaveDialog open={isSaveDialogOpen} onClose={()=>setIsSaveDialogOpen(false)} redirectLink={`/app/vehicle-type`}/>
            <DeleteWarning open={isDeleteDialogOpen} onClose={()=>setIsDeleteDialogOpen(false)} redirectLink={'/app/vehicle-type'}/>
    </Box>
   )
  )
}

export default VehicleForm;