import React,{useState} from 'react'
import {Box} from "@mui/material";
import AutocompleteField from '../../../../../../../component/AutocompleteField';
import { useNavigate } from 'react-router';
import BtnPrimary from '../../../../../../../component/PrimaryButton';
import BtnSecondary from '../../../../../../../component/SecondaryButton';
import ErrorMsg from '../../../../../../../component/ErrorMsg';

const Type = () => {

  const navigate = useNavigate();
  const [type,setType]=useState("");
  const [error,setError]=useState("");
  const typeOptions = ["Basic","House Rent Allowance","Dearness Allowance","Conveyance Allowance","Bonus","Commission",
    "Children Education Allowance","Transport Allowance","Helper Allowance","Travelling Allowance","Uniform Allowance",
    "Daily Allowance","City Compensatory Allowance","Overtime Allowance","Telephone Allowance","Fixed Medical Allowance",
    "Project Allowance","Food Allowance",""
  ];

  const handleSave = () =>{
    if(!type){
        setError("Required*")
        return;
    }
    navigate("form",{state:{type}});
  }

  return (
    <Box>
        <AutocompleteField
            label="Earning Type"
            width="48%"
            required={true}
            value={type}
            options={typeOptions}
            onChange={(e, value) => {
                           setType(value)
                           setError("");
                      }}
        />
        <ErrorMsg>{error}</ErrorMsg>
        <Box sx={{display:"flex", gap: "1.25rem", marginBlock: "1.875rem"}}>
                <BtnPrimary 
                    onClick={handleSave}
                    sx={{ borderRadius: "10px", width: "5.68rem", height: "3.125rem" }}>
                        Next
                </BtnPrimary>
                <BtnSecondary 
                    onClick={() => navigate(-1)}
                    sx={{ borderRadius: "10px", width: "5.68rem", height: "3.125rem" }}>
                        Cancel
                </BtnSecondary>
        </Box>
    </Box>
  )
}

export default Type