import React from 'react';
import { Dialog,Box, Avatar} from '@mui/material';
import { styled } from '@mui/material/styles'; 
import BtnPrimary from "./PrimaryButton";
import GreenTick from "../assets/images/green-success.png";
import { useNavigate } from 'react-router';

const TextBox = styled(Box)({
    display: "block",
});

function SaveDialog({open, onClose, redirectLink, resendInfo}) {
  
  const navigate = useNavigate();

  const handleOk = () =>{
    resendInfo?onClose():navigate(redirectLink);
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: "12px",
          boxShadow: "0px 4px 10px 0px #0000001A",
          padding: "1.5rem",
          display:"flex",
          flexDirection:"column",
          alignItems:"center",
          gap:"1.5rem"
        }
      }}
    >
        
            <Avatar src={GreenTick} alt="icon" sx={{height:"3rem", width:"3rem"}}/>
            <TextBox sx={{fontWeight:"500", fontSize: "1.125rem", lineHeight: "1.5rem"}}>
                Success!!
            </TextBox>
            <TextBox sx={{fontSize: "1rem", lineHeight: "1.5rem", color: "#6B6C7E" }}>
              {
                resendInfo?`Onboarding Invitation has been resent successfully to`:
                " Your changes have been saved successfully"
              }
              <TextBox sx={{fontSize: "1rem", lineHeight: "1.5rem", color: "#0081ff", textAlign:"center" }}>
                {resendInfo ? resendInfo : null}
              </TextBox>
            </TextBox>
           <BtnPrimary onClick={handleOk} sx={{width:"6.25rem", height: '3rem', padding: '1.25rem', borderRadius: '10px' }}>
                 Ok
           </BtnPrimary>
    </Dialog>
  );
}

export default SaveDialog;

