import * as React from "react";
import { createSvgIcon } from "@mui/material";

export default function SvgIconChildren(props) {
  const { color } = props;
  const CalenderSetting = createSvgIcon(
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon/Solid/view-grid">
        <g id="Icon">
          <path d="M8 6V5C8 3.34315 9.34315 2 11 2H13C14.6569 2 16 3.34315 16 5V6M2 10.3475C2 10.3475 5.11804 12.4244 9.97767 12.9109M22 10.3475C22 10.3475 18.882 12.4244 14.0223 12.9109M6 22H18C20.2091 22 22 20.2091 22 18V10C22 7.79086 20.2091 6 18 6H6C3.79086 6 2 7.79086 2 10V18C2 20.2091 3.79086 22 6 22Z" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
          <path d="M14 12.16V13.16C14 13.17 14 13.17 14 13.18C14 14.27 13.99 15.16 12 15.16C10.02 15.16 10 14.28 10 13.19V12.16C10 11.16 10 11.16 11 11.16H13C14 11.16 14 11.16 14 12.16Z" stroke="#16151C" strokeWidth={color} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
      </g>
    </svg>
  );

  return <CalenderSetting />;
}
