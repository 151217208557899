import React from "react";
import { Box } from "@mui/material";
import { Outlet } from "react-router";

function ShiftCreation() {

  return (
     <Box sx={{width:"100%"}}>
        <Outlet/>
     </Box>
      
  );
}

export default ShiftCreation;
