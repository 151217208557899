import * as React from "react";
import { createSvgIcon } from "@mui/material";

export default function SvgIconChildren(props) {
  const { color } = props;
  const HolidaySetting = createSvgIcon(
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon/Solid/view-grid">
        <g id="Icon">
        <path d="M4 6C4 3.79086 5.79086 2 8 2H15.3431C16.404 2 17.4214 2.42143 18.1716 3.17157L20.8284 5.82843C21.5786 6.57857 22 7.59599 22 8.65685V18C22 20.2091 20.2091 22 18 22H8C5.79086 22 4 20.2091 4 18V6Z" stroke={color} strokeWidth="1.5" strokeLinejoin="round"/>
        <path d="M9 7L17 7" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
        <path d="M9 12H17" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
        <path d="M9 17H13" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
        </g>
      </g>
    </svg>
  );

  return <HolidaySetting />;
}
