import React, { useEffect } from 'react';
import {apiKey} from "./googleMap/Helper";

function convertCoordinates(arrayOfCoordinates) {
    return arrayOfCoordinates.map(({ latitude, longitude }) => ({
      lat: latitude,
      lng: longitude
    }));
} 

const MapComponent = ({startLocation,endLocation,middleLocations, defaultColor, defaultZoom}) => {
  const newStartLocation = startLocation ? convertCoordinates(startLocation) : [];
  const newEndLocation = endLocation ? convertCoordinates(endLocation) : [];
  const newMiddleLocations = middleLocations ? 
  middleLocations.map(location => convertCoordinates(location)[0]) : [];

  const Locations = newStartLocation.concat(newMiddleLocations,newEndLocation);

  useEffect(() => {
    const initMap = () => {
      const map = new window.google.maps.Map(document.getElementById("map"), {
        zoom: (defaultZoom || 12),
        center: Locations[0],
        mapTypeId: "terrain",
      });
      const flightPlanCoordinates = Locations;
      const flightPath = new window.google.maps.Polyline({
        path: flightPlanCoordinates,
        geodesic: true,
        strokeColor: (defaultColor||"#0081FF"),
        strokeOpacity: 1.0,
        strokeWeight: 2,
      });
    
      flightPath.setMap(map);
    };

    if (!window.google) {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=initMap&v=weekly&channel=2`;
      script.async = true;
      document.body.appendChild(script);
      script.onload = initMap;
    } else {
      initMap();
    }
  }, [startLocation]);

  return <div id="map" style={{ height: '300px' }}></div>;
};

export default MapComponent;
