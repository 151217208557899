import React, {useState, useEffect} from 'react';
import TopBar from '../../../../../../../component/TopBar';
import MainBox from '../../../../../container/MainBox';
import {useLocation, useNavigate} from "react-router-dom";
import {Box, CircularProgress} from '@mui/material';
import ActiveBar from '../../../../../../../component/PolicyNavbarUpdated';
import BtnPrimary from '../../../../../../../component/PrimaryButton';
import FormField from '../../../../../../../component/Formfield';
import TypeBtn from '../../../../../../../component/AddRemoveButton';
import SuccessDialog from '../../../../../../../component/SuccessDialog';
import ErrorMsg from '../../../../../../../component/ErrorMsg';
import { getOrgEmployees } from '../../../../../../../services/globalData';
import { getAttendancePolicyById, updateAttendancePolicy } from '../../../../../../../services/attendancePolicy';

const AttendancePolicyRules = () => {
 
  const navigate = useNavigate();
  const [loading,setLoading] = useState(false);
  const [apiCall,setApiCall] = useState(false);
  const [policyId,setPolicyId] = useState("");
  const [approver, setApprover] = useState([""]);
  const [errorMsg,setErrorMsg] = useState("");
  const {state} = useLocation();
  const [isSuccessDialogOpen,setIsSuccessDialogOpen] = useState(false);
  const approverOptions = getOrgEmployees().map(employee => ({
    name: employee.fname+" "+employee.lname,
    value: employee._id
  }));

  console.log(state);

  useEffect(() => {
    if (state) {
      setPolicyId(state.policyId);
      fetchPolicyData(state.policyId); 
    } 
  }, [state]);

  const fetchPolicyData = async (policyId) => {
    setLoading(true);
    try {
        const res = await getAttendancePolicyById(policyId); 
        console.log(res);
        setApprover(res?.data?.approverIds); 
    }catch (error) {
        console.error('Error fetching policy data:', error);
    }
    setLoading(false);
  };

  const handleAddApproval = () =>{
    setApprover([...approver,""])
  }

  const handleRemoveApprover = (index) => {
    let approverList = [...approver];
    approverList.splice(index, 1);
    setApprover(approverList);
  };

  const handleApproverChange = (index, value) => {
    const updatedApprover = [...approver];
    updatedApprover[index] = value;
    setApprover(updatedApprover);
  };

  const handleNextClick = async() => {
    setApiCall(true);
    try{
      console.log(policyId,{approverIds:approver});
      const res = await updateAttendancePolicy(policyId,{approverIds:approver});
      console.log(res);
      if(res.success){
        setIsSuccessDialogOpen(true);
      }else{
        setErrorMsg("Select Approver to proceed");
      }
    }catch(error){
      console.log(error.response);
    }
    setApiCall(false);
};

  return ( 
    <Box sx={{width:"100%", paddingBlock:"1rem"}}>
      <TopBar 
        MainHeading={"Attendance Policy"}
        breadcrumbs={[{label:"Attendance", href:""},{label:"Policy Details", href:""},{label:"Add Category", href:""},{label:"Assign Policy", href:""},{label:"Policy Rules", href:""}]}
      />
      <MainBox sx={{ padding: "1.8125rem 1.25rem", height:"100%" }}>
          <Box sx={{width:"100%", height:"100%"}}>
            <Box sx={{display:"flex", justifyContent:"space-between"}}>
                  <ActiveBar active={["Policy Details","Add Category","Assign Policy","Rules"]} value="80"/>
            </Box>
            {loading?(
              <Box sx={{width:"100%", height:"100%",display:"flex", justifyContent:"center"}}>
                <CircularProgress/>
              </Box>
            ):(
            <>
              <Box sx={{marginTop:"2rem", paddingBlock:"1rem"}}>
                  <Box sx={{fontWeight:"500", fontSize: "1rem",lineHeight: "1.5rem", color:"#818181",marginBottom:"2rem"}}>
                    Approval Chain
                  </Box>
                  <Box>
                  {
                      approver?.map((item,index)=>(
                        <Box key={index} 
                            sx={{ width: "100%", 
                                  display: "flex",  
                                  marginBottom: "1rem", 
                                  justifyContent:"space-between",
                                  padding:"1.125rem 1.875rem",
                                  borderRadius:"10px",
                                  border:"1px solid #A2A1A833"
                                }}
                        >
                              <FormField
                                  label="Employee Name"
                                  type="select"
                                  options={approverOptions}
                                  value={item}
                                  onChange={(e) => handleApproverChange(index, e.target.value)}
                              />
                              <TypeBtn type="remove" onClick={()=>handleRemoveApprover(index)}>
                                      Remove 
                              </TypeBtn>
                        </Box>
                      ))
                  }
                  <TypeBtn onClick={handleAddApproval} type="add">
                      Add Approver
                  </TypeBtn>
                  <ErrorMsg>
                    {errorMsg}
                  </ErrorMsg>
                </Box>
              </Box>
              <Box sx={{display:"flex", justifyContent:"end", marginTop:"1.875rem"}}> 
                  <BtnPrimary 
                  onClick={handleNextClick} 
                  sx={{width:"10rem", height:"3.125rem", borderRadius:"10px"}}
                  disabled={apiCall}
                  >
                  {apiCall?<CircularProgress/>:"Save Policy"}
                  </BtnPrimary>
              </Box>
              <SuccessDialog 
                    open={isSuccessDialogOpen} 
                    onClose={()=>setIsSuccessDialogOpen(false)} 
                    message={`Policy created successfully!`} 
                    addNewLink={"/app/attendance-policy/new/setting"} 
                    addNewText="Create New Policy" 
                    viewAllLink={"/app/attendance-policy/listing"} 
                    viewAllText="View All Policies"
              />
            </>
            )}
          </Box>
      </MainBox> 
    </Box>    
  )
}

export default AttendancePolicyRules;
