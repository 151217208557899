import * as React from "react";
import { createSvgIcon } from "@mui/material";

export default function SvgIconChildren(props) {
  const { color } = props;
  const Briefcase= createSvgIcon(
    <svg
      width="14"
      height="18"
      viewBox="0 -3 14 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon/Solid/view-grid">
        <g clip-path="url(#clip0_13942_23783)">
            <path d="M6.66406 5.0013V4.16797C6.66406 2.78726 7.78335 1.66797 9.16406 1.66797H10.8307C12.2114 1.66797 13.3307 2.78726 13.3307 4.16797V5.0013M1.66406 8.62421C1.66406 8.62421 4.26243 10.355 8.31212 10.7604M18.3307 8.62421C18.3307 8.62421 15.7324 10.355 11.6827 10.7604M4.9974 18.3346H14.9974C16.8383 18.3346 18.3307 16.8423 18.3307 15.0013V8.33464C18.3307 6.49369 16.8383 5.0013 14.9974 5.0013H4.9974C3.15645 5.0013 1.66406 6.49369 1.66406 8.33464V15.0013C1.66406 16.8423 3.15645 18.3346 4.9974 18.3346Z" stroke={color} stroke-width="1.5" stroke-linecap="round"/>
            <path d="M11.6693 10.1341V10.9674C11.6693 10.9758 11.6693 10.9758 11.6693 10.9841C11.6693 11.8924 11.6609 12.6341 10.0026 12.6341C8.3526 12.6341 8.33594 11.9008 8.33594 10.9924V10.1341C8.33594 9.30078 8.33594 9.30078 9.16927 9.30078H10.8359C11.6693 9.30078 11.6693 9.30078 11.6693 10.1341Z" stroke={color} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_13942_23783">
            <rect width="20" height="20" fill="white"/>
            </clipPath>
            </defs>
      </g>
    </svg>
  );

  return <Briefcase/>;
}
