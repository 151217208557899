import React, { Children, useState } from "react";
import { Avatar, Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import Topbar from "../../../../../component/TopBar";
import MainBox from "../../../container/MainBox";
import UpArrow from "../../../../../assets/icons/up-arrow.svg";
import Laptop from "../../../../../assets/icons/laptop.svg";
import Spark from "../../../../../assets/icons/spark.svg";
import RequestUpdatesTable from "./RequestUpdatesTable";
import Calendar from "../../../../../component/Calendar";
import ProgressBar from '../../../../../component/ProgressBar';
import TV from '../../../../../assets/icons/tv.svg'
import CalendarIcon from '../../../../../assets/icons/calendar-dash.svg';
import FileIcon from '../../../../../assets/icons/file.svg';
import DetailsRequest from "./DetailsRequest";
import LeaderBoard from "./LeaderBoard";
import TableView from "./TableView";
import CardItem from "../../../../../component/CardItem";

function EmployeeAttendanceDashboard() {
  
  const [remark,setRemark] = useState("on time");
  const cardItems = ["1","2","3"];
  const [day,setDay] = useState("yesterday");
  const [date,setDate] = useState("date");
  const [view, setView] = useState('calendar');
  const [selectedDate,setSelectedDate] = useState(null);

  const handleView = (event, newView) => {
    setView(newView);
  };

  return (
     <Box sx={{width:"100%"}}>
        <Topbar MainHeading={"Attendance"} SubHeading={"Attendance > My Attendance"}/>
        <MainBox sx={{ padding: "2.625rem", height:"100%" }}>
              <Box sx={{width:"100%", display:"flex", justifyContent:"space-between", marginBottom:"0.625rem"}}>
                  <Box sx={{fontWeight:"500", fontSize:"1.5rem", lineHeight:"2.25rem"}}>
                    My Attendance
                  </Box>
                  <Box sx={{fontSize:"1rem", lineHeight:"1.25rem", color:"#5C5C5C"}}>
                    You came {remark} today.
                  </Box>
              </Box>
              <Box sx={{width:"100%", display:"flex", justifyContent:"space-between", gap: "1.75rem"}}>
                  <CardItem icon={Laptop} Heading={"Total work hours yesterday"} subHeading={"June 14, 2024"}>
                    <Box sx={{fontWeight:"600", fontSize:"1.875rem", display:"flex", gap:"0.5rem"}}>
                        {} <Box sx={{color:"#0081FF"}}>hr</Box> {} <Box sx={{color:"#0081FF"}}>min</Box>
                    </Box>
                    <Box sx={{fontSize:"0.75rem",lineHeight:"1.125rem", color:"#6B7280", marginTop:"0.5rem", display:"flex", justifyContent:"space-between"}}>
                       You took 30 min break 
                    </Box>
                  </CardItem>
                  <CardItem icon={UpArrow} Heading={"Today's Timeline"} subHeading={"June 14, 2024"}>
                    <Box sx={{marginTop:"2.5rem"}}>
                       <ProgressBar/>
                    </Box>
                    <Box sx={{fontSize:"0.75rem",lineHeight:"1.125rem", color:"#6B7280", marginTop:"0.5rem", display:"flex", justifyContent:"space-between"}}>
                        <Box>Duration:</Box>
                        <Box>Break:</Box>
                    </Box>
                  </CardItem>
                  <CardItem icon={Spark} Heading={"Punctuality Rate"}>
                    <Box sx={{fontWeight:"600", fontSize:"1.875rem", }}>
                      Avg : 70%
                    </Box>
                    <Box sx={{fontSize:"0.75rem",lineHeight:"1.125rem", color:"#6B7280", marginTop:"0.5rem"}}>
                        Keep up ! You are on time mostly. 
                    </Box>
                  </CardItem>
              </Box>
              <Box sx={{display:"flex", alignItems:"stretch", gap:"1.187rem", marginBlock:"1.125rem"}}>
                    <MainBox sx={{flex:"1", padding:"1.5rem"}}>
                      <Box sx={{display:"flex", gap:"1.1875rem", marginBottom:"1rem"}}>
                        <Box sx={{flex:1}}>
                            <Box sx={{display:"flex", gap:"0.625rem"}}>
                                <Avatar src={TV} style={{ width: '1.5rem', height: '1.5rem' }}/>
                                <Box sx={{fontWeight:"300", fontSize:"1rem", lineHeight:"1.5rem", color:"#0081FF", marginBottom:"0.56rem"}}>
                                    Monthly Preview
                                </Box>
                            </Box>
                            <ProgressBar value={42}/>
                        </Box>
                        <ToggleButtonGroup
                            value={view}
                            exclusive
                            onChange={handleView}
                        >
                            <ToggleButton value="calendar" sx={{width:'2.5rem', height:'2rem'}}>
                              <Avatar src={CalendarIcon} style={{ width: '1.4rem', height: '1.5rem' }}/>
                            </ToggleButton>
                            <ToggleButton value="table" sx={{width:'2.5rem', height:'2rem'}}>
                              <Avatar src={FileIcon} style={{ width: '1.5rem', height: '1.5rem' }}/>
                            </ToggleButton>
                        </ToggleButtonGroup>
                      </Box>
                      {
                         view==="calendar"?
                         <Calendar selectedDate={selectedDate} setSelectedDate={setSelectedDate}/>:
                         <TableView/>
                      }
                    </MainBox>
                    <MainBox sx={{flex:"1", padding:"1.5rem"}}>
                       {  
                          selectedDate?
                          <DetailsRequest selectedDate={selectedDate} setSelectedDate={setSelectedDate}/>:
                          <LeaderBoard selectedDate={selectedDate} setSelectedDate={setSelectedDate}/>
                       }
                    </MainBox>
              </Box>
              <MainBox sx={{padding:"1.5rem"}}>
                  <RequestUpdatesTable/>
              </MainBox>
        </MainBox>
     </Box>
      
  );
}

export default EmployeeAttendanceDashboard;
