import React, { useEffect, useState } from "react";
import {Box} from "@mui/material";
import Topbar from "../../../../../../component/TopBar";
import { default as CSVIcon } from '../../../../../../assets/icons/elements.png';
import DateComponent from "../../../../../../component/DatePicker";
import { SearchBox } from "../../../../../../component/SearchBox";
import BtnFilter from "../../../../../../component/FilterButton";
import Department from "../../../../../../component/Department";
import { getAllDaySelfAttendance } from "../../../../../../services/facialRecognition";
import { PolicyTableHeadings } from "../../../../../../styles/PolicyTableHeadings";
import PaginationTable from "../../../../../../component/PaginationTable";
import { isPointInFence } from "../../../../../../utils/isPointInFence";
import { formatTimeString } from "../../../../../../utils/formatTimeString";
import { getDuration } from "../../../../../../utils/getDuration";
import { calculateTime } from "../../../../../../utils/calculateTime";
import { PolicyTableCell } from "../../../../../../styles/PolicyTableCell";

export default function EmployeeAttendance(){
  const [loading,setLoading] = useState(false);
  const [dateDialogOpen, setDateDialogOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(false);
  const [data,setData] = useState([]);
  const [fence,setFence] = useState();

  useEffect(()=>{
    fetchAttendance();
  },[])

  const fetchAttendance = async () => {
    setLoading(true);
    try {
      const res = await getAllDaySelfAttendance();
      setData(res?.data?.reverse());
      console.log(res);
    } catch (error) {
      console.log(error.response);
    }
    setLoading(false);
  };

  console.log(isPointInFence(fence, "28.5109499", "77.0719872"));

  const headings = [
    { label: "Date", style: {...PolicyTableHeadings, width:"4.25rem", borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px" } },
    { label: "Status", style: {...PolicyTableHeadings,  width:"7.5rem"} },
    { label: "Location", style: {...PolicyTableHeadings, width:"7.5rem"} },
    { label: "Clock In", style: {...PolicyTableHeadings, width:"8.75rem"} },
    { label: "Clock Out", style: {...PolicyTableHeadings, width:"8.75rem"} },
    { label: "Break Time", style: {...PolicyTableHeadings, width:"8.75rem"} },
    { label: "Total Time", style: {...PolicyTableHeadings, width:"8.75rem"} },
    { label: "Effective Hrs", style: { ...PolicyTableHeadings,width:"8.75rem", borderTopRightRadius: "12px", borderBottomRightRadius: "12px" } }
  ];


  return (
    <Box sx={{width:"100%", paddingBlock:"1rem"}}>
      <Topbar MainHeading={`My Attendance`}/>
      <Box sx={{width:"100%", paddingBlock:"0.74rem"}}>
          <Box sx={{width:"100%", display:"flex", justifyContent:"space-between", gap:"1rem", marginBottom:"1.5rem"}}>
             <SearchBox styles={{flex:1}}/>
             <Department styles={{flex:1}}/>
             <DateComponent
                value={selectedDate}
                isDialogOpen={dateDialogOpen}
                openDialog={() => setDateDialogOpen(true)}
                closeDialog={() => setDateDialogOpen(false)}
                onSelect={(date) => {
                          setSelectedDate(date)
                          setDateDialogOpen(false)
                          }}
             />
             <BtnFilter sx={{padding: '1rem 1.5rem', borderRadius: '12px'}} endIcon={CSVIcon}>
              Export CSV
             </BtnFilter>
          </Box>
          <PaginationTable
                rows={data}
                headings={headings}
                loading={loading}
                noRecordMessage={"No Record Found"}
                renderCell={(row) => (
                  <>
                    <PolicyTableCell>{row?.dayString}</PolicyTableCell>
                    <PolicyTableCell >{"Present"}</PolicyTableCell>
                    <PolicyTableCell>
                      {isPointInFence(fence, row?.arrivalLat, row?.arrivalLong)?"In Office":"Remote"}
                    </PolicyTableCell>
                    <PolicyTableCell>{formatTimeString(row?.createdAt)}</PolicyTableCell>
                    <PolicyTableCell>{!isNaN(Number(row?.departureTime)) ? calculateTime(row?.departureTime) : "--"}</PolicyTableCell>
                    <PolicyTableCell>{getDuration(row?.breakHours)}</PolicyTableCell>
                    <PolicyTableCell>{getDuration(row?.totalHours)}</PolicyTableCell>
                    <PolicyTableCell>{getDuration(row?.effectiveHours)}</PolicyTableCell>
                  </>
                )}
           />
      </Box>
    </Box>
  );
};
