import React from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Paper, CircularProgress,} from "@mui/material";

const StyledLink = styled(Link)({
  color:"inherit",
  textDecoration:"none"
})

const miscStyle = {
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "150px",
}

const PaginationTable = ({
  rows,
  headings,
  renderCell,
  customWidth,
  noRecordMessage = "",
  loading,
  link,
  path,
  id,
  tableStyle,
  paginationHide
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", boxShadow: "none", elevation: 0 }}>
        <TableContainer sx={tableStyle?{...tableStyle}:{}}>
          <Table sx={{  minWidth: customWidth || 550 }}>
            <TableHead>
              <TableRow>
                {headings?.map((heading, index) => (
                  <TableCell key={index} sx={heading.style}>
                    {heading.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {loading ? ( 
            <TableRow>
             <TableCell colSpan={headings?.length}>
             <Box sx={miscStyle}>
                <CircularProgress />
              </Box>
             </TableCell>
            </TableRow>
            ) : (
            <TableBody>
              {rows?.length > 0
                ? (rowsPerPage > 0
                    ? rows?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : rows
                  )?.map((row, rowIndex) => (
                    <TableRow 
                      hover 
                      tabIndex={-1} 
                      key={rowIndex}
                      component={link ? StyledLink : 'tr'}
                      to={link ? `${path}/${row._id || row[id]}` : null} 
                    >
                      {renderCell(row)}
                    </TableRow>
                  ))
                : <TableRow>
                    <TableCell colSpan={headings?.length}>
                       <Box sx={miscStyle}>
                          {noRecordMessage}
                        </Box>
                    </TableCell>
                  </TableRow>
                }
            </TableBody>
            )}
          </Table>
        </TableContainer>
        {!paginationHide && (
           <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
        )}
      </Paper>
    </Box>
  );
};

export default PaginationTable;
