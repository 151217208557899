import React, { Suspense } from "react";
import Spinner from "../component/Spinner";
const LoginPage = React.lazy(() => import("../app/recoverAccount"));

function AccountRecovery() {
  return (
    <>
      <Suspense fallback={<Spinner />}>
        <LoginPage />
      </Suspense>
    </>
  );
}

export default AccountRecovery;
