import React, {useState, useEffect} from 'react';
import { Box } from '@mui/material';
import HolidaySetting from "../assets/icons/HolidaySetting";
import GeneralSettings from "../assets/icons/GeneralSettings";
import AddDocument from "../assets/icons/AddDocument";
import Briefcase from "../assets/icons/Briefcase";
import ProgressBar from "./ProgressBar";

const ActiveBar = ({ active, value, page}) => {

  const policyItems = [{value:"Policy Details",icon:GeneralSettings},
                 {value:"Add Category",icon:AddDocument},
                 {value:"Assign Policy",icon:Briefcase},
                 {value:"Rules",icon:HolidaySetting}
               ]

  const shiftItems = [{value:"Shift Details",icon:GeneralSettings},
    {value:"Assign Shift",icon:Briefcase},
  ]

  const shiftRotationItems = [{value:"Shift",icon:GeneralSettings},
    {value:"Frequency",icon:AddDocument},
  ]

  const payGroupItems = [{value:"Pay Group",icon:GeneralSettings}];

  const payGroupDetailsItems = [{value:"Pay Group Details",icon:GeneralSettings}];

  const [items, setItems] = useState(policyItems);

  useEffect(() => {
    switch (page) {
      case "shift":
        setItems(shiftItems);
        break;
      case "shift-rotation":
        setItems(shiftRotationItems);
        break;
      case "pay-group":
        setItems(payGroupItems);
        break;
      case "pay-group-details":
        setItems(payGroupDetailsItems);
        break;
      default:
        setItems(policyItems);
        break;
    }
  }, []);

  return (
    <Box sx={{width:"80%",paddingBlock:"0.5rem"}}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              minHeight: "2.18rem",
              gap: "1.25rem",
            }}
          >
            {
                  items.map((item)=>{
                      const {value,icon:Icon}=item;

                      return(
                        <Box sx={{
                          display:"flex", 
                          alignItems:"center",
                          gap:"0.625rem",
                          fontWeight:"300",
                          fontSize: "1rem",
                          lineHeight: "1.5rem",
                          color: active.includes(value) ? "#0081FF" : "#00000080",
                        }}>
                            <Icon color={active.includes(value)? "#0081FF" : "#00000080"}/>
                            <Box>
                              <strong>{value}</strong>
                            </Box>
                      </Box> 
                      )
                  })
              }
          </Box>
          <ProgressBar value={value}/>
    </Box>
  );
};

export default ActiveBar;
