import React,{useState} from 'react'
import {Box} from '@mui/material';
import BtnPrimary from '../../../../../../component/PrimaryButton';
import BtnSecondary from '../../../../../../component/SecondaryButton';
import FormField from '../../../../../../component/Formfield';
import DateField from '../../../../../../component/DateField';
import CheckboxField from '../../../../../../component/CheckboxField';

const MachineForm = () => {

    const [softwareFormData, setSoftwareFormData] = useState({
        name:"",
        manufacturer:"",
        type:"",
        version:"",
        isAddNewLicense:false,
        vendor:"",
        software:"",
        key:"",
        licensed:false,
        noOfLicense:"",
        purchasedOn:"",
        expiredOn:""
    });
    const [isPurchasedOnDialogOpen, setIsPurchasedOnDialogOpen] = useState(false);
    const [isExpiredOnDialogOpen, setIsExpiredOnDialogOpen] = useState(false);

  const handleChange = (field, value) => {
    setSoftwareFormData(prevState => ({
            ...prevState,
            [field]: value 
    }));
  };

  return (
    <Box sx={{ width: "100%", padding:"1.875rem 1.25rem"}}>
        <Box sx={{fontWeight:"500", fontSize:"1.375rem", lineHeight:"2.06rem", marginBottom:"1.5rem"}}>
            Add New Software
        </Box>
        <Box>
            <FormField
                label="Name"
                type="text"
                value={softwareFormData.name}
                onChange={(e) => handleChange('name', e.target.value)}
            />
            <FormField
                label="Manufacturer"
                type="text"
                value={softwareFormData.manufacturer}
                onChange={(e) => handleChange('manufacturer', e.target.value)}
            />
            <FormField
                label="Type"
                type="select"
                options={["Productivity","Graphics","Support","Accounting","Development"]}
                value={softwareFormData.type}
                onChange={(e) => handleChange('type', e.target.value)}
            />
            <FormField
                label="Version"
                type="text"
                value={softwareFormData.version}
                onChange={(e) => handleChange('version', e.target.value)}
            />
             <CheckboxField
              label="Add New License"
              checked={softwareFormData.isAddNewLicense}
              onChange={(isChecked) => handleChange('isAddNewLicense', isChecked)}
              labelStyle={{ fontSize: "1rem", lineHeight: "1.5rem", color: "#818181" }}
            />

            {softwareFormData.isAddNewLicense && (
                <Box>
                    <Box sx={{ fontWeight:"500", fontSize:"1.375rem", lineHeight:"2.06rem", marginBottom:"1.5rem"}}>
                        Add New License
                    </Box>
                    <Box>
                        <FormField
                            label="Vendor"
                            type="text"
                            value={softwareFormData.vendor}
                            onChange={(e) => handleChange('vendor', e.target.value)}
                        />
                        <FormField
                            label="Software"
                            type="text"
                            value={softwareFormData.software}
                            onChange={(e) => handleChange('software', e.target.value)}
                        />
                        <FormField
                            label="Key"
                            type="text"
                            value={softwareFormData.key}
                            onChange={(e) => handleChange('key', e.target.value)}
                         />
                         <CheckboxField
                            label="Free"
                            checked={!softwareFormData.licensed}
                            onChange={(isChecked) => handleChange('licensed', !isChecked)}
                            labelStyle={{ fontSize: "1rem", lineHeight: "1.5rem", color: "#818181" }}
                        />
                        <CheckboxField
                            label="Licensed"
                            checked={softwareFormData.licensed}
                            onChange={(isChecked) => handleChange('licensed', isChecked)}
                            labelStyle={{fontSize: "1rem", lineHeight: "1.5rem", color: "#818181" }}
                        />
                        {
                            softwareFormData.licensed && (
                                <Box>
                                    <FormField
                                        label="Number Of Licenses"
                                        type="select"
                                        options={[]}
                                        value={softwareFormData.noOfLicense}
                                        onChange={(e) => handleChange('noOfLicense', e.target.value)}
                                    />
                                    <DateField
                                        label="Purchased On"
                                        value={softwareFormData.purchasedOn}
                                        isDialogOpen={isPurchasedOnDialogOpen}
                                        openDialog={() => setIsPurchasedOnDialogOpen(true)}
                                        closeDialog={() => setIsPurchasedOnDialogOpen(false)}
                                        onSelect={(date) => {
                                            handleChange('purchasedOn', date);
                                            setIsPurchasedOnDialogOpen(false);
                                        }}
                                    />
                                    <DateField
                                        label="Expire On"
                                        value={softwareFormData.expiredOn}
                                        isDialogOpen={isExpiredOnDialogOpen}
                                        openDialog={() => setIsExpiredOnDialogOpen(true)}
                                        closeDialog={() => setIsExpiredOnDialogOpen(false)}
                                        onSelect={(date) => {
                                            handleChange('expiredOn', date);
                                            setIsExpiredOnDialogOpen(false);
                                        }}
                                    />
                                </Box>
                            )
                        }
                    </Box>
                </Box>
            )}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "end", gap: "1.25rem", marginBlock: "1.875rem" }}>
                <BtnPrimary sx={{ borderRadius: "10px", width: "5.68rem", height: "3.125rem" }}>
                    Submit
                </BtnPrimary>
                <BtnSecondary sx={{ borderRadius: "10px", width: "5.68rem", height: "3.125rem" }}>
                    Cancel
                </BtnSecondary>
        </Box>
    </Box>
  )
}

export default MachineForm