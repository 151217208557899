import React from 'react';
import styled from "@emotion/styled";
import { Box, TextField, InputAdornment } from '@mui/material';
import CalenderIcon from "../assets/icons/calendar-dash.svg";
import HolidayDate from "./DateSelectDialog";
import ErrorMsg from './ErrorMsg';

const TextLabel = styled(Box)({
    fontSize: '1rem',
    lineHeight: '1.5rem',
    color: '#818181',
    marginBottom:"4px"
});
  
  const InputBoxStyle = {
    height: '3rem',
    borderRadius: '10px',
    fontWeight:"300",
    fontSize: '1rem',
    lineHeight: '1.5rem',
    color:'#16151CCC'
}

const DateField = ({ label, value,fullwidth, isDialogOpen, openDialog, closeDialog, onSelect , disabled, validation, past}) => {
  return (
    <Box sx={{ width: fullwidth?'100%':'48%',marginBottom: '1.125rem' }}>
      <TextLabel>{label}</TextLabel>
      <TextField
        placeholder="YYYY-MM-DD"
        value={value || ''}
        onClick={openDialog}
        variant="outlined"
        disabled={disabled}
        fullWidth
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <img src={CalenderIcon} alt="Calendar" />
            </InputAdornment>
          ),
        }}
        sx={{
          "& .MuiInputBase-root": InputBoxStyle,
          "& .MuiOutlinedInput-notchedOutline": {
            border: '1px solid #A2A1A833'
          }
        }}
      />
      <ErrorMsg>{validation}</ErrorMsg>
      <HolidayDate open={isDialogOpen} onClose={closeDialog} onSelect={onSelect} past={past}/>
    </Box>
  );
};

export default DateField;
