import React, { useState, useEffect } from 'react';
import { Box,TableCell } from '@mui/material';
import PaginationTable from '../../../../../../component/PaginationTable';
import TopBar from "../../../../../../component/TopBar";
import MainBox from "../../../../container/MainBox";
import { getAllExpense} from '../../../../../../services/expense';
import { getEmployeeNames } from '../../../../../../utils/getEmployeeNames';
import TableHeader from '../../../../../../component/TableHeader';
import ExpenseInfo from './ExpenseInfo'

const CustomTableCell = {
  fontFamily:"Poppins",
  fontWeight: "300",
  fontSize: "1rem",
  color: "#000000",
  lineHeight: "1.5rem",
  padding: "1rem",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis", 
  overflow:"hidden"
};


const Headings = {
  fontFamily:"Poppins",
  fontWeight: "300",
  fontSize: "1rem",
  color: "#000000",
  lineHeight: "1.5rem",
  padding: "1.375rem 1rem",
  backgroundColor: "#F5F5F5",
  borderBottom: "none",
  textAlign:"center",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
}

const ExpenseDashboard = () => {
    const [loading, setLoading] = useState(true);
    const [date, setDate] = useState(new Date().toLocaleDateString('en-GB')); 
    const [order,setOrder] = useState("desc");
    const [data, setData] = useState([]);
    const [expenseId,setExpenseId] = useState("");
    const [open,setOpen] = useState(false);
    const [filteredData,setFilteredData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(()=>{
      filteredData.reverse();
    },[order]);

    useEffect(()=>{
      setFilteredData(data.filter((item)=>item.date===(new Date(date).toLocaleDateString('en-GB'))));
    },[date]);
    
    useEffect(()=>{
      setFilteredData(data.filter((item)=>(
        searchQuery===""?item:item?.employeeName?.toLowerCase()?.includes(searchQuery?.toLowerCase())
      )));
    },[searchQuery]);

      const headings = [
        { label: "Date", style: {...Headings, width:"6.43rem", borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px" } },
        { label: "Emp Name", style: {...Headings, width:"6.43rem", maxWidth: "6.43rem"} },
        { label: "Expense Id", style: {...Headings, width:"6.43rem"} },
        { label: "Category", style: {...Headings, width:"6.43rem"} },
        { label: "Amount", style: {...Headings, width:"6.43rem"} },
        { label: "Status", style: { ...Headings, width:"6.43rem", borderTopRightRadius: "12px", borderBottomRightRadius: "12px" } }
      ];


      useEffect(()=>{
        const fetchData = async () => {
          try {
            const res = await getAllExpense();
            console.log(res);
            if(res.success){
              const employeeIds = res.data.map(data => data.submitterId);
              const combinedData = await getEmployeeNames(res.data, employeeIds, "submitterId");
              console.log(combinedData);
              const dataTable = combinedData?.filter(item => item.type === 'ANY')?.map((item)=>({
                 date:(new Date(item.createdAt).toLocaleDateString('en-GB')),
                 employeeName:item.employee.fname+" "+item.employee.lname,
                 category:item.type,
                 expenseId:item._id,
                 amount:item.amount,
                 status:item.status
              }));
              const reversedDataTable = dataTable.reverse();
              setData(reversedDataTable);
              setFilteredData([...reversedDataTable]);
              setLoading(false);
          }
        }catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
          }
        };
      
        fetchData();
      },[])

      const handleRowClick = (id) => {
        setExpenseId(id);
        if(!open){
          setOpen(!open);
        }
      };

    return (
        <Box sx={{ width: "100%" }}>
            <TopBar MainHeading={"Expenses"} SubHeading={"Expenses"}/>
            <MainBox sx={{ padding: "1.875rem 1.25rem" }}>
            <Box></Box>
                 <TableHeader 
                   data={data} 
                   setDate={setDate} 
                   setOrder={setOrder}
                   searchQuery={searchQuery}
                   handleSearch={(value)=>setSearchQuery(value)}
                 />
                 <Box sx={{display:"flex", gap:"1rem", overflow:"hidden"}}>
                  <Box sx={{flex:1}}>
                  <PaginationTable
                      rows={filteredData}
                      headings={headings}
                      loading={loading}
                      noRecordMessage={"No Record Found"}
                      renderCell={(row) => (
                        <>
                          <TableCell 
                            align="center"
                            sx={{...CustomTableCell, width:"6.43rem",  maxWidth:"6.43rem"}}
                            >
                              {row.date}
                            </TableCell>
                          <TableCell 
                            align="center"
                            sx={{...CustomTableCell, width:"9rem", maxWidth: "9rem"}}
                          >
                              {row.employeeName}
                          </TableCell>
                          <TableCell 
                            align="center"
                            sx={{...CustomTableCell, width:"6.43rem", maxWidth: "6.43rem",":hover":{cursor:"pointer"}, color:"#008cc4",textDecoration:"underline"}} 
                            onClick={() => handleRowClick(row.expenseId)}
                          >
                              {row.expenseId}
                          </TableCell>
                          <TableCell align="center" sx={{...CustomTableCell, width:"6.43rem"}}>{row.category}</TableCell>
                          <TableCell align="center" sx={{...CustomTableCell, width:"6.43rem"}}>{row.amount}</TableCell>
                          <TableCell align="center" sx={{...CustomTableCell, width:"6.43rem"}}>
                                  <Box sx={{
                                            borderRadius:"4px", 
                                            padding:"4px 8px",
                                            textAlign:"center",
                                            backgroundColor: row.status === "pending" ? "#FFBE64" : (row.status === "rejected" ? "#E493931A" : "#0CBD7D"),
                                            color: row.status === "pending" ? "#212423" : (row.status === "rejected" ? "#E52E2E" : "#FFFFFF")
                                          }}>
                                      {row.status}
                                  </Box>
                          </TableCell>
                        </>
                      )}
                    />
                  </Box>
                  <Box sx={{
                          marginRight: open ? '0px' : '-350px',
                          transitionProperty: 'margin-right', 
                          transitionDuration: '0.5s',
                          transitionTimingFunction: 'ease-in-out', 
                        }}
                  >
                    <ExpenseInfo expenseId={expenseId} setOpen={setOpen}/>
                  </Box> 
                </Box>
            </MainBox>
        </Box>
    )
}

export default ExpenseDashboard;
