import React, {useState, useEffect} from 'react'
import styled from "@emotion/styled";
import {Box, CircularProgress} from '@mui/material';
import BtnPrimary from '../../../../../../../component/PrimaryButton';
import BtnSecondary from '../../../../../../../component/SecondaryButton';
import GoogleMapWithPolyline from '../../../../../../../component/GoogleMapPolyline';
import {getTripById } from '../../../../../../../services/trips';
import ActionDialog from '../../../../../../../component/ActionDialog';
import {approveExpense, getExpenseById, rejectExpense } from '../../../../../../../services/expense';
import CloseIcon from '@mui/icons-material/Close';
import { getOrgEmployees } from '../../../../../../../services/globalData';

const LabelBox = styled(Box)({
    fontSize: "0.875rem",
    color: "#5C5C5C",
    lineHeight: "1.5rem",
});

const ValueBox = styled(Box)({
    fontSize: "1rem",
    color: "#16151C",
    lineHeight: "1.5rem",
});

const TripInfo = ({tripId, expenseId, setOpen}) => {

const [tripDetails, setTripDetails] = useState([
    { label: "Trip ID", value: "" },
    { label: "Start Date", value: "" },
    { label: "End Date", value: "" },
    { label: "Total Distance", value: "" },
    { label: "Reporting Manager", value: "" },
    { label: "Trip Policy", value: "" },
    { label: "Vehicle Type", value: "" },
    { label: "Reason", value: "" }
  ]);
 const [loading, setLoading] = useState(false);
 const [startLocation,setStartLocation] = useState(null);
 const [endLocation,setEndLocation] = useState(null);
 const [middleLocations,setMiddleLocations] = useState(null);
 const [approveDialogOpen, setApproveDialogOpen] = useState(false);
 const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
 const [status,setStatus] = useState("");
 const [employee,setEmployee] = useState({});

  useEffect(() => {
      fetchTripDetails();
  }, [tripId]);

  const fetchTripDetails = async () => {
    setLoading(true);
    try {
      const res = await getTripById(tripId); 
      console.log(res);
      const res2 = await getExpenseById(expenseId); 
      console.log(res2); 
      const tripData = res?.data;
      const expenseData = res2?.data;
      const approvers = getOrgEmployees().filter(emp => expenseData?.policy?.approverIds?.includes(emp._id));      
      console.log(approvers);
      setStatus(expenseData?.status);
      setEmployee(getOrgEmployees().find(emp=>emp._id===expenseData?.submitterId));
      setTripDetails([
        { label: "Trip ID", value: tripData?.aliasId },
        { label: "Start Date", value: new Date(tripData?.startTime).toLocaleDateString('en-GB')},
        { label: "End Date", value: new Date(tripData?.endTime).toLocaleDateString('en-GB')},
        { label: "Total Distance", value: `${tripData?.distance || 0} KM` },
        { label: "Reporting Manager", value: approvers?.length > 0 
          ? approvers?.map(approver => `${approver?.fname} ${approver?.lname}`).join(', ')
          : '--'},
        { label: "Trip Policy", value: expenseData?.policy?.name},
        { label: "Vehicle Type", value: expenseData?.policyCateogary?.name },
        { label: "Reason", value: expenseData?.reason }
      ]);
      setStartLocation(tripData?.startLocation);
      setEndLocation(tripData?.endLocation);
      setMiddleLocations(tripData?.middleLocations);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setLoading(false);
  };


  const handleReject = () => {
    setRejectDialogOpen(true);
  };

  const handleApprove = async() => {
    setApproveDialogOpen(true);
  };

  const handleCloseRejectDialog = () => {
    setRejectDialogOpen(false);
  };
  
  const handleCloseApproveDialog = () => {
    setApproveDialogOpen(false);
  };

  return (
    loading?(
      <Box sx={{width:"25.5rem",display:"flex", justifyContent:"center", alignItems:"center", height:"100vh"}}>
        <CircularProgress/>
      </Box>
    ):(
    <Box sx={{width:"25.5rem", backgroundColor:"#F5F5F5",borderRadius:"12px",padding:"1.5rem"}}>
          <Box sx={{display:"flex",justifyContent:"space-between"}}>
              <Box sx={{fontWeight:"600",fontSize:"1.25rem",lineHeight:"1.875rem",color:"#16151C"}}>
                Trip Details
              </Box>
              <CloseIcon 
                fontSize='small' 
                onClick={()=>setOpen(false)} 
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}/>
          </Box>
          <Box sx={{display:"flex",justifyContent:"space-between",fontWeight:"300",fontSize:"0.875rem",lineHeight:"1.375rem"}}>
              <Box>
                  <Box sx={{color:"#16151C"}}>{employee?.fname+" "+employee?.lname}</Box>
                  <Box sx={{color:"#0081FF"}}>{employee?.designation}</Box>
              </Box>
              <Box sx={{
                        alignSelf:"end",
                        borderRadius:"4px", 
                        padding:"2px 8px",
                        textAlign:"center",
                        backgroundColor: status === "pending" ? "#FFBE64" : (status === "rejected" ? "#E493931A" : "#0CBD7D"),
                        color: status === "pending" ? "#212423" : (status === "rejected" ? "#E52E2E" : "#FFFFFF")
                      }}>
                  {status}
              </Box>
          </Box>
          <Box sx={{display:"flex", flexDirection:"column", gap:"1rem", marginTop:"1.5rem"}}>
                {tripDetails.map((item, index) => (
                    <Box
                      key={index}
                      sx={{ display: "flex", justifyContent: "space-between"}}
                    >
                      <LabelBox>{item?.label}</LabelBox>
                      <ValueBox>{item?.value}</ValueBox>
                    </Box>
                  ))}
          </Box>
          <Box>
            <GoogleMapWithPolyline 
                startLocation={startLocation}
                endLocation={endLocation}
                middleLocations={middleLocations}
            />
          </Box>
          {
            status==="pending"?
              <Box sx={{ width:"100%", display: "flex", gap: "1rem", marginBlock: "1.875rem" }}>
                <BtnSecondary onClick={handleReject} sx={{ borderRadius: "8px", height: "3rem", flex:"1" }}>
                    Reject
                </BtnSecondary>
                <BtnPrimary onClick={handleApprove} sx={{ borderRadius: "8px", height: "3rem",flex:"1" }}>
                    Approve
                </BtnPrimary>
              </Box>
            :null
          }
          <ActionDialog 
            type="approve" 
            open={approveDialogOpen} 
            onClose={handleCloseApproveDialog} 
            reviewApi={approveExpense}
            id={expenseId} 
            text="Approve Trip?"
          />
          <ActionDialog 
            type="reject" 
            open={rejectDialogOpen} 
            onClose={handleCloseRejectDialog} 
            reviewApi={rejectExpense}
            id={expenseId}
            text="Reject Trip?"
          />
    </Box>
   )
  )
}

export default TripInfo