import React from "react";
import { Box } from "@mui/material";

export default (props) => {
  const { controller } = props;

  const ActiveController = controller; 
  return (
    <Box sx={{width:"100%", display:"flex", direction:"column"}}>
        <ActiveController />
    </Box>
  );
};
