import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Box, TextField,InputAdornment, MenuItem,IconButton, Menu} from "@mui/material";
import BtnPrimary from "../../../../../../component/PrimaryButton";
import CustomSelect from "../../../../../../component/CollapsibleSelection";
import dayjs from "dayjs";
import HolidayDate from "../../../../../../component/DateSelectDialog";
import CalenderIcon from "../../../../../../assets/icons/calendar-dash.svg";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {createHolidays, getAllHolidays} from "../../../../../../services/holiday";
import { getLegalUnitList } from "../../../../../../services/globalData";

const TextLabel = styled(Box)({
    display:"block",
    fontWeight:"500",
    fontSize: "1rem",
    lineHeight: "1.5rem", 
    marginBottom: "0.75rem",
    color:"#16151C",
  });

const TextBox = styled(Box)({
  display: "block",
});

const InputBoxStyle = {
  height: "3rem",
  borderRadius: "4px",
  fontSize: "1rem",
  lineHeight: "1.5rem",
  color: "#3A3A3A",
}

const locationList = [
  'Pune',
  'Mumbai',
  'Delhi',
];

const HolidaySetting = (props) => {
  const [legalUnits,setLegalUnits] = useState([]);
  const [legalUnitOptions, setLegalUnitOptions] = useState([]);
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [startDateDialogOpen, setStartDateDialogOpen] = useState(false);
  const [endDate, setEndDate] = useState(null);
  const [endDateDialogOpen, setEndDateDialogOpen] = useState(false);
  const [holidays, setHolidays] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedHolidayIndex, setSelectedHolidayIndex] = useState(null);
  const [edit,setEdit] = useState(false);

  const onLocationSelect = (selectedOptions) => {
    setLegalUnits(selectedOptions);
  };

  const handleStartDateSelect = (date) => {
    setStartDate(date);
    setStartDateDialogOpen(false);
  };

  const handleEndDateSelect = (date) => {
    setEndDate(date);
    setEndDateDialogOpen(false);
  };

  const handleOpenMenu = (event, index) => {
    setAnchorEl(event.currentTarget);
    setSelectedHolidayIndex(index); 
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  
  const Holiday = {
     name,
     legalUnits: legalUnits.map(unit => unit.value),
     startDate: (new Date(startDate)).getTime(),
     endDate: (new Date(endDate)).getTime()
  }

  const handleAddHoliday = async() => { //function to create holiday
      try{
        console.log(Holiday);
       const res = await createHolidays(Holiday);
       console.log(res);
      }catch(error){
        console.log(error.response);
      }
      setLegalUnits([]);
      setName("");
      setStartDate(null);
      setEndDate(null);
      fetchHolidays();
  };

  const handleEditHoliday = () => {}
  const handleDeleteHoliday = () => {}

  const fetchHolidays = async() =>{  //function to fetch holiday list
      try{
        const res = await getAllHolidays();
        setHolidays(res.data);
        console.log(res);
      }catch(error){
        console.log(error.response);
      }
  }
  
  const fetchData = () => {
      const legalUnitData = getLegalUnitList();
      const formattedOptions = legalUnitData.map(unit => ({ value: unit._id, name: unit.name }));
      console.log(formattedOptions);
      setLegalUnitOptions(formattedOptions);
  };

  useEffect(()=>{
    fetchData();
    fetchHolidays();
  },[])

  return (
    <Box sx={{width:"100%", padding:"1rem 0", display:"flex", gap:"1.5rem"}}>
          <Box sx={{width:"50%", borderRadius:"8px",padding:"0.75rem",border:"1px solid #E8E8E8"}}>
                <Box sx={{marginBottom:"1.5rem"}}>
                    <TextLabel>Location</TextLabel>
                    <CustomSelect optionList={legalUnitOptions} onSelect={onLocationSelect} selectedValues={legalUnits}/>
                </Box>
                <Box sx={{marginBottom:"1.5rem"}}>
                    <TextLabel>Holiday Name</TextLabel>
                    <TextField
                       value={name}
                       onChange={(e) => setName(e.target.value)}
                       placeholder="Enter holiday name"
                       variant="outlined"
                       fullWidth
                       sx={{ "& .MuiInputBase-root": InputBoxStyle}}
                     />
                </Box>
                <Box sx={{marginBottom:"1.5rem"}}>
                    <TextLabel>Start Date</TextLabel>
                    <TextField
                     placeholder="YYYY-MM-DD"
                     value={startDate || ''}
                     onClick={() => setStartDateDialogOpen(true)}
                     variant="outlined"
                     fullWidth
                     InputProps={{
                       readOnly: true,
                       endAdornment: (
                         <InputAdornment position="end">
                           <img src={CalenderIcon} alt="Calendar" />
                         </InputAdornment>
                       ),
                     }}
                     sx={{"& .MuiInputBase-root": InputBoxStyle}}
                   />
                   <HolidayDate open={startDateDialogOpen} onClose={() => setStartDateDialogOpen(false)} onSelect={handleStartDateSelect} />
                </Box>
                <Box sx={{marginBottom:"1.5rem"}}>
                    <TextLabel>End Date</TextLabel>
                    <TextField
                     placeholder="YYYY-MM-DD"
                     value={endDate || ''}
                     onClick={() => setEndDateDialogOpen(true)}
                     variant="outlined"
                     fullWidth
                     InputProps={{
                       readOnly: true,
                       endAdornment: (
                         <InputAdornment position="end">
                           <img src={CalenderIcon} alt="Calendar" />
                         </InputAdornment>
                       ),
                     }}
                     sx={{"& .MuiInputBase-root": InputBoxStyle}}
                   />
                   <HolidayDate open={endDateDialogOpen} onClose={() => setEndDateDialogOpen(false)} onSelect={handleEndDateSelect} />
                </Box>
                <Box sx={{display:"flex", justifyContent:"end"}}>
                    <BtnPrimary onClick={handleAddHoliday} sx={{width:"7.5rem", height:"3rem", borderRadius:"8px"}}>ADD</BtnPrimary>
                </Box>
          </Box>

          <Box sx={{width:"50%", borderRadius:"8px",padding:"0.75rem",border:"1px solid #E8E8E8"}}>
                <TextBox 
                 sx={{width:"100%",
                    textAlign:"center",
                    fontWeight:"500",
                    fontSize: "1.375rem",
                    lineHeight: "2.06rem", 
                    marginBottom: "0.75rem",
                    color:"#3A3A3A",                  
                  }}>
                  Public Holidays List {new Date().getFullYear()}
                </TextBox>
            
                {
                 holidays.map((holiday, index) => (
                  <Box key={index} sx={{ display: "flex", width: "100%", marginTop: "0.75rem"}}>
                     <Box sx={{ display: "flex", width: "90%", border: "1px solid #B3B3B333", borderRadius: "4px", padding: "0.5rem 1rem", justifyContent: "space-between" }}>
                        <Box sx={{ display: "flex", gap: "0.2rem" }}>
                          <TextBox sx={{ fontWeight: "500", fontSize: "0.875rem", lineHeight: "1.31rem" }}>{dayjs(holiday.startDate).format('D MMM')}</TextBox>
                          <TextBox sx={{ fontSize: "0.875rem", lineHeight: "1.31rem" }}>{holiday.name}</TextBox>
                        </Box>
                        <TextBox sx={{ fontWeight: "500", fontSize: "0.875rem", lineHeight: "1.31rem" }}>{holiday.legalUnits.join(', ')}</TextBox>
                     </Box>
                     <IconButton onClick={(event) => handleOpenMenu(event, index)}>
                       <MoreVertIcon />
                     </IconButton>
                     <Menu
                       anchorEl={anchorEl}
                       open={selectedHolidayIndex === index && Boolean(anchorEl)}
                       onClose={handleCloseMenu}
                     >
                       <MenuItem onClick={() => handleEditHoliday(index)}>Edit</MenuItem>
                       <MenuItem onClick={() => handleDeleteHoliday(index)}>Delete</MenuItem>
                     </Menu>
                  </Box>
                 ))}
          </Box>
    </Box>
  );
};

export default HolidaySetting;