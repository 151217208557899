import { postCall, getCall, putCall } from "./api";

// Expense Category 

export const createPolicyCateogary = async (input = {}) => {
  const request = {
    path: `/policy-cateogary`,
    bodyParams: input,
  };
  return await postCall(request);
};

export const getAllExpenseCategory = async (legalUnit) => {
  console.log(legalUnit);
  const request = {
    path: `/policy-cateogary/${legalUnit}?type=ANY`,
  };
  return await getCall(request);
};

export const getAllExpensePolicy = async (legalUnit) => {
  const request = {
    path: `/policy/${legalUnit}?type=ANY`,
  };
  return await getCall(request);
};

export const getPolicyCateogaryByLegalUnit = async (legalUnit) => {
  const request = {
    path: `/policy-cateogary/${legalUnit}`,
  };
  return await getCall(request);
};

export const createPolicy = async (input = {}) => {
  const request = {
    path: `/policy`,
    bodyParams: input,
  };
  return await postCall(request);
};

export const linkPolicy = async (policyId, input = {}) => {
  const request = {
    path: `/policy/link/${policyId}`,
    bodyParams: input,
  };
  return await putCall(request);
};

export const unlinkPolicy = async (policyId, input = {}) => {
  const request = {
    path: `/policy/remove/${policyId}`,
    bodyParams: input,
  };
  return await putCall(request);
};

export const getPolicyById = async (policyId) => {
  console.log(policyId);
  const request = {
    path: `/policy/id/${policyId}`,
  };
  return await getCall(request);
};

export const getPolicySelf = async () => {
  const request = {
    path: `/policy/self`,
  };
  return await getCall(request);
};



//approve and reject

export const approveExpense = async (input={}, id) => {
  const request = {
    path: `/expense/approve/${id}`,
    bodyParams: input,
  };
  return await putCall(request);
};

export const rejectExpense = async (input={}, id) => {
  const request = {
    path: `/expense/reject/${id}`,
    bodyParams: input,
  };
  return await putCall(request);
};


//all expense
export const getAllExpense = async () => {
  const request = {
    path: `/expense/organization`,
  };
  return await getCall(request);
};

export const getExpenseById = async (id) => {
  const request = {
    path: `/expense/${id}`,
  };
  return await getCall(request);
};

export const getEmployeeExpense = async () => {
  const request = {
    path: `/expense/employee`,
  };
  return await getCall(request);
};


//create expense by employee

export const createExpense = async (input = {}) => {
  const request = {
    path: `/expense`,
    bodyParams: input,
  };
  return await postCall(request);
};