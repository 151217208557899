import React,{useState} from 'react'
import {Autocomplete, Box, TextField} from '@mui/material';
import styled from '@emotion/styled';
import BtnPrimary from '../../../../../../component/PrimaryButton';
import BtnSecondary from '../../../../../../component/SecondaryButton';
import FormField from '../../../../../../component/Formfield';
import DateField from '../../../../../../component/DateField';
import { getOrgDepartments, getOrgEmployees } from '../../../../../../services/globalData';
import AddNewOs from './AddNewOs';
import { useLocation, useNavigate } from 'react-router';
import { addMachine, updateMachine } from '../../../../../../services/asset';

const TextBox = styled(Box)({
    display: 'block',
    fontSize: '1rem',
    lineHeight: '1.5rem',
    color: '#818181',
  });
  
  const InputStyle = {
    height: '3rem',
    borderRadius: '10px',
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontWeight:"300",
    color: '#1E1E1E',
  }

const MachineForm = () => {
    const [loading,setLoading]=useState(false);
    const [apiCall,setApiCall]=useState(false);
    const navigate = useNavigate();
    const {state} = useLocation();
    const [mode, setMode] = useState("create"); 
    const [assetId, setAssetId] = useState(null);
    const [machineFormData, setMachineFormData] = useState({
        type:"",
        name:"",
        manufacturer:"",
        operatingSystem:"",
        model:"",
        serialNumber:"",
        vendor:'6662c2750571495f2e83ab3a',
        activeServiceDate:"",
        // status:"",
        employee:"",
        department:"",
    });
    const [validationErrors, setValidationErrors] = useState({
        type:"",
        name:"",
        manufacturer:"",
        operatingSystem:"",
        model:"",
        serialNumber:"",
        vendor:"",
        activeServiceDate:"",
        status:"",
        employee:"",
        department:"",
    });
    const [isDateDialogOpen, setIsDateDialogOpen] = useState(false);
    const [addNewOs,setAddNewOs] = useState(false);
    const [selectedDepartment, setSelectedDepartment] = useState();
    const [selectedEmployee, setSelectedEmployee] = useState();
    const [error,setError]=useState("");

    const departments = getOrgDepartments().map(department => ({
        name: department.name,
        id: department._id
    }));
    const employees = getOrgEmployees().map(employee => ({
        name: employee.fname+" "+employee.lname,
        id: employee._id
    }));

    const checkOs = (value) =>{
      if(value==="other"){
         setAddNewOs(true);
      }
    }

  const handleChange = (field, value) => {
    setMachineFormData(prevState => ({
            ...prevState,
            [field]: value 
    }));
  };

  const handleAddClick = async() => {
    const errors ={};
    if (!machineFormData.type) {
      errors.type="Type is required"
    }
    if (!machineFormData.name) {
        errors.name="Name is required"
    }
    if (!machineFormData.manufacturer) {
       errors.manufacturer="Manufacturer is required"
    }
    if (!machineFormData.operatingSystem) {
       errors.operatingSystem="Operating System is required"
    }
    
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    console.log(machineFormData);
    setApiCall(true);
    try {
      if (mode === 'create') {
        const res = await addMachine(machineFormData);
        console.log(res);
        if(res.success){
          navigate("/app/machines",{state:{assetId: res.data._id, mode}});
        } else {
            console.error('Adding machine operation failed:', res.error);
            setError(res.message);
        }
      } else if (mode === 'edit') {
        const res = await updateMachine(assetId,machineFormData);
        console.log(res);
        if(res.success){
          navigate("/app/machines",{state:{assetId, mode}});
        }else{
          console.error('Machine updation failed:', res.error);
          setError(res.message);
        }
      } else {        
         navigate("/app/machines",{state:{assetId, mode}});  
      }
    } catch (error) {
        console.error('Error saving Machines:', error);
    }
    setApiCall(false);
};

  return (
    <Box sx={{ width: "100%", padding:"1.875rem 1.25rem"}}>
        <Box sx={{ fontWeight:"500", fontSize:"1.375rem", lineHeight:"2.06rem", marginBottom:"1.5rem"}}>
            Add New Machine
        </Box>
        <Box>
            <FormField
                label="Type"
                type="text"
                value={machineFormData.type}
                validation={validationErrors.type}
                onChange={(e) => handleChange('type', e.target.value)}
            />
            <FormField
                label="Operating System"
                type="select"
                options={["windows", "mac", "linux", "other"]}
                value={machineFormData.operatingSystem}
                validation={validationErrors.operatingSystem}
                onChange={(e) => {
                    handleChange('operatingSystem', e.target.value)
                    checkOs(e.target.value);
                }}
            />
            <FormField
                label="Name"
                type="text"
                value={machineFormData.name}
                validation={validationErrors.name}
                onChange={(e) => handleChange('name', e.target.value)}
            />
            <FormField
                label="Manufacturer"
                type="text"
                value={machineFormData.manufacturer}
                validation={validationErrors.manufacturer}
                onChange={(e) => handleChange('manufacturer', e.target.value)}
            />
            <FormField
                label="Model"
                type="text"
                value={machineFormData.model}
                onChange={(e) => handleChange('model', e.target.value)}
            />
            <FormField
                label="Serial Number"
                type="text"
                value={machineFormData.serialNumber}
                onChange={(e) => handleChange('serialNumber', e.target.value)}
            />
            <DateField
                label="Active Service Date"
                value={machineFormData.activeServiceDate}
                isDialogOpen={isDateDialogOpen}
                openDialog={() => setIsDateDialogOpen(true)}
                cloperatingSystemeDialog={() => setIsDateDialogOpen(false)}
                onSelect={(date) => {
                    handleChange('activeServiceDate', date);
                    setIsDateDialogOpen(false);
                }}
            />
            <FormField
                label="Vendor"
                type="text"
                value={machineFormData.vendor}
                onChange={(e) => handleChange('vendor', e.target.value)}
            />
            <FormField
                label="Status"
                type="select"
                options={["active","inactive"]}
                value={machineFormData.status}
                onChange={(e) => handleChange('status', e.target.value)}
            />
        </Box>
        <Box sx={{fontWeight:"500", fontSize:"1.375rem", lineHeight:"2.06rem", marginBottom:"1.5rem"}}>
            User
        </Box>
        <Box>
            <Box sx={{width:"48%"}}> 
                <TextBox>Employees</TextBox>
                <Autocomplete
                    options={employees}
                    getOptionLabel={option => option?.name}
                    size="small"
                    value={selectedEmployee||null}
                    onChange={(e, selectedValue) => {
                        setSelectedEmployee(selectedValue);
                        handleChange('employee', selectedValue?.id)
                    }}
                    sx={{
                    '& .MuiInputBase-root': InputStyle,
                        "& .MuiOutlinedInput-notchedOutline":{
                        border: "1px solid #E7E7ED",
                        }
                    }}
                    renderInput={(params) => <TextField {...params}/>}
                />
            </Box>
            <Box sx={{width:"48%", marginTop:"1.125rem"}}> 
                <TextBox>Department</TextBox>
                <Autocomplete
                    options={departments}
                    getOptionLabel={option => option?.name}
                    size="small"
                    value={selectedDepartment||null}
                    onChange={(e, selectedValue) => {
                        setSelectedDepartment(selectedValue);
                        handleChange('department', selectedValue?.id)
                    }}
                    sx={{
                    '& .MuiInputBase-root': InputStyle,
                        "& .MuiOutlinedInput-notchedOutline":{
                        border: "1px solid #E7E7ED",
                        }
                    }}
                    renderInput={(params) => <TextField {...params}/>}
                />
            </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "end", gap: "1.25rem", marginBlock: "1.875rem" }}>
                <BtnPrimary 
                  onClick={handleAddClick}
                  sx={{ borderRadius: "10px", width: "8.68rem", height: "3.125rem" }}
                >
                    Add Machine
                </BtnPrimary>
                <BtnSecondary sx={{ borderRadius: "10px", width: "5.68rem", height: "3.125rem" }} to="/app/machines">
                    Cancel
                </BtnSecondary>
        </Box>
        <AddNewOs open={addNewOs} onClose={()=>setAddNewOs(false)}/>
    </Box>
  )
}

export default MachineForm