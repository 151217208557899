import React, { useState, useEffect } from 'react';
import { Box,TableCell } from '@mui/material';
import PaginationTable from '../../../../../../../component/PaginationTable';
import Topbar from "../../../../../../../component/TopBar";
import MainBox from "../../../../../container/MainBox";
import { getTrip} from '../../../../../../../services/trips';
import { getEmployeeNames } from '../../../../../../../utils/getEmployeeNames';
import TripInfo from "./TripInfo";
import TableHeader from '../../../../../../../component/TableHeader';

const CustomTableCell = {
  fontFamily:"Poppins",
  fontWeight: "300",
  fontSize: "1rem",
  color: "#000000",
  lineHeight: "1.5rem",
  padding: "1rem",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis", 
  overflow:"hidden"
};


const Headings = {
  fontFamily:"Poppins",
  fontWeight: "300",
  fontSize: "1rem",
  color: "#000000",
  lineHeight: "1.5rem",
  padding: "1.375rem 1rem",
  backgroundColor: "#F5F5F5",
  borderBottom: "none",
  textAlign:"center",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
}

const TripDetails = () => {
    const [loading, setLoading] = useState(true);
    const [date, setDate] = useState(new Date().toLocaleDateString('en-GB')); 
    const [order,setOrder] = useState("desc");
    const [data, setData] = useState([]);
    const [tripId,setTripId] = useState("");
    const [expenseId,setExpenseId] = useState("");
    const [open,setOpen] = useState(false);
    const [filteredData,setFilteredData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(()=>{
      setFilteredData(filteredData.reverse());
    },[order]);

    useEffect(()=>{
      setFilteredData(data.filter((item)=>item.date===(new Date(date).toLocaleDateString('en-GB'))));
    },[date]);

    useEffect(()=>{
      setFilteredData(data.filter((item)=>(
        searchQuery===""? item : item?.employeeName?.toLowerCase()?.includes(searchQuery?.toLowerCase())
      )));
    },[searchQuery]);
    
      const headings = [
        { label: "Date", style: {...Headings, width:"6.43rem", borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px" } },
        { label: "Employee", style: {...Headings, width:"9rem"} },
        { label: "Trip ID", style: {...Headings, width:"6.43rem"} },
        { label: "Total Distance", style: {...Headings, width:"6.43rem", maxWidth: "6.43rem"} },
        { label: "Amount", style: {...Headings, width:"5.43rem"} },
        { label: "Status", style: { ...Headings, width:"6.43rem", borderTopRightRadius: "12px", borderBottomRightRadius: "12px" } }
      ];


      useEffect(()=>{
        const fetchData = async () => {
          try {
            const res = await getTrip();
            if(res.success){
              const employeeIds = res.data.map(data => data.submitterId);
              const combinedData = await getEmployeeNames(res.data, employeeIds, "submitterId");        
              const dataTable = combinedData.map((item)=>({
                 date:(new Date(item.createdAt).toLocaleDateString('en-GB')),
                 employeeName:item.employee.fname+" "+item.employee.lname,
                 tripId:item.linkTrip._id,
                 expenseId:item._id,
                 aliasId:item.linkTrip.aliasId,
                 totalDistance:item.linkTrip.distance,
                 amount:item.amount,
                 status:item.status
              }));
              const reversedDataTable = dataTable.reverse();
              setData(reversedDataTable);
              setFilteredData([...reversedDataTable]);
              console.log(filteredData);
              setLoading(false);
            }
          } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
          }
        };
      
        fetchData();
      },[])

      const handleRowClick = (tripId, expenseId) => {
        setTripId(tripId);
        setExpenseId(expenseId);
        if(!open){
          setOpen(!open);
        }
      };

    return (
        <Box sx={{ width: "100%" }}>
            <Topbar MainHeading={"Trips Details"}/>
            <MainBox sx={{ padding: "1.875rem 1.25rem" }}>
              <Box></Box>
              <TableHeader 
                 data={data} 
                 setDate={setDate} 
                 setOrder={setOrder}
                 searchQuery={searchQuery}
                 handleSearch={(value)=>setSearchQuery(value)}
              />
              <Box sx={{display:"flex", gap:"1rem", overflow:"hidden"}}>
                <Box sx={{flex:1}}>
                    <PaginationTable
                        rows={filteredData}
                        headings={headings}
                        loading={loading}
                        noRecordMessage={"No Record Found"}
                        renderCell={(row) => (
                          <>
                            <TableCell 
                              align="center"
                              sx={{...CustomTableCell, width:"6.43rem",  maxWidth:"6.43rem"}}
                            >
                              {row.date}
                            </TableCell>
                            <TableCell 
                              align="center"
                              sx={{...CustomTableCell, width:"9rem", maxWidth: "9rem"}}
                            >
                            {row.employeeName}
                            </TableCell>
                            <TableCell 
                            align="center"
                            sx={{...CustomTableCell, width:"6.43rem", maxWidth: "6.43rem",":hover":{cursor:"pointer"}, color:"#008cc4",textDecoration:"underline"}} 
                            onClick={() => handleRowClick(row.tripId, row.expenseId)}
                          >
                              {row.aliasId}
                          </TableCell>
                            <TableCell align="center" sx={{...CustomTableCell, width:"6.43rem"}}>{row.totalDistance || 0} KM</TableCell>
                            <TableCell align="center" sx={{...CustomTableCell, width:"5.43rem"}}>{row.amount}</TableCell>
                            <TableCell align="center" sx={{...CustomTableCell, width:"6.43rem"}}>
                                  <Box sx={{
                                            borderRadius:"4px", 
                                            padding:"4px 8px",
                                            textAlign:"center",
                                            backgroundColor: row.status === "pending" ? "#FFBE64" : (row.status === "rejected" ? "#E493931A" : "#0CBD7D"),
                                            color: row.status === "pending" ? "#212423" : (row.status === "rejected" ? "#E52E2E" : "#FFFFFF")
                                          }}>
                                        {row.status}
                                  </Box>
                            </TableCell>
                          </>
                        )}
                      />
                </Box>  
                <Box sx={{
                        marginRight: open ? '0px' : '-420px',
                        transitionProperty: 'margin-right', 
                        transitionDuration: '0.5s',
                        transitionTimingFunction: 'ease-in-out', 
                      }}
                >
                   <TripInfo tripId={tripId} expenseId={expenseId} setOpen={setOpen}/>
                </Box>   
              </Box>
            </MainBox>
        </Box>
    )
}

export default TripDetails;
