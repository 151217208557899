import React, { useState } from "react";
import { Box } from "@mui/material";
import Topbar from "../../../../../../component/TopBar";
import MainBox from "../../../../container/MainBox";
import { Outlet } from "react-router";

function Software() {
  return (
     <Box sx={{width:"100%"}}>
        <Topbar MainHeading={"All Software"}/>
        <MainBox sx={{ padding: "1.875rem 1.25rem" }}>
              <Outlet/>
        </MainBox>
     </Box>
      
  );
}

export default Software;
