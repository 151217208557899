import React, { useState, useEffect } from "react";
import {Box, Button, ButtonGroup, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import styled from "@emotion/styled";
import PaginationTable from "../../../../../component/PaginationTable";
const dayjs = require('dayjs');

export default ({leaveData}) => {

    const [activeTab, setActiveTab] = useState("Approved");
    const [tableData, setTableData] = useState([]);
    const [data,setData] = useState(leaveData);

    const CustomTableCell = styled(TableCell)({
        fontSize: "0.875rem",
        color: "#8B9390",
        lineHeight: "1.31rem",
        letterSpacing: "1px",
        textAlign: "left",
        padding: "0.75rem"
      });
      
      const Headings = {
        fontSize: "1rem",
        fontWeight:500,
        color: "#212423",
        lineHeight: "1.5rem",
        backgroundColor: "#FAFAFA",
        padding: "1rem",
        borderBottom: "none",
      }
    
    const loadData = () => {
        let filteredData;
        switch (activeTab) {
            case "Approved":
                filteredData = data.filter(item => item.status === "Approved");
                break;
            case "Pending":
                filteredData = data.filter(item => item.status === "Pending");
                break;
            case "History":
                filteredData = data;
                break;
            default:
                filteredData = [];
        }
        setTableData(filteredData);
    };

    useEffect(() => {
      loadData();
    }, [activeTab]);

    useEffect(() => {
      setData(leaveData);
    }, [leaveData]);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const headings = [
      { label: "From", style: { ...Headings } },
      { label: "To", style: {...Headings} },
      { label: "Status", style: {...Headings} },
    ];

    return (
             <Box sx={{display:'flex', justifyContent:'space-between'}}>
                  <Box sx={{display:'flex', flexDirection:'column', borderRadius:"12px", backgroundColor:"#FFFFFF", padding:"1.5rem 1rem 1.5rem 1rem", boxShadow:"0px 2.767256498336792px 20px 0px #0000000D"}}>
                    <ButtonGroup aria-label="leave status" sx={{ alignSelf: 'flex-end', borderRadius: '4px', marginBottom:"1rem"}}>
                      {["Approved","Pending","History"].map((label)=>(
                        <Button
                           key={label}
                           onClick={() => handleTabClick(label)}
                           sx={{
                             fontSize:"0.875rem",
                             lineHeight:"1.31rem",
                             fontWeight:500,
                             width:'7.5rem',
                             textTransform: 'none',
                             padding: '0.75rem',
                             backgroundColor: activeTab === label ? '#0081FF' : null,
                             color: activeTab === label ? "white" : '#5C5C5C',
                             '&:hover': {
                               backgroundColor: activeTab === label ? '#0081FF' : null,
                               color: activeTab === label ?"white" : '#5C5C5C',
                           },
                           }}
                      >
                        {label}
                      </Button>
                      ))}
                    </ButtonGroup>
                     <PaginationTable
                          customWidth="36.37rem"
                          rows={tableData}
                          headings={headings}
                          renderCell={(row, index) => (
                           <>
                             <CustomTableCell>{dayjs(row.firstDay).format('D MMM YYYY')}</CustomTableCell>
                             <CustomTableCell>{dayjs(row.lastDay).format('D MMM YYYY')}</CustomTableCell>
                             <CustomTableCell>
                                <Box sx={{width:"6.25rem",
                                          borderRadius:"4px", 
                                          padding:"4px 8px",
                                          textAlign:"center",
                                          backgroundColor: row.status === "Pending" ? "#FFBE64" : (row.status === "Rejected" ? "#E493931A" : "#0CBD7D"),
                                          color: row.status === "Pending" ? "#212423" : (row.status === "Rejected" ? "#E52E2E" : "#FFFFFF")
                                        }}>
                                     {row.status}
                                </Box>
                             </CustomTableCell>
                           </>
                         )}
                       />
                  </Box>
             </Box>
    );
  };