//Ray Casting Algorithm

export const isPointInFence = (fence, arrivalLat, arrivalLng) => {
    
    function isPointInPolygon(polygon, lat, lng) {
        let inside = false;
        const x = lng, y = lat;

        for (let i = 0, j = polygon?.length - 1; i < polygon?.length; j = i++) {
            const xi = polygon[i]?.lng, yi = polygon[i]?.lat;
            const xj = polygon[j]?.lng, yj = polygon[j]?.lat;

            const intersect = ((yi > y) !== (yj > y)) &&
                (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect) inside = !inside;
        }

        return inside;
    }

    return isPointInPolygon(fence, arrivalLat, arrivalLng);
}


