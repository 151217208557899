import React from 'react'
import { Box, Switch } from '@mui/material';

const SwitchToggle = ({ checked, onChange, label, disabled, labelStyle }) => {
  return (
    <Box sx={{display:"flex", justifyContent:"space-between", alignItems:"center"}}> 
       <Box sx={labelStyle || { fontSize: '1rem',lineHeight: '1.5rem',color: '#818181'}}>{label}</Box>
       <Switch checked={checked} onChange={onChange} disabled={disabled}/>
    </Box>
  )
}

export default SwitchToggle