import React from 'react'
import { Avatar, Box } from '@mui/material'
import BtnPrimary from "./PrimaryButton";
import Eye from "../assets/icons/eye-white.svg";
import { calculateTime } from '../utils/calculateTime';
import { formatTimeString } from '../utils/formatTimeString';

const EmployeeAttendanceCard = ({employeeData}) => {

  console.log(employeeData);

  return (
    <Box 
      sx={{
      borderRadius:"10px", 
      backgroundColor:"#D9D9D96E", 
      padding:"1.5rem 0.75rem",
      '&:hover': {
          backgroundColor: '#EFEFEF',
          boxShadow:"0px 4px 4px 0px #00000040"
        },
      '&:hover .viewDetailsBtn': {
          display: 'flex',
        },
        '&:hover .clockInOut': {
          fontSize: '0.85rem',
        },
      }}>
      <Box sx={{display:"flex",alignItems:"center",}}>
         <Avatar sx={{width:"2.76rem", height:"2.54rem"}}/>
         <Box sx={{color:"#111827", marginLeft:"0.75rem"}}>
            <Box sx={{fontWeight:"500", fontSize:"1rem", lineHeight:"1.25rem"}}>
              {employeeData?.employee?.fname+" "+employeeData?.employee?.lname}
            </Box>
            <Box sx={{fontWeight:"300", fontSize:"0.75rem", lineHeight:"1.25rem"}}>
              {employeeData?.employee?.designation+" | "}
            </Box>
            <Box sx={{fontWeight:"300", fontSize:"0.75rem", lineHeight:"1.25rem"}}>
              {employeeData?.employee?.employmentType}
            </Box>
         </Box>
         <Box className="clockInOut" sx={{color:"#111827", fontSize:"0.75rem", lineHeight:"1.25rem", marginInline:"1.125rem"}}>
            <Box sx={{display:"flex"}}>
                <Box sx={{fontWeight:"300"}}>Clock In - </Box>
                <Box sx={{fontWeight:"500"}}>{formatTimeString(employeeData?.records[0]?.createdAt)}
               </Box>
            </Box>
            <Box sx={{display:"flex"}}>
                <Box sx={{fontWeight:"300"}}>Clock Out - </Box>
                <Box sx={{fontWeight:"500"}}>{!isNaN(Number(employeeData?.records[0]?.departureTime)) ? calculateTime(employeeData?.records[0]?.departureTime) : "--"}</Box>
            </Box>
         </Box>
         <Box sx={{display:"flex", gap:"10px", alignItems:"center"}}>
            <Box sx={{height:"8px", width:"8px", borderRadius:"50%", backgroundColor:"#0063F5"}}></Box>
            <Box sx={{fontWeight:"300", color:"#4F4F50", fontSize:"0.75rem", lineHeight:"1.25rem"}}>
                {employeeData.status}
            </Box>
         </Box>
       </Box>
       <Box className="viewDetailsBtn" sx={{display:"none", justifyContent:"end", marginTop:"1.875rem"}}> 
            <BtnPrimary 
              to={`/app/attendance-details/${employeeData?.employeeId}`}
              sx={{width:"10rem", height:"3.125rem", borderRadius:"10px"}}
              startIcon={Eye}
            >
              View Details
            </BtnPrimary>
        </Box>
    </Box>
  )
}

export default EmployeeAttendanceCard