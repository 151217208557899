import React,{useState} from 'react'
import {Box} from '@mui/material';
import BtnPrimary from '../../../../../../component/PrimaryButton';
import BtnSecondary from '../../../../../../component/SecondaryButton';
import FormField from '../../../../../../component/Formfield';

const EmployeeRate = () => {

    const [employeeRateData, setEmployeeRateData] = useState({
        employee:"",
        ratePerHr:"",
        otRatePerHr:"",
        extendedOtRatePerHr:"",
    });

  const handleChange = (field, value) => {
    setEmployeeRateData(prevState => ({
            ...prevState,
            [field]: value 
        }));
  };

  return (
    <Box sx={{ width: "100%", padding:"1.875rem 1.25rem"}}>
        <Box sx={{fontWeight:"500", fontSize:"1.375rem", lineHeight:"2.06rem", marginBottom:"1.5rem"}}>
            Add Employee Rate
        </Box>
        <Box>
            <FormField
                label="Employee"
                type="select"
                name="employee"
                value={employeeRateData.employee}
                options={[]}
                onChange={(e) => handleChange('employee', e.target.value)}
            />
            <FormField
                label="Rate Per Hour"
                type="text"
                name="ratePerHr"
                value={employeeRateData.ratePerHr}
                onChange={(e) => handleChange('ratePerHr', e.target.value)}
            />
            <FormField
                label="OT Rate Per Hour"
                type="text"
                name="otRatePerHr"
                value={employeeRateData.otRatePerHr}
                onChange={(e) => handleChange('otRatePerHr', e.target.value)}
            />
            <FormField
                label="Extended OT Rate Per Hour"
                type="text"
                name="extendedOtRatePerHr"
                value={employeeRateData.extendedOtRatePerHr}
                onChange={(e) => handleChange('extendedOtRatePerHr', e.target.value)}
            />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "end", gap: "1.25rem", marginBlock: "1.875rem" }}>
                <BtnPrimary sx={{ borderRadius: "10px", width: "5.68rem", height: "3.125rem" }}>
                    Submit
                </BtnPrimary>
                <BtnSecondary sx={{ borderRadius: "10px", width: "5.68rem", height: "3.125rem" }}>
                    Cancel
                </BtnSecondary>
        </Box>
    </Box>
  )
}

export default EmployeeRate