import React,{useState, useEffect} from 'react';
import { Box,TableCell} from "@mui/material";
import styled from '@emotion/styled';
import BtnPrimary from "../../../../../../../component/PrimaryButton";
import { Outlet, useLocation } from "react-router";
import Topbar from "../../../../../../../component/TopBar";
import MainBox from "../../../../../container/MainBox";
import TopNavbar from '../../../../../../../component/TopNavbar';

const CustomTableCell = styled(TableCell)({
  fontWeight: "300",
  fontSize: "1rem",
  lineHeight: "1.5rem",
  padding: "1rem",
});

const Listing = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const [active,setActive] = useState("earnings");
    const navItems = [
        {check:"earnings", link:"", value:"Earnings"},
        {check:"reimbursements", link:"reimbursements", value:"Reimbursements"},
        {check:"deductions", link:"deductions", value:"Deductions"}
      ]

    useEffect(()=>{
    const { pathname } = location;
    console.log(pathname);
    if (pathname.includes("deductions")) {
        setActive("deductions");
    }else if (pathname.includes("reimbursements")) {
        setActive("reimbursements");
    } else {
        setActive("earnings");
    }
    },[location])

    const Headings = {
      fontWeight: "300",
      fontSize: "1rem",
      color: "#000000",
      lineHeight: "1.5rem",
      padding: "1rem",
      padding: "1.375rem 1rem",
      backgroundColor: "#F5F5F5",
      borderBottom: "none",
    }

  return (
     <Box sx={{width:"100%"}}>
        <Topbar MainHeading={"Salary Components"} SubHeading={"Payroll > Salary Component"}/>
        <MainBox sx={{ padding: "1.875rem 1.25rem" }}>
          <Box>
            <Box sx={{ display: "flex", justifyContent: "end", marginBottom:"1.875rem" }}>
                <BtnPrimary
                to="/app/new-salary-component"
                sx={{ borderRadius: "10px",paddingBlock:"1.125rem", height: "3rem" }}
                >
                   Add Component
                </BtnPrimary>
            </Box>
            <Box sx={{ marginBottom:"1.56rem"}}>
                <TopNavbar active={active} navItems={navItems}/>
            </Box>
            <Outlet context={[CustomTableCell,Headings]}/>
         </Box>
        </MainBox>
     </Box>
      
  );
}

export default Listing