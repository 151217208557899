import React, { useEffect, useState } from "react";
import {Box, CircularProgress} from "@mui/material";
import TopBar from "../../../../../../../component/TopBar";
import MainBox from "../../../../../container/MainBox";
import DropdownButton from "../../../../../../../component/DropdownButton";
import BtnSecondary from "../../../../../../../component/SecondaryButton";
import { PolicyTableHeadings} from '../../../../../../../styles/PolicyTableHeadings';
import { PolicyTableCell} from '../../../../../../../styles/PolicyTableCell';
import PaginationTable from "../../../../../../../component/PaginationTable";
import { getOrgShifts } from "../../../../../../../services/globalData";
import TableHeader from '../../../../../../../component/TableHeader';
import { useLocation } from "react-router";
import { getEmployeeDetails } from "../../../../../../../utils/getEmployeeNames";
import { calculateTime } from "../../../../../../../utils/calculateTime";
import { formatTimeString } from "../../../../../../../utils/formatTimeString";
import { isPointInFence } from "../../../../../../../utils/isPointInFence";
import { getGeofence } from "../../../../../../../services/geofence";

const AllEmployeesAttendance = () => {
  
  const [loading,setLoading] = useState(false);
  const shiftOptions = getOrgShifts()?.map((shift) => ({
    value: shift?._id,
    name: shift?.name,
  }));
  const [selectedShift, setSelectedShift] = useState(shiftOptions[0]?.value);
  const [searchQuery, setSearchQuery] = useState("");
  const {state} = useLocation();
  const [filteredAttendanceData, setFilteredAttendanceData] = useState([]);
  const [fence,setFence] = useState();

  useEffect(()=>{
      fetchGeofences();
      transformArray();
  },[]);

  const transformArray = async() =>{
    setLoading(true);
    const transformedArray = state?.employeesAttendanceData?.flatMap(({ date, employees }) =>
      employees.map(({ employeeId, records }) => ({ date, employeeId, records }))
    ); 
    const employeeIds = transformedArray?.map(data => data?.employeeId);
    const combinedData = await getEmployeeDetails(transformedArray, employeeIds, "employeeId"); 
    setFilteredAttendanceData(combinedData.reverse());
    setLoading(false);
  }

  const fetchGeofences = async () => {
    const getFences = await getGeofence();
    const fences = getFences?.data[0]?.geofenceObj?.fences[0];
    setFence(fences);
  };

  console.log(filteredAttendanceData);

  const headings = [
    { label: "Date", style: {...PolicyTableHeadings, width:"4.25rem", borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px" } },
    { label: "Employee Name", style: {...PolicyTableHeadings,  width:"10rem"} },
    { label: "Employee ID", style: {...PolicyTableHeadings, width:"10rem"} },
    { label: "Location", style: {...PolicyTableHeadings, width:"7.5rem"} },
    { label: "Status", style: {...PolicyTableHeadings, width:"7.5rem"} },
    { label: "Clock In", style: {...PolicyTableHeadings, width:"7.5rem"} },
    { label: "Clock Out", style: {...PolicyTableHeadings, width:"7.5rem"} },
    { label: "Working Hours", style: { ...PolicyTableHeadings,width:"7.5rem", borderTopRightRadius: "12px", borderBottomRightRadius: "12px" } }
  ];

  return (
    loading?(
      <Box sx={{width:"100%",display:"flex", justifyContent:"center"}}>
        <CircularProgress/>
      </Box>
    ):(
     <Box sx={{width:"100%", paddingBlock:"1rem"}}>
        <TopBar 
          MainHeading={"Attendance"} 
          breadcrumbs={[
            {label:"Attendance"},
            {label:"Dashboard", href:"/app/attendance-dashboard"},
            {label:"All Employee Attendance"}
          ]}
        />
        <MainBox sx={{ padding: "2.5rem 1.25rem"}}>
            <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom:"1.5rem", alignItems:"end" }}>
                <Box sx={{fontWeight:"500", fontSize:"1.5rem", lineHeight:"2.25rem"}}>
                   Employee Attendance Details
                </Box>
                <Box sx={{display:"flex", gap:"1rem"}}>
                  <DropdownButton 
                    value={selectedShift} 
                    options={shiftOptions} 
                    onChange={(e)=>setSelectedShift(e.target.value)}
                  />                  
                  <BtnSecondary sx={{ width: '5.56rem', height: '2rem', borderRadius: '8px' }} to="/app/attendance-dashboard">
                      Back
                  </BtnSecondary>
                </Box>
            </Box>
             <TableHeader 
                data={filteredAttendanceData} 
                // setDate={setDate} 
                // setOrder={setOrder}
                searchQuery={searchQuery}
                handleSearch={(value)=>setSearchQuery(value)}
             />
             <PaginationTable
                rows={filteredAttendanceData}
                headings={headings}
                loading={loading}
                link={true}
                path="/app/attendance-details"
                id="employeeId"
                noRecordMessage={"No Record Found"}
                renderCell={(row) => (
                  <>
                    <PolicyTableCell>{row?.date}</PolicyTableCell>
                    <PolicyTableCell>{row?.employee?.fname+" "+row?.employee?.lname}</PolicyTableCell>
                    <PolicyTableCell>{row?.employee?.aliasId}</PolicyTableCell>
                    <PolicyTableCell>{isPointInFence(fence, row?.arrivalLat, row?.arrivalLong)?"In Office":"Remote"}</PolicyTableCell>
                    <PolicyTableCell>Present</PolicyTableCell>
                    <PolicyTableCell>{formatTimeString(row?.records[0]?.createdAt)}</PolicyTableCell>
                    <PolicyTableCell>{!isNaN(Number(row?.records[0]?.departureTime)) ? calculateTime(row?.records[0]?.departureTime) : "--"}</PolicyTableCell>
                    <PolicyTableCell>{Math.floor((row.records?.[row?.records?.length - 1]?.totalHours)/60)}</PolicyTableCell>
                  </>
                )}
              />
        </MainBox>
     </Box>
    )
  );
};

export default AllEmployeesAttendance;