import React from 'react';
import PolicyRules from '../../../../../../../component/PolicyRules';

const ExpensePolicyRules = () => {
 
  return (  
        <PolicyRules
           addNewLink="/app/expense-policy/new/setting" 
           viewAllLink="/app/expense-policy/listing" 
        />
  )
}

export default ExpensePolicyRules;