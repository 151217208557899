import React,{useState} from 'react';
import { Avatar, Box} from '@mui/material';
import ProgressBar from '../../../../../component/ProgressBar';
import ThumbsUp from '../../../../../assets/icons/thumbs-up.svg';
import MainBox from "../../../container/MainBox";
import BtnPrimary from '../../../../../component/PrimaryButton';
import BtnSecondary from '../../../../../component/SecondaryButton';
import ThumsDownBlue from '../../../../../assets/icons/thums-down-blue.svg';
import ThumsUpWhite from '../../../../../assets/icons/thums-up-white.svg';
import MonthPicker from '../../../../../component/MonthPicker';

const LeaderBoard = () => {

  const handleLike = () =>{}
  const handleDislike = () =>{}

  return (
    <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom:"1rem", alignItems:"end" }}>
             <Box>
                <Box sx={{display:"flex"}}>
                   <Avatar src={ThumbsUp} style={{ width: '1.5rem', height: '1.5rem' }}/>
                   <Box sx={{fontWeight:"300", fontSize:"1rem", lineHeight:"1.5rem", color:"#0081FF", marginBottom:"0.56rem"}}>
                      LeaderBoard
                   </Box>
                </Box>
                <ProgressBar/>
             </Box>
             
        </Box>
        <Box>
           <Box sx={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                <MonthPicker/>
                <Box sx={{fontWeight:"500", fontSize:"1.125rem", lineHeight:"1.375rem", marginBlock:"1rem"}}>
                    Most consistent employee
                </Box>
                <Avatar sx={{width:"9.375rem", height:"9.375rem"}}/>
                <Box sx={{fontWeight:"500", fontSize:"1.5rem", lineHeight:"2.25rem", color:"#1D293A", marginTop:"0.5rem", marginBottom:"0.35rem"}}>
                    {"Name"}
                </Box>
                <Box sx={{fontWeight:"500", fontSize:"1.125rem", lineHeight:"1.31rem", color:"#979DA6"}}>
                    {"Designation"}
                </Box>
                <Box sx={{fontWeight:"500", fontSize:"1.75rem", lineHeight:"1.125rem", marginTop:"1.68rem", marginBottom:"2.06rem"}}>
                    Attendance
                </Box>
           </Box>
           <Box sx={{width:"100%",display:"flex", gap:"1.187rem", justifyContent:"end"}}>
                    <BtnSecondary 
                      onClick={handleDislike} 
                      sx={{width:"4.31rem", height:"3.125rem", borderRadius:"10px"}}
                    >
                      <img src={ThumsDownBlue} sx={{height:"0.5rem", width:"0.5rem"}}/>
                    </BtnSecondary> 
                    <BtnPrimary 
                      onClick={handleLike} 
                      sx={{width:"4.31rem", height:"3.125rem", borderRadius:"10px"}}
                    >
                       <img src={ThumsUpWhite} sx={{height:"0.5rem", width:"0.5rem"}}/>
                    </BtnPrimary> 
           </Box>
        </Box>
    </Box>
  )
}

export default LeaderBoard