import React, { useState, useEffect } from 'react';
import { TextField, Collapse, List, ListItemButton, ListItemText } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {getAllLeaveType} from "../../../../../services/leave";

export default function CustomSelect({onLeaveSelect}) {
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getAllLeaveType();
        console.log(res); 
        setOptions(res.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []);

  const handleToggle = () => {setOpen(!open)};

  const handleOptionSelect = (option) => {
    setSelectedOption(option.name);
    setOpen(false);
    onLeaveSelect(option._id);
  };

  return (
    <div>
      <TextField
        variant="outlined"
        onClick={handleToggle}
        placeholder="Leave Type"
        value={selectedOption}
        fullWidth
        InputProps={{
          readOnly: true,
          endAdornment: <ExpandMoreIcon />
        }}
        sx={{
            "& .MuiInputBase-root":{
                backgroundColor:"#008CC405",
                borderRadius:"4px",
                fontSize:"1rem",
                lineHeight:"1.5rem",
                color:"#5C5C5C"
            }
          }} 
      />
      <Collapse in={open}>
        <List>
          {options.map((option) => (
            <ListItemButton key={option.id} onClick={() => handleOptionSelect(option)}>
              <ListItemText 
               primary={option.name} 
               sx={{
                fontSize:"1rem",
                lineHeight:"1.5rem",
                color:"#5C5C5C",
                borderBottom:"1px solid #ECECEC",
                padding:0
               }} />
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </div>
  );
}
