import React, {useState} from "react";
import {Box, Button, TableCell} from "@mui/material";
import MainBox from "../../../container/MainBox";
import TopBar from "../../../../../component/TopBar";
import BtnPrimary from '../../../../../component/PrimaryButton';
import BtnSecondary from '../../../../../component/SecondaryButton';
import PaginationTable from "../../../../../component/PaginationTable";
import { PolicyTableHeadings } from "../../../../../styles/PolicyTableHeadings";
import styled from '@emotion/styled';
import EditIcon from "../../../../../assets/icons/pencil-black.svg";
import ActiveBar from "../../../../../component/PolicyNavbarUpdated";
import {useNavigate } from "react-router-dom";
import FormField from "../../../../../component/Formfield";
import CheckboxField from "../../../../../component/CheckboxField";
import SwitchToggle from "../../../../../component/SwitchToggle";

const Card = styled(Box)({
    height:"10rem",
    backgroundColor:"#FAFAFB",
    padding:"1.25rem",
    borderRadius:"15px",
    boxShadow:"0px 1px 2px 0px #0000004D",
    display:"flex",
    flexDirection:"column",
    alignItems:"center"  
})

const CardMainHeading = styled(Box)({
    fontWeight: "600",
    fontSize: "1.875rem",
    lineHeight: "2.5rem",
    color:"#16151C"
});

const CardText = styled(Box)({
    fontSize: "0.75rem",
    lineHeight: "1.125rem",
    letterSpacing: "0.1%",
});

const CustomTableCell = {
    fontFamily:"Poppins",
    fontWeight: "300",
    fontSize: "1rem",
    color: "#000000",
    lineHeight: "1.5rem",
    padding: "1rem",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis", 
    overflow:"hidden"
  };
  
  
  const Headings = {
    fontFamily:"Poppins",
    fontSize: "1rem",
    fontWeight:"400",
    color: "#1E1E1E",
    lineHeight: "1.5rem",
    borderBottom: "1px solid var(--Colors-Prime-Gray-Prime-Gray-300, #D1D5DB)",
  }

const ComponentHeading = ({heading}) =>{
    return(
      <Box sx={{width:"100%",height:"3rem",borderRadius:"10px", padding:"0.75rem", backgroundColor:"#FAFAFB", display:"flex",justifyContent:"space-between", alignItems:"center", marginBlock:"1.875rem"}}>
          <Box sx={{fontWeight:"500", fontSize:"1.25rem", lineHeight:"1.5rem", color:"#141414"}}>
               {heading}
          </Box>
          <Box sx={{display:"flex", gap:"1.25rem"}}>
               <Button sx={{textTransform:"none", color:"#000"}}>
                 + Add Components
               </Button>
          </Box>
      </Box>
    )
}

const SalaryTemplate = () => {
  
  const [selectedSalaryTemplate, setSelectedSalaryTemplate] = useState();
  const [revertBackToDefault, setRevertBackToDefault] = useState();
  const [earningComponentData, setEarningComponentData] = useState([]);
  const [statutaryComponentData, setStatutaryComponentData] = useState([
    {name:"Provident Fund ( PF )", helperText:"( Subject to applicability within this salary range )", checked:true},
    {name:"Employee State Insurance ( ESI )", helperText:"( Subject to applicability within this salary range )", checked:true},
    {name:"Tax Deduction at Source ( TDS )", helperText:"( Tax deducted at source according to the employee’s income tax slab. )", checked:true},
  ]);

  const earningComponentHeadings = [
    { label: "Salary Component", style: {...Headings, width:"19.71rem"} },
    { label: "Calculation Type", style: {...Headings, width:"14.81rem"} },
    { label: "Monthly Amount", style: {...Headings, width:"12.0625rem"} },
    { label: "Taxability", style: {...Headings, width:"8.43rem"} },
    { label: "Actions", style: { ...Headings, width:"10rem"} }
  ];

  return (
     <Box sx={{width:"100%", paddingBlock:"1rem"}}>
        <TopBar 
          MainHeading={`Salary Template`} 
          breadcrumbs={[{label:"Payroll", href:""},{label:"Edit Salary Template", href:""}]}
        />
        <MainBox sx={{ padding: "1.375rem 1.25rem"}}>
            <Box sx={{display:"flex", justifyContent:"space-between", flexWrap:"wrap"}}>
                <FormField
                    label="Salary Templates"
                    type="select"
                    options={[]}
                    value={selectedSalaryTemplate}
                    onChange={(e)=>{setSelectedSalaryTemplate(e.target.value)}}
                />
                <Box>
                    <CheckboxField
                        label="Revert Back to the Default Salary structure setting"
                        checked={revertBackToDefault}
                        onChange={(isChecked) => {setRevertBackToDefault(isChecked)}}
                        labelStyle={{fontWeight:"300",fontSize: "1.05rem", lineHeight: "1.43rem",letterSpacing:"-2.5%"}}
                    />
                </Box>
                <Box sx={{width:"48%", display:"flex", alignItems:"center", gap:"1rem"}}>
                    <Box>Annual CTC</Box>
                    <FormField
                        type="text"
                        value={selectedSalaryTemplate}
                        onChange={(e)=>{setSelectedSalaryTemplate(e.target.value)}}
                    />
                    <Box>per year</Box>
                </Box>
            </Box>
            <ComponentHeading heading="Earning Component"/>
            <PaginationTable
                rows={earningComponentData}
                headings={earningComponentHeadings}
                noRecordMessage={"No Record Found"}
                paginationHide={true}
                renderCell={(row) => (
                <>
                    <TableCell sx={{...CustomTableCell, width:"19.71rem"}}></TableCell>
                    <TableCell sx={{...CustomTableCell, width:"14.81rem"}}></TableCell>
                    <TableCell sx={{...CustomTableCell, width:"12.0625rem"}}></TableCell>
                    <TableCell sx={{...CustomTableCell, width:"8.43rem"}}></TableCell>
                    <TableCell sx={{...CustomTableCell, width:"10rem"}}></TableCell>
                </>
                )}
            />
            <ComponentHeading heading="Statutory / Mandatory Deduction"/>
            <PaginationTable
                rows={statutaryComponentData}
                noRecordMessage={"No Record Found"}
                paginationHide={true}
                renderCell={(row) => (
                <>
                    <TableCell sx={{width:"80%"}}>
                       <Box sx={{fontSize:"1rem", lineHeight:"1.5rem"}}>{row?.name}</Box>
                       <Box sx={{fontSize:"0.75rem", lineHeight:"1.125rem", color:"#7A7878", fontWeight:"300"}}>
                        {row?.helperText}
                       </Box>
                    </TableCell>
                    <TableCell sx={{width:"20%"}}>
                    <SwitchToggle 
                        checked={row?.checked} 
                        // onChange={}
                    />
                    </TableCell>
                </>
                )}
            />
            <ComponentHeading heading="Deduction Component"/>
            <ComponentHeading heading="Reimbursements"/>
            <Box>
               Note: Any changes made to the salary components will take effect in the current pay run, provided it is not Approved.
            </Box>
            <Box sx={{display:"flex", justifyContent:"end", gap:"1.25rem", marginTop:"3.5rem"}}>
                 <BtnPrimary sx={{width:"5.6875rem", height:"3.125rem", borderRadius:"10px"}}>
                    Update
                 </BtnPrimary>
                 <BtnSecondary sx={{width:"5.6875rem", height:"3.125rem", borderRadius:"10px"}}>
                    Save
                 </BtnSecondary>
            </Box>
        </MainBox>
     </Box>
  );
};

export default SalaryTemplate;
