import React from "react";
import styled from "@emotion/styled";
import { TextField, Box, MenuItem,Autocomplete, InputAdornment } from "@mui/material";
import CalenderIcon from "../assets/icons/calendar-dash.svg";
import HolidayDate from "./DateSelectDialog";
import ErrorMsg from './ErrorMsg';

const TextBox = styled(Box)({
    display: "block",
    wordBreak: "break-word",
    fontSize: "1rem",
    fontWeight: "500",
    color:"#5C5C5C",
    lineHeight:"1.5rem",
    marginTop: ".7812rem",
});

const InputStyle = {
    fontSize: "1rem", 
    lineHeight: '1.5rem',
    backgroundColor: "#F5F8FF",
    borderRadius: "4px",
    padding: "1"
}

export const FormField = ({width,label,type,value,onChange,options}) =>{
    return(
        <Box width={width}>
            <TextBox>{label}</TextBox>
            {type==="select"?(
                    <TextField
                        select
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={value}
                        onChange={onChange}
                        sx={{
                            '& .MuiInputBase-root': InputStyle,
                            "& .MuiOutlinedInput-notchedOutline":{
                                border: "1px solid #E7E7ED",
                            }
                        }}
                        >
                        {options.map((option, index) => (
                            <MenuItem key={index} value={option}>
                            {option}
                            </MenuItem>
                        ))}
                    </TextField>
            ):(
                    <TextField
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={value}
                        onChange={onChange}
                        sx={{
                        "& .MuiInputBase-root": InputStyle,
                        "& .MuiOutlinedInput-notchedOutline": {
                            border: "1px solid #E7E7ED",
                        },
                        }}
                />
            )}
        </Box>
    )
}

export const AutocompleteField =({width,label,value,onChange,options}) =>{
    return(
        <Box width={width}>
            <TextBox>{label}</TextBox>
            <Autocomplete
                  options={options}
                  size="small"
                  value={value}
                  onChange={onChange}
                  sx={{
                    '& .MuiInputBase-root': InputStyle,
                      "& .MuiOutlinedInput-notchedOutline":{
                        border: "1px solid #E7E7ED",
                      }
                  }}
                  renderInput={(params) => <TextField {...params}/>}
               />
        </Box>
    )
}

export const DateField = ({ label, value,fullwidth, isDialogOpen, openDialog, closeDialog, onSelect , disabled, validation, past}) => {
    return (
      <Box sx={{ width: fullwidth?'100%':'48%'}}>
        <TextBox>{label}</TextBox>
        <TextField
          placeholder="YYYY-MM-DD"
          value={value || ''}
          onClick={openDialog}
          variant="outlined"
          size="small"
          disabled={disabled}
          fullWidth
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <img src={CalenderIcon} alt="Calendar" />
              </InputAdornment>
            ),
          }}
          sx={{
            "& .MuiInputBase-root": InputStyle,
            "& .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #E7E7ED",
            }
          }}
        />
        <ErrorMsg>{validation}</ErrorMsg>
        <HolidayDate open={isDialogOpen} onClose={closeDialog} onSelect={onSelect} past={past}/>
      </Box>
    );
  };
  
 
  