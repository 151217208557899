import React,{useState, useEffect} from 'react';
import { Box, Avatar, CircularProgress} from "@mui/material";
import styled from '@emotion/styled';
import BtnSecondary from "../SecondaryButton";
import { getLegalUnitList, getOrgDepartments, getOrgEmployees, getOrgGroups } from "../../services/globalData";
import { useLocation } from "react-router-dom";
import { getAllExpenseCategory, getPolicyById, linkPolicy, unlinkPolicy } from '../../services/expense';
import ProgressBar from '../ProgressBar';
import DocsIcon from '../../assets/icons/docs.svg';
import { findGroup } from '../../utils/findGroup';
import { findDepartment } from '../../utils/findDepartment';
import FormField from '../Formfield';
import { currentUserDetails } from '../../utils/currentUserDetails';
import { updatePolicy } from '../../services/policy';
import DateField from '../DateField';
import Pencil from '../../assets/icons/pencil.svg';
import BtnPrimary from '../PrimaryButton';
import TypeBtn from '../AddRemoveButton';
import SetLimit from '../SetLimit';
import SortByTable from '../assign-policy/SortByTable';
import { getAllVehicleType } from '../../services/policyCategory';
import EditPolicyCategory from './EditPolicyCategory';
import ErrorMsg from '../ErrorMsg';
import SaveDialog from '../SaveDialog';


const HeadingBox = styled(Box)({
        fontSize: "1rem",
        lineHeight: "1.5rem",
});

const HeaderSection = ({type}) => (
  <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1.9375rem', alignItems: 'end' }}>
      <Box>
          <Box sx={{ display: 'flex' }}>
              <Avatar src={DocsIcon} style={{ width: '1.5rem', height: '1.5rem' }} />
              <Box sx={{
                      fontWeight: '300',
                      fontSize: '1rem',
                      lineHeight: '1.5rem',
                      color: '#0081FF',
                      marginBottom: '0.56rem',
                  }}
              >
                  {type==="ANY"?"Expense":"Trip"} Policies
              </Box>
          </Box>
          <ProgressBar />
      </Box>
      <BtnSecondary 
         sx={{ width: '5.56rem', height: '2.25rem', borderRadius: '10px' }} 
         to={type==="ANY"?"/app/expense-policy/listing":"/app/trips/listing"}
      >
          Back
      </BtnSecondary>
  </Box>
);

const Headings = ({title,onEditClick, onSaveClick, edit, apiCall}) => (
    <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", marginBottom:"1.875rem" }}>
      <HeadingBox>{title}</HeadingBox>
      {
        edit?(
          <BtnPrimary sx={{ width: '10rem', height: '2.25rem', borderRadius: '10px' }} onClick={onSaveClick}>
            {apiCall?<CircularProgress size={"1rem"}/>:"Save Changes"}
          </BtnPrimary>
        ):<Avatar src={Pencil} sx={{width:"1.5rem",height:"1.5rem",'&:hover': {cursor: "pointer"}}} onClick={onEditClick}/>
      }
    </Box>
)


const EditPolicy = ({type}) => {
        const {state} = useLocation();
        const createdBy = currentUserDetails().fname+" "+currentUserDetails().lname;
        const [loading, setLoading] = useState(true);
        const [apiCall,setApiCall]=useState(false);
        const [isSaveOpen, setIsSaveOpen]=useState(false);
        const [policyData,setPolicyData] = useState({
              code:"",
              name:"",
              startDate:null,
              endDate:null,
              description:"",
              policyCateogary:[],
              approverIds:[],
        });
        const [startDateDialogOpen, setStartDateDialogOpen] = useState(false);
        const [endDateDialogOpen, setEndDateDialogOpen] = useState(false);
        const [validationErrors, setValidationErrors] = useState({
                                                                  code:"",
                                                                  name:"",
                                                                  startDate:"",
                                                                  endDate:"",
                                                                });
        const [error,setError]=useState("");
        const [categoryOptions, setCategoryOptions] = useState([]);
        const approverOptions = getOrgEmployees().map(employee => ({
                    name: employee.fname+" "+employee.lname,
                    value: employee._id
        }));
        const [sortBy,setSortBy] = useState("");
        const [detailsEdit,setDetailsEdit]=useState(false);
        const [categoryEdit,setCategoryEdit]=useState(false);
        const [assignPolicyEdit,setAssignPolicyEdit]=useState(false);
        const [approverEdit,setApproverEdit]=useState(false);
        const [isLimitDialogOpen, setIsLimitDialogOpen] = useState(false);
        const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(null); 
        const [selectedList,setSelectedList] = useState({department: [],employeeGroup:[]});
        const [previousList,setPreviousList] = useState({department: [],employeeGroup:[]});
        const [data, setData] = useState();
        const allDepartments = getOrgDepartments();
        const allGroups = getOrgGroups();

        useEffect(()=>{
          setData(sortBy==="Departments"?allDepartments:allGroups);
        },[sortBy])

        useEffect(()=>{
                getCategoryList();
                getPolicyData(state.policyId);
        },[]);
            
        //to get expense category options
        const getCategoryList = async () => {
        try {
                const legalUnit = getLegalUnitList();
                const res = type==="ANY"?await getAllExpenseCategory(legalUnit[0]._id):await getAllVehicleType(legalUnit[0]._id);
                const categories = res.data.map(category => ({ value: category._id, name: category.name, code: category.code}));
                setCategoryOptions(categories);
        } catch (error) {
                console.error('Error fetching data:', error);
        }
        };

        //to get policy data
        const getPolicyData = async (policyId) => {
                setLoading(true);
                try {
                    const res = await getPolicyById(policyId); 
                    const data = res.data;
                    console.log(data);
                    if(res.success){
                          setPolicyData(prevState => ({
                            ...prevState,
                            code: data?.code,
                            name: data?.name,
                            startDate: data?.startDate?.split('T')[0],
                            endDate: data?.endDate?.split('T')[0],
                            description: data?.description,
                            approverIds: data?.approverIds,
                            policyCateogary: data?.policyCateogary,
                            linkTo: data?.linkTo
                        })); 
                    }
                    if(data?.linkTo?.employeeGroup?.length>0){
                       setSortBy("Groups");
                       setSelectedList(prevState => ({...prevState,employeeGroup: data?.linkTo?.employeeGroup}));
                       setPreviousList(prevState => ({...prevState,employeeGroup: data?.linkTo?.employeeGroup}));
                    }else if(data?.linkTo?.department?.length>0){
                      setSortBy("Departments");
                      setSelectedList(prevState => ({...prevState,department: data?.linkTo?.department}));
                      setPreviousList(prevState => ({...prevState,department: data?.linkTo?.department}));
                    }
                } catch (error) {
                    console.error('Error fetching policy data:', error);
                }
                setLoading(false);
        };
          
        //to manage input
        const handleChange = (field, value) => {
                const updatedValue = field === "code" ? value?.toUpperCase() : value;
                setPolicyData(prevState => ({...prevState, [field]: updatedValue}));
                setValidationErrors(prevState => ({...prevState, [field]: ""}));
                setError("");
        };

        //setting limit for categories
        const handleLimitDialogClose = (limits) => {
            setIsLimitDialogOpen(false);
            if (limits && selectedCategoryIndex !== null) {
            let updatedExpenseCategories = [...policyData?.policyCateogary];
            updatedExpenseCategories[selectedCategoryIndex].limits = limits; 
            setPolicyData((prev)=>({...prev,policyCateogary:updatedExpenseCategories}));
            }
            setSelectedCategoryIndex(null); 
        };

        //to display selected policy bearer
        const formatPolicyBearer = (linkTo) =>{
          const employeeGroups = linkTo?.employeeGroup?.map(findGroup)?.map(obj => obj?.name).filter(Boolean).join(', ') || '';
          const departments = linkTo?.department?.map(findDepartment)?.map(obj => obj?.name).filter(Boolean).join(', ') || '';
          if(employeeGroups || departments)
              return `${employeeGroups}${employeeGroups && departments ? ', ' : ''}${departments}`;
          else
              return "No Policy Bearer Assigned Yet"
        }

        const handleAddApproval = () =>{
                setPolicyData(prevState => ({
                        ...prevState,
                        approverIds: [...prevState.approverIds, ""]
                }));
        }
            
        const handleRemoveApprover = (index) => {
                setPolicyData(prevState => {
                    const approverList = [...prevState.approverIds];
                    approverList.splice(index, 1);
                    return {
                        ...prevState,
                        approverIds: approverList
                    };
                });
        };
        
        const handleApproverChange = (index, value) => {
                setPolicyData(prevState => {
                    const updatedApprover = [...prevState.approverIds];
                    updatedApprover[index] = value;
                    return {
                        ...prevState,
                        approverIds: updatedApprover
                    };
                });
        };
            
      const handleSaveChanges = async() =>{
        setError("");
        setApiCall(true);
        const res = await updatePolicy(state.policyId,policyData);
        handlePolicyLinking();
        setApiCall(false);
        setDetailsEdit(false);
        setAssignPolicyEdit(false);
        setApproverEdit(false);
        setCategoryEdit(false);
      }

      const handlePolicyLinking = async() =>{
        setError("");
        setApiCall(true);
        const departmentsToRemove = previousList?.department?.filter(dept => !selectedList?.department?.includes(dept));
        const groupsToRemove = previousList?.employeeGroup?.filter(grp => !selectedList?.employeeGroup?.includes(grp));

        const res = await unlinkPolicy(state.policyId, {department:departmentsToRemove, employeeGroup: groupsToRemove});
        if(!res?.success){
          setError(res?.message);
          setApiCall(false);
          return;
        }

        const departmentsToAdd = selectedList?.department?.filter(dept => !previousList?.department?.includes(dept));
        const groupsToAdd = selectedList?.employeeGroup?.filter(grp => !previousList?.employeeGroup?.includes(grp));

        const result = await linkPolicy(state.policyId, { linkTo: {department:departmentsToAdd, employeeGroup: groupsToAdd}});
        if(!result?.success){
          setError(result?.message==="Duplicate policy cateogary"
                   ?"Duplicate policy category error - can't assign same policy category twice":result?.message);
          setApiCall(false);
          return;
        }

        setApiCall(false);
        setAssignPolicyEdit(false);
      }

      const handleNextClick = () =>{
        handleSaveChanges();
        setIsSaveOpen(true);
      }

  return (
    loading?(
      <Box sx={{width:"100%",display:"flex", justifyContent:"center", alignItems:"center", height:"100vh"}}>
        <CircularProgress/>
      </Box>
    ):(
    <Box>
        <HeaderSection type={type}/>
        <Box sx={{marginBottom: '1.9375rem'}}>
            <Headings 
             title="Policy Details" 
             edit={detailsEdit} 
             onEditClick={()=>setDetailsEdit(true)} 
             onSaveClick={handleSaveChanges}
             apiCall={apiCall}
            />
            <Box sx={{ width: "100%", display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                      <FormField
                          label="Policy Code"
                          type="text"
                          value={policyData.code}
                          validation={validationErrors.code}
                          disabled={!detailsEdit}
                          onChange={(e) => handleChange('code', e.target.value)}
                      />
                      <FormField
                          label="Policy Name"
                          type="text"
                          value={policyData.name}
                          validation={validationErrors.name}
                          disabled={!detailsEdit}
                          onChange={(e) => handleChange('name', e.target.value)}
                      />
                      <FormField
                          label="Created By"
                          type="text"
                          value={createdBy}
                          disabled={true}
                      />
                      <FormField
                        label="Description"
                        type="text"
                        value={policyData.description}
                        fullwidth
                        row={2}
                        disabled={!detailsEdit}
                        onChange={(e) => handleChange('description', e.target.value)}
                      />
                      <DateField
                        label="Policy Valid From"
                        value={policyData.startDate}
                        isDialogOpen={startDateDialogOpen}
                        validation={validationErrors.startDate}
                        openDialog={() => setStartDateDialogOpen(true)}
                        closeDialog={() => setStartDateDialogOpen(false)}
                        onSelect={(date) => {
                                  handleChange('startDate', date)
                                  setStartDateDialogOpen(false)
                                  }}
                        disabled={!detailsEdit}
                      />
                      <DateField
                        label="Policy Valid Till"
                        value={policyData.endDate}
                        isDialogOpen={endDateDialogOpen}
                        validation={validationErrors.endDate}
                        openDialog={() => setEndDateDialogOpen(true)}
                        closeDialog={() => setEndDateDialogOpen(false)}
                        onSelect={(date) => {
                                  handleChange('endDate', date)
                                  setEndDateDialogOpen(false)
                                }}
                        disabled={!detailsEdit}
                      />
            </Box>
        </Box>
        <Box sx={{marginBottom: '1.9375rem'}}>
            <Headings 
             title="Add Category" 
             edit={categoryEdit} 
             onEditClick={()=>setCategoryEdit(true)} 
             onSaveClick={handleSaveChanges}
             apiCall={apiCall}
            />
            <EditPolicyCategory
              categoryEdit={categoryEdit}
              policyData={policyData}
              setPolicyData={setPolicyData}
              categoryOptions={categoryOptions}
              setIsLimitDialogOpen={setIsLimitDialogOpen}
              setSelectedCategoryIndex={setSelectedCategoryIndex}
              type={type}
            /> 
        </Box>
        <Box sx={{marginBottom: '1.9375rem'}}>
            <Headings 
             title="Assign Policy" 
             edit={assignPolicyEdit} 
             onEditClick={()=>setAssignPolicyEdit(true)} 
             onSaveClick={handlePolicyLinking}
             apiCall={apiCall}
            />
            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems:"center" }}>
                        <FormField
                            label="Sort By"
                            type="select"
                            options={["Departments","Groups"]}
                            value={sortBy}
                            disabled={!assignPolicyEdit}
                            onChange={(e)=>{setSortBy(e.target.value)}}
                        />
                        <FormField
                            label="Policy Bearers"
                            type="text"
                            value={formatPolicyBearer(selectedList)}
                            disabled={true}
                        />
            </Box>
            <Box sx={{marginBottom:"1rem"}}>
                <ErrorMsg>{error}</ErrorMsg>
            </Box>
            {(sortBy && assignPolicyEdit) && (
              <SortByTable
                sortBy={sortBy}
                data={data}
                selectedList={selectedList}
                setSelectedList={setSelectedList}  
              />
            )}   
        </Box>
        <Box>
            <Headings 
             title="Rules" 
             edit={approverEdit} 
             onEditClick={()=>setApproverEdit(true)} 
             onSaveClick={handleSaveChanges}
             apiCall={apiCall}
            />
            <Box>
            <Box>
            {   policyData?.approverIds?.length > 0 ? (
                <Box>
                 <Box sx={{fontSize: "1rem",lineHeight: "1.5rem", color:"#818181",marginBottom:"1rem"}}>
                    Approval Chain
                  </Box>
                 {
                  policyData?.approverIds?.map((item,index)=>(
                    <Box key={index} 
                         sx={{ width: "100%", 
                               display: "flex",  
                               marginBottom: "1rem", 
                               justifyContent:"space-between",
                               padding:"1.125rem 1.875rem",
                               borderRadius:"10px",
                               border:"1px solid #A2A1A833"
                            }}
                    >
                          <FormField
                              placeholder="Employee Name"
                              type="select"
                              options={approverOptions}
                              value={item}
                              disabled={!approverEdit}
                              onChange={(e) => handleApproverChange(index, e.target.value)}
                          />
                          <TypeBtn type="remove" onClick={()=>handleRemoveApprover(index)} disabled={!approverEdit}>
                                  Remove 
                          </TypeBtn>
                    </Box>
                  ))
                  }
                </Box>
              ): (
                <Box sx={{fontSize:"1rem",lineHeight:"1.5rem", color: '#818181', marginBottom:"1rem"}}>
                        No approvers assigned yet
                </Box>
              )
            }
            <Box sx={{display:approverEdit?"flex":"none"}}>
                <TypeBtn onClick={handleAddApproval} type="add">
                    Add Approver
                </TypeBtn>
            </Box>
          </Box>
        </Box>
        </Box>
        <Box sx={{display:"flex", justifyContent:"center", gap:"1rem", marginTop:"1.875rem"}}>
              <BtnPrimary 
                onClick={handleNextClick} 
                sx={{width:"18.75rem", height:"3.125rem", borderRadius:"10px"}}
                disabled={apiCall}
              >
                  {apiCall?<CircularProgress color='info'/>:"Save Changes"}
              </BtnPrimary> 
        </Box>
        <SetLimit
            open={isLimitDialogOpen}
            limits={
              policyData?.policyCateogary[selectedCategoryIndex]?.limits?.length>0 
              ? policyData?.policyCateogary[selectedCategoryIndex]?.limits 
              : [{limit:"",frequency:"",instances:"",frequencyType:""}]
            }
            onClose={handleLimitDialogClose}
        />
        <SaveDialog 
            open={isSaveOpen} 
            onClose={()=>setIsSaveOpen(false)} 
            redirectLink={type==="ANY"?"/app/expense-policy/listing":"/app/trips/listing"}
        />
    </Box>
  ))
}

export default EditPolicy
        