import React from 'react';
import { Box} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';

function Calendar({selectedDate, setSelectedDate}) {

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StaticDatePicker
            onChange={handleDateChange}
            value={selectedDate}
            showDaysOutsideCurrentMonth
            dayOfWeekFormatter={(_day, weekday) => `${weekday.format('ddd')}`}
            sx={{
              width: "100%",
              height: "100%",
              border: "none",
              padding: 0,
              "& .MuiPickersToolbar-root": {
                display: "none"
              },
              "& .MuiDialogActions-root": {
                display: "none"
              },
              "& .MuiPickersLayout-contentWrapper":{
                border:"none",
              },
              "& .MuiDateCalendar-root":{
                width:"477px",
                height:"100%"
              },
              "& .MuiTypography-root":{
                fontSize:"1.125rem",
                lineHeight:"1.5rem",
                marginInline:"0.96rem",
                marginBottom:"1rem",
                color:"#8F9BB3"
              },
              "& .MuiButtonBase-root":{
                fontSize:"1.43rem",
                fontWeight:"500",
                lineHeight:"1.875rem",
                marginInline:"0.96rem",
                marginBottom:"0.6rem",
              }
            }}
          />
        </LocalizationProvider>
    </Box>
  );
}

export default Calendar;

