import React, { useState } from 'react'
import PaginationTable from '../../../../../../../component/PaginationTable'
import { useOutletContext } from 'react-router';
import { Box } from '@mui/material';
import { MoreVert } from '@mui/icons-material';

const Earnings = () => {
  const [CustomTableCell,Headings] = useOutletContext();
  const [data,setData] = useState([{
    name:"House Rent Allowance",
    type:"Basic",
    calcType:"Fixed; 50% of CTC",
    epf:"yes",
    esi:"yes",
    status:"Active"
  }]);

  const headings = [
    { label: "Name", style: {...Headings, borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px" } },
    { label: "Earning Type", style: {...Headings} },
    { label: "Calculation Type", style: {...Headings} },
    { label: "Consider For EPF", style: {...Headings} },
    { label: "Consider For ESI", style: {...Headings} },
    { label: "Status", style: {...Headings} },
    { label: "", style: { ...Headings, borderTopRightRadius: "12px", borderBottomRightRadius: "12px" } }
    ];

  return (
    <PaginationTable
            rows={data}
            headings={headings}
            noRecordMessage={"No Record Found"}
            renderCell={(row) => (
                <>
                    <CustomTableCell>{row?.name}</CustomTableCell>
                    <CustomTableCell >{row.type}</CustomTableCell>
                    <CustomTableCell>{row.calcType}</CustomTableCell>
                    <CustomTableCell>{row.epf}</CustomTableCell>
                    <CustomTableCell>{row.esi}</CustomTableCell>
                    <CustomTableCell>
                    <Box sx={{
                          borderRadius: "8px",
                          color: "white",
                          width:"5rem",
                          backgroundColor: "#50c878",
                          padding: "3px 8px",
                          fontSize:"0.75rem",
                          lineHeight:"1.5rem",
                          textAlign:"center"
                        }}>
                          {row.status}
                     </Box>
                    </CustomTableCell>
                    <CustomTableCell>
                       <MoreVert/>
                    </CustomTableCell>
                </>
            )}
    />
  )
}

export default Earnings