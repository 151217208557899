import React, { Suspense } from "react";
import Spinner from "../component/Spinner";
const SignupPage = React.lazy(() => import("../app/signup"));

function Login() {
  return (
    <>
      <Suspense fallback={<Spinner />}>
        <SignupPage />
      </Suspense>
    </>
  );
}

export default Login;
