import React, {useState} from "react";
import {Box, CircularProgress} from "@mui/material";
import TopBar from "../../../../../../../../component/TopBar";
import MainBox from "../../../../../../container/MainBox";
import { Outlet } from "react-router";

export default function Requests(){

  const [loading,setLoading] = useState(false);

  return (
    loading?(
      <Box sx={{width:"100%",display:"flex", justifyContent:"center"}}>
        <CircularProgress/>
      </Box>
    ):(
     <Box sx={{width:"100%", paddingBlock:"1rem"}}>
        <TopBar 
          MainHeading={"Attendance"} 
          breadcrumbs={[
            {label:"Attendance"},
            {label:"Dashboard", href:"/app/attendance-dashboard"},
            {label:"Requests"}
          ]}
        />
        <MainBox sx={{ padding: "2.5rem 1.25rem"}}>
            <Outlet/>
        </MainBox>
     </Box>
    )
  );
};
