import { getOrgEmployees, getOrgGroups} from "../services/globalData";

export const getEmployeeListFromDepartments = (department) => {

  const filteredEmployees = getOrgEmployees()?.filter((emp)=>{
     return department?.includes(emp.department);
  });
  return filteredEmployees;
};

export const getEmployeeListFromGroups = (employeegrp) => {
  const orgGroups = getOrgGroups();
  const allEmployees = getOrgEmployees();

  // Filter groups based on employeegrp array
  const selectedGroups = orgGroups.filter(group => employeegrp.includes(group._id));
  console.log(selectedGroups);

  // Extract employee IDs from the selected groups
  const employeeIds = selectedGroups.flatMap(group => group.employee);
  console.log(employeeIds);

  // Filter employees based on the extracted employee IDs
  const employeesInfo = allEmployees.filter(employee => employeeIds.includes(employee._id));
  console.log(employeesInfo);

  return employeesInfo;
};