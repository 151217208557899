import React,{useState, useEffect} from 'react';
import {Box} from "@mui/material";
import styled from '@emotion/styled';
import BtnPrimary from "../../../../../../../component/PrimaryButton";
import BtnSecondary from "../../../../../../../component/SecondaryButton";
import { useNavigate, useLocation } from 'react-router';
import FormField from '../../../../../../../component/Formfield';
import RadioButtonGroup from '../../../../../../../component/RadioButtonGroup';
import CheckboxField from '../../../../../../../component/CheckboxField';

const InfoBox = styled(Box)(() => ({
    width:"100%", 
    backgroundColor:"#FEF3C7", 
    padding:"12px", 
    color:"#16151CCC", 
    fontWeight:"300",
    fontSize:"0.875rem",
    lineHeight:"1.5rem",
    border:"1px solid #A2A1A8",
    borderRadius:"10px",
    marginTop:"1.5rem"
}));

const checkBoxLabel = {fontSize: "1rem", lineHeight: "1.5rem", color: "#141414" }

const NewComponent = () => {
    const {state} = useLocation();

    const [componentForm, setComponentForm] = useState({
        earningType:state.type,
        earningName:"",
        nameInPayslip:"",
        active:true,
        makePartOfSalaryStructure:true,
        epf:false,
        esi:false,
        showInPayslip:true,
        calcType:"flat Amount",
    });

  const handleChange = (field, value) => {
    setComponentForm(prevState => ({
            ...prevState,
            [field]: value 
        }));
  };

  const navigate = useNavigate();
  const handleSave = () =>{

  }

  return (
     <Box sx={{width:"100%"}}>
          <Box>
            <Box sx={{display:"flex", gap:"1.5rem"}}>
                 <Box sx={{flex:1}}>
                    <FormField
                        label="Earning Type"
                        type="text"
                        fullwidth
                        value={componentForm.earningType}
                        disabled={true}
                    />
                    <FormField
                        label="Earning Name"
                        type="text"
                        fullwidth
                        value={componentForm.earningName}
                        onChange={(e) => handleChange('earningName', e.target.value)}
                    />
                    <FormField
                        label="Name in Payslip"
                        type="text"
                        fullwidth
                        value={componentForm.nameInPayslip}
                        onChange={(e) => handleChange('nameInPayslip', e.target.value)}
                    />
                    <RadioButtonGroup
                        label="Calculation Type"
                        display="col"
                        value={componentForm.calcType} 
                        onChange={(e) =>  handleChange('calcType', e.target.value)}
                        options={[
                            { value: 'flat Amount', label: 'Flat Amount' },
                            { value: 'percent of basic', label: 'Percentage of Basic' }
                        ]}
                    />
                    <CheckboxField
                        label="Mark this as Active"
                        checked={componentForm.active}
                        onChange={(isChecked) => handleChange('active', isChecked)}
                        labelStyle={{fontSize: "1rem", lineHeight: "1.5rem", color: "#141414" }}
                    />
                 </Box>
                 <Box sx={{flex:1}}>
                        <InfoBox sx={{height:"3.5rem"}}>
                            Fixed amount paid at the end of every month.
                        </InfoBox>
                        <Box sx={{fontWeight:"300", fontSize:"1rem", lineHeight:"1.5rem", marginBlock:"1.5rem"}}>
                            Other Configurations
                        </Box>
                        <Box sx={{display:"flex", flexDirection:"column"}}>
                        <CheckboxField
                            label="Make this earning a part of the employee's salary structure"
                            checked={componentForm.makePartOfSalaryStructure}
                            onChange={(isChecked) => handleChange('makePartOfSalaryStructure', isChecked)}
                            labelStyle={checkBoxLabel}
                       />
                       <CheckboxField
                            label=" This is a taxable earning"
                            helpText="The income tax amount will be divided equally and deducted
                            every month across the financial year."
                            checked={componentForm.leaveEncashment}
                            onChange={(isChecked) => handleChange('leaveEncashment', isChecked)}
                            labelStyle={checkBoxLabel}
                       />
                       <CheckboxField
                            label="Calculate on pro-rata basis"
                            helpText="Pay will be adjusted based on employee working days."
                            checked={componentForm.leaveEncashment}
                            onChange={(isChecked) => handleChange('leaveEncashment', isChecked)}
                            labelStyle={checkBoxLabel}
                       />
                       <CheckboxField
                            label="Include this as a Flexible Benefit Plan component"
                            checked={componentForm.leaveEncashment}
                            onChange={(isChecked) => handleChange('leaveEncashment', isChecked)}
                            labelStyle={checkBoxLabel}
                       />
                       <CheckboxField
                            label="Consider for EPF Contribution"
                            checked={componentForm.epf}
                            onChange={(isChecked) => handleChange('epf', isChecked)}
                            labelStyle={checkBoxLabel}
                       />
                       <CheckboxField
                            label="Consider for ESI Contribution"
                            checked={componentForm.esi}
                            onChange={(isChecked) => handleChange('esi', isChecked)}
                            labelStyle={checkBoxLabel}
                       />
                       <CheckboxField
                            label="Show this component in payslip"
                            checked={componentForm.showInPayslip}
                            onChange={(isChecked) => handleChange('showInPayslip', isChecked)}
                            labelStyle={checkBoxLabel}
                       />
                       </Box>
                 </Box>
            </Box> 
            <InfoBox>
               Note: Once you associate this component with an employee, you will only be able to edit the Name and Amount/Percentage. The changes you make to Amount/Percentage will apply only to new employees.
            </InfoBox>
            <Box sx={{display:"flex", gap: "1.25rem", marginBlock: "1.875rem", justifyContent:"flex-end" }}>
                    <BtnPrimary 
                    onClick={handleSave}
                    sx={{ borderRadius: "10px", width: "5.68rem", height: "3.125rem" }}>
                        Save
                    </BtnPrimary>
                    <BtnSecondary 
                    onClick={() => navigate(-1)}
                    sx={{ borderRadius: "10px", width: "5.68rem", height: "3.125rem" }}>
                        Cancel
                    </BtnSecondary>
            </Box>
         </Box>
     </Box>
      
  );
}

export default NewComponent