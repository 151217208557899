import React, { Suspense } from "react";
import Spinner from "../component/Spinner";
const LoginPage = React.lazy(() => import("../app/login"));

function Login() {
  return (
    <>
      <Suspense fallback={<Spinner />}>
        <LoginPage />
      </Suspense>
    </>
  );
}

export default Login;
