import { postCall, getCall } from "./api";
import {
  getLegalUnitList,
  getActiveLegalUnit,
  setActiveLegalUnit,
} from "./globalData";

export const addGeofence = async (input = {}) => {
  let activelLegalUnit = getActiveLegalUnit();
  if (activelLegalUnit) {
    activelLegalUnit = activelLegalUnit._id;
  } else {
    const allLegalUnit = getLegalUnitList();
    if (allLegalUnit.length > 0) {
      activelLegalUnit = allLegalUnit[0]._id;
      setActiveLegalUnit(allLegalUnit[0]);
    }
  }
  if (activelLegalUnit) {
    const request = {
      path: `/legal-unit-geofence/${activelLegalUnit}/geofence/`,
      bodyParams: input,
    };
    return await postCall(request);
  }
};

export const getGeofence = async () => {
  let activelLegalUnit = getActiveLegalUnit();
  if (activelLegalUnit) {
    activelLegalUnit = activelLegalUnit._id;
  } else {
    const allLegalUnit = getLegalUnitList();
    if (allLegalUnit.length > 0) {
      activelLegalUnit = allLegalUnit[0]._id;
      setActiveLegalUnit(allLegalUnit[0]);
    }
  }
  if (activelLegalUnit) {
    const request = {
      path: `/legal-unit-geofence/${activelLegalUnit}/geofence/`,
    };
    return await getCall(request);
  }
};
