import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import Topbar from "../../../../../../component/TopBar";
import MainBox from "../../../../container/MainBox";
import { useNavigate } from "react-router";
import { PolicyTableHeadings } from "../../../../../../styles/PolicyTableHeadings";
import { PolicyTableCell } from "../../../../../../styles/PolicyTableCell";
import { formatDate, formatDateByDay } from "../../../../../../utils/formatDate";
import PaginationTable from "../../../../../../component/PaginationTable";
import PolicyListingHeader from "../../../../../../component/PolicyListingHeader";
import { getAllAttendancePolicies } from "../../../../../../services/attendancePolicy";
import { getEmployeeNames } from "../../../../../../utils/getEmployeeNames";

function AttendancePolicyListing() {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
      try {
        const res = await getAllAttendancePolicies();
        console.log(res);
        const employeeIds = res.data?.filter(data => data.createdBy)?.map(data => data.createdBy);
        const combinedData = await getEmployeeNames(res.data, employeeIds, "createdBy");
        console.log(combinedData);
        setData(combinedData?.reverse());
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };
  
    const headings = [
      { label: "Policy Code", style: {...PolicyTableHeadings, width:"7.68rem", borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px" } },
      { label: "Policy Name", style: {...PolicyTableHeadings,  width:"15.5rem"} },
      { label: "Policy Validity", style: {...PolicyTableHeadings, width:"12.56rem"} },
      { label: "Created By", style: {...PolicyTableHeadings, width:"12.56rem"} },
      { label: "Last Modified", style: { ...PolicyTableHeadings,width:"12.56rem", borderTopRightRadius: "12px", borderBottomRightRadius: "12px" } }
    ];

    const handleMenuItemClick = (id) => { 
      navigate("/app/attendance-policy/edit",{state:{policyId:id}});
    };

  return (
     <Box sx={{width:"100%",paddingBlock:"1rem"}}>
        <Topbar MainHeading={"Attendance Policy"}/>
        <MainBox sx={{ padding: "1.8125rem 1.25rem", height:"100%" }}>
                <PolicyListingHeader 
                    Heading = "Attendance Policies"
                    ButtonText = "+ Create Attendance Policy"
                    ButtonLink = "/app/attendance-policy/new/setting"
                />
                <PaginationTable
                    rows={data}
                    headings={headings}
                    loading={loading}
                    noRecordMessage={"No Record Found"}
                    renderCell={(row) => (
                        <>
                        <PolicyTableCell>{row?.code}</PolicyTableCell>
                        <PolicyTableCell 
                            onClick={() => handleMenuItemClick(row?._id)}
                        >
                          <Box sx={{width:"100%", ":hover":{cursor:"pointer"}, color:"#008cc4",textDecoration:"underline"}}>
                            {row?.name}
                          </Box>
                        </PolicyTableCell>
                        <PolicyTableCell>{formatDate(row?.startDate)}-{formatDate(row?.endDate)}</PolicyTableCell>
                        <PolicyTableCell>
                            {row?.employee?.fname + " " +row?.employee?.lname}
                        </PolicyTableCell>
                        <PolicyTableCell>{formatDateByDay(row?.updatedAt)}</PolicyTableCell>
                        </>
                    )}
                />
        </MainBox>
     </Box>
      
  );
}

export default AttendancePolicyListing;
