import React, {useState} from "react";
import {Avatar, Box, TableCell} from "@mui/material";
import MainBox from "../../../../container/MainBox";
import TopBar from "../../../../../../component/TopBar";
import BtnPrimary from '../../../../../../component/PrimaryButton';
import PaginationTable from "../../../../../../component/PaginationTable";
import { PolicyTableHeadings } from "../../../../../../styles/PolicyTableHeadings";
import styled from '@emotion/styled';
import CheckboxField from '../../../../../../component/CheckboxField';
import GreenTick from "../../../../../../assets/icons/green-tick-outlined.svg";
import RedCross from "../../../../../../assets/icons/red-cross-outlined.svg";
import {useLocation } from "react-router-dom";
import ApprovalDialog from "./ApprovalDialog";

const CustomTableCell = styled(TableCell)({
    fontWeight: "300",
    fontSize: "1rem",
    lineHeight: "1.5rem",
    textAlign:"center",
});

const Approval = () => {

  const [requests, setRequests] = useState([{}]); 
  const [selectedRequests, setSelectedRequests] = useState([]);
  const [isApprovalDialogOpen, setIsApprovalDialogOpen] = useState(false);
  const {state} = useLocation();

  const requestsHeadings = [
    { label: "", style: {...PolicyTableHeadings, width:"3rem", borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px" } },
    { label: "Employee Name", style: {...PolicyTableHeadings,  width:"10rem"} },
    { label: "Employee Id", style: {...PolicyTableHeadings,  width:"10rem"} },
    { label: state?.type==="LOP"?"LOP days":"Total days", style: {...PolicyTableHeadings,  width:"10rem"} },
    { label: state?.type==="LOP"?"Comment":"Leave Type", style: {...PolicyTableHeadings,  width:"7.5rem"} },
    { label: "Status", style: {...PolicyTableHeadings,  width:"7.5rem"} },
    { label: "Approver", style: {...PolicyTableHeadings,  width:"10rem"} },
    { label: "Action", style: { ...PolicyTableHeadings,width:"6.25rem", borderTopRightRadius: "12px", borderBottomRightRadius: "12px" } }
    ];

    const handleCheckboxChange = (id, isChecked) => {
        setSelectedRequests((prevList) => {
            if (isChecked) {
                return [...prevList, id];
            } else {
                return prevList.filter(employeeId => employeeId !== id);
            }
        });
    };
  

  return (
     <Box sx={{width:"100%", paddingBlock:"1rem"}}>
        <TopBar 
          MainHeading={"Payroll"} 
          breadcrumbs={[{label:"Settings", href:""},{label:"Payroll", href:""},{label:"Pay Group Detail", href:""}]}
        />
        <MainBox sx={{ padding: "1.25rem 1.375rem", display:"flex", flexDirection:"column", gap:"1.5rem"}}>
                    <Box sx={{fontWeight:"500", fontSize:"1.25rem", lineHeight:"1.875rem"}}>Approvals</Box>
                    <Box sx={{display:"flex",gap:"0.5rem", fontWeight:"500", fontSize:"1.25rem", lineHeight:"1.875rem"}}>
                        <Box sx={{color: state?.type==="LOP"?"#0081FF":"#0081FF4D"}}>LOP</Box>
                        <Box sx={{color:"#0081FF"}}>/</Box>
                        <Box sx={{color: state?.type==="no-attendance"?"#0081FF":"#0081FF4D"}}>No Attendance</Box>
                    </Box>
                    <PaginationTable
                        rows={requests}
                        headings={requestsHeadings}
                        noRecordMessage={"No Record Found"}
                        renderCell={(row) => (
                        <>
                            <CustomTableCell>
                                <CheckboxField
                                    checked={(selectedRequests.includes(row._id))}
                                    onChange={(isChecked) => handleCheckboxChange(row._id, isChecked)}
                                />
                            </CustomTableCell>
                            <CustomTableCell>{}</CustomTableCell>
                            <CustomTableCell>{}</CustomTableCell>
                            <CustomTableCell></CustomTableCell>
                            <CustomTableCell></CustomTableCell>
                            <CustomTableCell></CustomTableCell>
                            <CustomTableCell></CustomTableCell>
                            <CustomTableCell>
                                <Box sx={{width:"100%", display:"flex", justifyContent:"center", gap:"1rem"}}>
                                    <Avatar 
                                       src={GreenTick} 
                                       sx={{height:"1.25rem", width:"1.25rem", cursor:"pointer"}}
                                       onClick={()=>setIsApprovalDialogOpen(true)}
                                    />
                                    <Avatar src={RedCross} sx={{height:"1.25rem", width:"1.25rem"}}/>
                                </Box>
                            </CustomTableCell>
                        </>
                        )}
                    />
        </MainBox>
        <ApprovalDialog
           open={isApprovalDialogOpen}
           onClose={()=>setIsApprovalDialogOpen(false)}
           type={state?.type}
        />
     </Box>
  );
};

export default Approval;
