import React from 'react';
import {Dialog} from '@mui/material';
import { Stack } from "@mui/material";
import GoogleMap from "./googleMap";
import { addGeofence, getGeofence } from "../services/geofence";
import { setGeofenceList } from "../services/globalData";

function GeofenceDialog({open, onClose}) {

const onMapSubmit = async (fences) => {
    const fenceList = { geofenceObj: fences };
    const res = await addGeofence(fenceList);
    const getFences = await getGeofence();
    const { success, data } = getFences;
    if (success) {
    setGeofenceList(data);
    }
    onClose();
};

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width:"36.625rem",
          borderRadius: "8px",
          boxShadow: "0px 4px 10px 0px #0000001A",
          padding: "2.25rem 1.5rem",
          display:"flex",
          flexDirection:"column",
          alignItems:"center",
        }
      }}
    >
        <Stack
            margin="auto"
            paddingInline={"1.5rem"}
            paddingBlock={"2.25rem"}
            boxShadow={"0px 4px 20px 0px #00000026"}
            borderRadius={"8px"}
            width={"100%"}
        >
          <GoogleMap onSubmit={onMapSubmit} />
        </Stack>
    </Dialog>
  );
}

export default GeofenceDialog;

