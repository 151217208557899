import * as React from 'react';
import {Box, Breadcrumbs} from '@mui/material';
import styled from "@emotion/styled";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)({
    color:"inherit",
    textDecoration:"none"
})

export default function CustomSeparator({ breadcrumbs }) {

  return (
    <Box>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs?.map((breadcrumb, index) => (
          <StyledLink
            key={index}
            to={breadcrumb?.to}
            sx={{ 
              color: "#16151C", 
              fontSize: "0.875rem", 
              lineHeight: "1.375rem", 
              fontWeight: "300",
            }}
          >
            {breadcrumb?.label}
          </StyledLink>
        ))}
      </Breadcrumbs>
    </Box>
  );
}
