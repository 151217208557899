import React,{useState, useEffect} from 'react';
import {Box, TableCell} from "@mui/material";
import styled from '@emotion/styled';
import BtnPrimary from "../../../../../../component/PrimaryButton";
import PaginationTable from '../../../../../../component/PaginationTable';
import { useNavigate } from 'react-router';
import { SearchBox } from '../../../../../../component/SearchBox';
import BtnSecondary from '../../../../../../component/SecondaryButton';
import {getMachineData } from '../../../../../../services/asset';

const Headings = {
    fontSize: "0.875rem",
    fontWeight:"500",
    lineHeight: "1.5rem",
    color: "#000000",
    padding: "1.375rem 1rem",
    borderBottom: "1px solid #D1D5DB",
}

const CustomTableCell = styled(TableCell)({
    fontSize: "1rem",
    color: "#374151",
    lineHeight: "1.5rem",
    padding: "1rem",
    borderBottom: "0.5px solid #16151C33",
});


const MachineListing = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleMenuItemClick = (id) => { 
    navigate("form",{state:{assetId:id||"", mode:"view"}});
  };

    useEffect(() => {
      const fetchData = async () => {
        try {
          const res = await getMachineData();
          setData(res.data);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoading(false);
        } 
      };
      fetchData();
    }, []);
    
    console.log(data);
  
    const headings = [
      { label: "Asset ID", style: {...Headings, width:"7.5rem", borderTopLeftRadius: "12px"}},
      { label: "Name", style: {...Headings, width:"7.5rem"} },
      { label: "Assigned to", style: {...Headings, width:"11.25rem"} },
      { label: "Status", style: {...Headings, width:"7.5rem"} },
      { label: "Active Service Date", style: {...Headings, width:"15.5rem"} },
      { label: "Vendor", style: {...Headings, width:"7.5rem"} },
      { label: "View", style: { ...Headings, width:"7.5rem", borderTopRightRadius: "12px"}}
    ];

  return (
    <Box>
         <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom:"1.5rem" }}>
             <SearchBox inputStyle={{height:"2.625rem"}}/>
             <BtnPrimary
               to="form"
               sx={{ borderRadius: "10px",padding:"1.25rem", height: "2.625rem" }}
             >
               Add Machine
             </BtnPrimary>
         </Box>
        <PaginationTable
          rows={data}
          headings={headings}
          loading={loading}
          noRecordMessage={"No Record Found"}
          tableStyle={{border:"0.5px solid #00000033", borderRadius:"12px"}}
          renderCell={(row) => (
            <>
                <CustomTableCell>{row._id}</CustomTableCell>
                <CustomTableCell>{row.name}</CustomTableCell>
                <CustomTableCell>{row.employee}</CustomTableCell>
                <CustomTableCell>{row.status}</CustomTableCell>
                <CustomTableCell>{row.activeServiceDate}</CustomTableCell>
                <CustomTableCell>{row.vendor}</CustomTableCell>
                <CustomTableCell>
                  <BtnSecondary sx={{width:"5rem",borderRadius:"8px"}} onClick={() => handleMenuItemClick(row._id)}>
                    View
                  </BtnSecondary>
                </CustomTableCell>
            </>
          )}
        />
    </Box>
  )
}

export default MachineListing