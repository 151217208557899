import { fetchEmployeesByIdArray } from "../services/employee";

export const getEmployeeNames = async(apiData, employeeIds, key) => {
    const employees = await fetchEmployeesByIdArray(employeeIds);
    const combinedData = apiData.map(data => {
         const employeeIndex = (employees.data).findIndex(emp => emp._id === data[key]);
         const employee = employeeIndex !== -1 ? employees.data[employeeIndex] : null;
           return {
             ...data,
             employee: employee ? { fname: employee.fname, lname: employee.lname } : null
           };
    })
  return combinedData;
  }

  export const getEmployeeDetails = async(apiData, employeeIds, key) => {
    const employees = await fetchEmployeesByIdArray(employeeIds);
    const combinedData = apiData?.map(data => {
         const employeeIndex = (employees.data).findIndex(emp => emp._id === data[key]);
         const employee = employeeIndex !== -1 ? employees.data[employeeIndex] : null;
           return {
             ...data,
             employee: employee ? employee : null
           };
    })
  return combinedData;
  }