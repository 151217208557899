import React,{useState} from 'react'
import {Box} from '@mui/material';
import BtnPrimary from '../../../../../../component/PrimaryButton';
import BtnSecondary from '../../../../../../component/SecondaryButton';
import FormField from '../../../../../../component/Formfield';

const ProjectConfiguration = () => {

    const [projectConfigurationData, setProjectConfigurationData] = useState({
        projectName:"",
        clientName:"",
        projectHead:"",
        projectManager:"",
    });

  const handleChange = (field, value) => {
    setProjectConfigurationData(prevState => ({
            ...prevState,
            [field]: value 
        }));
  };

  return (
    <Box sx={{ width: "100%", padding:"1.875rem 1.25rem"}}>
        <Box sx={{fontWeight:"500", fontSize:"1.375rem", lineHeight:"2.06rem", marginBottom:"1.5rem"}}>
            Project Configuration Details
        </Box>
        <Box>
            <FormField
                label="Project Name"
                type="text"
                name="projectName"
                value={projectConfigurationData.projectName}
                onChange={(e) => handleChange('projectName', e.target.value)}
            />
            <FormField
                label="Client Name"
                type="text"
                name="clientName"
                value={projectConfigurationData.clientName}
                onChange={(e) => handleChange('clientName', e.target.value)}
            />
            <FormField
                label="Project Head"
                type="select"
                name="projectHead"
                options={[]}
                value={projectConfigurationData.projectHead}
                onChange={(e) => handleChange('projectHead', e.target.value)}
            />
            <FormField
                label="Project Manager"
                type="select"
                name="projectManager"
                options={[]}
                value={projectConfigurationData.projectManager}
                onChange={(e) => handleChange('projectManager', e.target.value)}
            />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "end", gap: "1.25rem", marginBlock: "1.875rem" }}>
                <BtnPrimary sx={{ borderRadius: "10px", width: "5.68rem", height: "3.125rem" }}>
                    Submit
                </BtnPrimary>
                <BtnSecondary sx={{ borderRadius: "10px", width: "5.68rem", height: "3.125rem" }}>
                    Cancel
                </BtnSecondary>
        </Box>
    </Box>
  )
}

export default ProjectConfiguration